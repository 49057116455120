import React, { useState } from 'react';
import Homeheader from '../components/homeheader';
import Button from '@mui/material/Button';
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import FooterZ from '../components/FooterZ';
import FormControl, { useFormControl } from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import Box from '@mui/material/Box';
import FormHelperText from '@mui/material/FormHelperText';
function Unsubscribe(props) {
    const token = document.cookie;
    const nav = useNavigate();
    const [reason, setReason] = useState('');
    const [error, setError] = useState(false);
    let loc = useLocation();
    const searchParams = new URLSearchParams(loc.search);
    const emailParam = searchParams.get('email');
    const handleUnsubscribed = async () => {


        let cardUrl = `unsubscribe/user`
        const response = await axios.post(cardUrl, {
            email: emailParam
        }
            // {reason : reason}
        )
        // console.log(response,"response")
        setReason("");
        setError(false);
        nav('/');
    }
    const handleChangeReason = (event) => {
        setReason(event.target.value);
        setError(false);
    };
    return (
        <div>
            <Homeheader />
            <div className="fx">
                <div style={{ height: "90vh" }} className='text-center d-flex justify-content-center align-items-center'>
                    <div className=''>
                        <div className='d-flex justify-content-center align-items-center'>
                            <img className='img-fluid' src="/unsubscripe.svg" alt='Unsubscribe from job alerts' title='Unsubscribe from Jobeze Job Alerts' />
                        </div>
                        <div className='py-3'>
                            <h1>
                                <div className='col-12 fs-3 fw-bold'>Unsubscribe from email notifications</div>
                            </h1>
                            <div style={{ color: "#00000099" }} className='col-12 fw-bolder '>
                                <p>Tell us how we can improve our services in order to provide you the best experience</p>
                            </div>
                        </div>
                        <div className='col-12 pb-5 text-start'>
                            <div className='fw-bold pb-2 px-2'>Why are you unsubscribing?</div>
                            <Box component="form" autoComplete="off">
                                <FormControl fullWidth error={error} >
                                    <OutlinedInput placeholder="Please write the reason here "
                                        value={reason}
                                        onChange={handleChangeReason}
                                    />
                                    {error && <FormHelperText className='text-danger fw-bold' >Reason is missing !</FormHelperText>}
                                </FormControl>
                            </Box>
                        </div>
                        <div className='col-12'>
                            <Button variant='contained' size='large' onClick={handleUnsubscribed} className='fw-bold px-4' >unsubscribe</Button>
                        </div>
                    </div>
                </div>
            </div>
            {/* <FooterZ /> */}
        </div>
    );
}

export default Unsubscribe;