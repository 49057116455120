import React from 'react';
import HeaderAvatar from './HeaderAvatar';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom'
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

function Header(props) {
    const nav = useNavigate();
    const handleprofile = () => {
        nav('/profile-section')
    }
    const handleHome = () => {
        nav('/')
    }
    const handleapplication = () => {
        nav('/applicationsection')
        // handleSearch();
    }
    const handleLogout = () => {
        localStorage.clear();
        const d = new Date();
        let expires = "expires=" + d.toUTCString();
        document.cookie = "_secure_ARJ_" + "=" + "" + ";" + expires + ";path=/";
        window.location.href = ('/');
    }
    const handleLogin = () => {
        nav('/login')
    }
    const handleSignup = () => {
        nav('/signup')
    }
    return (
        <div className='container-fluid py-2'>
            <div className=' border-bottom'>
                <div className=" container pb-2">
                    <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                        <Box display={"flex"} alignItems={"center"} gap={4} className="py-1">
                            <Skeleton variant="rectangle" sx={{ borderRadius: "16px" }} width={150} height={40} />
                            <Skeleton variant="rectangle" sx={{ borderRadius: "50px" }} width={500} height={40} />
                        </Box>
                        <Box display={"flex"} alignItems={"center"} gap={4} className="py-1">
                            <Skeleton variant="rectangle" sx={{ borderRadius: "16px" }} width={100} height={40} />
                            <Skeleton variant="rectangle" sx={{ borderRadius: "16px" }} width={100} height={40} />
                            <Skeleton variant="rectangle" sx={{ borderRadius: "16px" }} width={100} height={40} />
                            <Skeleton variant="rectangle" sx={{ borderRadius: "16px" }} width={100} height={40} />
                        </Box>
                    </Box>
                </div>
            </div>
            <div className="container pt-2">
                <Box display={"flex"} alignItems={"center"} gap={2} className="pt-2">
                    <Skeleton variant="rounded" sx={{borderRadius : "50px"}} width={160} height={45} />
                    <Skeleton variant="rounded" sx={{ borderRadius: "50px" }} width={150} height={45} />
                    <Skeleton variant="rounded" sx={{ borderRadius: "50px" }} width={130} height={45} />
                    <Skeleton variant="rounded" sx={{ borderRadius: "50px" }} width={130} height={45} />
                    <Skeleton variant="rounded" sx={{ borderRadius: "50px" }} width={130} height={45} />
                </Box>
            </div>
        </div>
    );
}

export default Header;