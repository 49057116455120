import React, { useEffect, useState } from "react";
import "./Status.css";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import moment from "moment/moment.js";
import axios from "axios";
import { toast } from "react-toastify";
import JobView from "./JobView";
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Skeleton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";


const Status = (props) => {
  const [isopen, setIsOpen] = useState(false);
  const [isRevoke, setRevoke] = useState(false);
  const [isloading, setIsLoading] = useState(true);
  // const [data, setData] = useState();

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, [props]);

  // console.log(props, "props");
  const nav = useNavigate();
  const handleRevoke = () => {
    axios
      .post(`user/applied-job/revoke`, {
        token: document.cookie,
        job_id: props.jobid,
        original_date_modified: props.cj_date_modified
      })
      .then((response) => {
        toast.success("Job Revoked Successfully", {
          hideProgressBar: true,
          closeOnClick: true,
        });
        setRevoke(false);
        window.location.href = "/applied-jobs";
      });
  };
  const isSmallScreen = useMediaQuery('(max-width: 625px)');
  // const isMediumScreen = useMediaQuery('(max-width: 1200px)');

  const handleClick = () => {
    if (isSmallScreen) {
      window.open(`/jobDetail/${props.jobid}`, "_blank");
    } else {
      nav("/view-jobs", { state: { job_id: props.jobid } });
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClickDropdown = e => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRevokeOpen = () => {
    setRevoke(true);
  };
  const handleRevokeClose = () => {
    setRevoke(false);
    setAnchorEl(null);
  };



  return (
    <>
      <Dialog
        open={isRevoke}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {/* <Typography className="Model-confirmation">{"Do you wish to Revoke this Application?"} </Typography>
          <hr /> */}
          <Typography className="job-title-modelbox">
            <div className="fw-bold h3">
              {`${props.title}`}
            </div>
          </Typography>
          <Typography className="job-warning fw-bold h4">
            Your job application will be withdrawn. Are you sure you want to
            revoke the application.
          </Typography>
          {/* <Typography className="Applied-on-modelbox" ><span>Applied on :</span> {`  ${moment(props.date).format("DD/MM/YYYY")}`}</Typography> */}
        </DialogTitle>
        <DialogActions>
          <Typography className="Model-cancel">
            <Button
              onClick={handleRevokeClose}
              className="fw-bold"
              size="large"
            >
              GO BACK
            </Button>
          </Typography>
          <Typography>
            <Button onClick={handleRevoke} color="error" variant="contained" className="fw-bold">
              yes, revoke
            </Button>
          </Typography>
        </DialogActions>
      </Dialog>
      {/* {isopen && <JobView jobID={props.jobid} setIsOpen={setIsOpen} />} */}
      {isloading ? (
        <div className="bg-white row row-cols-3 align-items-center justify-content-around pb-3 rounded-3">
          <div className="col-9 row m-0">
            <div className=" py-3">
              <Skeleton
                variant="text" animation="wave"
                sx={{ fontSize: "1rem", width: "250px", height: "30px" }}
              />
            </div>
            <div className="">
              <Skeleton animation="wave"
                variant="text"
                sx={{ fontSize: "1rem", width: "250px", height: "30px" }}
              ></Skeleton>
            </div>
          </div>
          <div className="col-2" style={{ display: "flex" }}>
            <img
              className="img-fluid me-2"
              src="/rightick.png"
              alt="Confirm job applications with Jobeze"
              title="Confirm Job Applications Easily on Jobeze"
              style={{ height: "20px", width: "20px", marginTop: "1%" }}
            />
            <div className="fw-bold">
              <Skeleton animation="wave"
                variant="text"
                sx={{ fontSize: "1rem", width: "130px", height: "30px" }}
              ></Skeleton>
            </div>
          </div>
        </div>
      ) : (
        <div className="bg-white shadow d-flex align-items-center justify-content-between py-3 px-4 rounded-3">
          <div className="col-lg-8">
            <div className="fw-bold pb-2" style={{ textTransform: "capitalize" }} >{props.title}
              {
                props.is_deleted === 1 && (
                  <Chip
                    label="This Job is no longer available"
                    color="error"
                    style={{ marginLeft: '20px', borderRadius: "4px" }}
                  />
                )
              }
            </div>
            <div className="fw-bold pt-2">{props.name} <span className="appliedstatus"> - Applies on {moment(props.date).format("MM/DD/YYYY")} </span> </div>
          </div>
          <div className="col-lg d-flex justify-content-end align-content-center align-items-center" >
            <img
              className="img-fluid me-lg-2 me-md-2 d-none d-md-block d-lg-block"
              src="/rightick.png"
              alt="Confirm job applications with Jobeze"
              title="Confirm Job Applications Easily on Jobeze"
              style={{ height: "20px", width: "20px" }}
            />
            <div className="fw-bold d-none d-lg-block d-md-block ">
              Applied on {moment(props.date).format("MM/DD/YYYY")}
            </div>
            <div className="" style={props.is_deleted == 1 ? { pointerEvents: 'none' } : {}}>

              {/* <a
                  href=" "
                  role="button"
                  id="dropdownMenuLink"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <MoreVertIcon />
                </a>
                <ul
                  className="dropdown-menu applicationShowRight shadow-lg"
                  aria-labelledby="dropdownMenuLink"
                >
                  <li>
                    <a
                      className="dropdown-item"
                      style={{ cursor: "pointer", }}
                      onClick={() => setRevoke(true)}
                      data-bs-toggle="modal"
                      href
                    >
                      Revoke Application
                    </a>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      style={{ cursor: "pointer", }}
                      onClick={handleClick}
                      data-bs-toggle="modal"
                      href
                    >
                      View Job Detail
                    </a>
                  </li>
                </ul> */}

              <IconButton
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClickDropdown}
                aria-label="Open to show more"
                title="Open to show more"
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                className="py-2"
              >
                <MenuItem sx={{ borderBottom: "1px solid gray" }} className="fw-bold py-2" onClick={handleRevokeOpen}>
                  Revoke Application
                  <Divider />
                </MenuItem>
                <MenuItem onClick={handleClick} className="fw-bold" >
                  View Job Detail
                </MenuItem>

              </Menu>
            </div>
          </div>

        </div>
      )}
    </>
  );
};

export default Status;
