
function Html(htmlString) {
    const containsEscapeCharacters = /&lt;|&gt;/.test(htmlString);
    const processedHtmlString = containsEscapeCharacters
        ? unescapeHtmlEntities(htmlString)
        : htmlString;
return processedHtmlString
    }
function unescapeHtmlEntities(text) {
    var doc = new DOMParser().parseFromString(text, "text/html");
    return doc.documentElement.textContent;
}

export default Html

