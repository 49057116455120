import React, { useEffect, useState } from "react";
import "./Professional.css";

import UpdateResume from "./UpdateResume";
import Chooseresume from "./ChooseResume";
import EditIcon from "@mui/icons-material/Edit";
import Professionaldrawer from "./Professionaldrawer";
import moment from "moment";
import { Typography, Box, Grid } from "@mui/material";

const Professional = ({
  data,
  duplicate,
  getData,
  replace,
  setReplace,
  setEffectDependency,
}) => {
  const date_modified = data?.details?.date_modified;
  const [onclickdrawer, setonclickdrawer] = useState(false);
  const [selectedValue, setSelectedValue] = useState("yes");
  const [currentEmp, setCurrentEmp] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [resume, setResume] = useState("");
  const [pathResume, setPathResume] = useState("");
  // const [isreplace, setReplace] = useState(false);
  const [dateR, setDateR] = useState("");
  const [getResumeData, setGetResumeData] = useState("");
  const [experience, setExperience] = useState("");
  const [currentsalary, setCurrentsalary] = useState("");
  const [expectedsalary, setexpectedsalary] = useState("");
  const [years, setyears] = useState("");
  const [months, setMonths] = useState("");
  const [selectedOptionPre, setSelectedOptionPre] = useState();
  // const [preLoc, setPreLoc] = useState([]);
  const [selectedOptionCur, setSelectedOptionCur] = useState([]);
  // const [preCur, setCur] = useState("");

  const [flag, setFlag] = useState(true);
  const [slideradius, setsliderradius] = useState("");

  const [currentLocation, setCurrentLocation] = useState([]);
  const [preferredLocation, setPreferredLocation] = useState([]);

  const [notice_period, setNotice_period] = useState(0);
  const [last_working_day, setLast_working_day] = useState("");
  const [expected_joining_date, setExpected_joining_date] = useState("");

  const handledrawer = () => {
    setonclickdrawer(true);
  };

  const drawerProfessionalProps = {
    ProfessionalDrawerField: {
      experience,
      current_employer: currentEmp,
      CTC: currentsalary,
      Exp_CTC: expectedsalary,
      // current_loc: selectedOptionCur[0]?.label
      //   ? selectedOptionCur[0]?.label
      //   : preCur,
      // preferred_loc: selectedValue === "yes" ? "Any Location" : preLoc,
      current_loc: currentLocation,
      preferred_loc: preferredLocation,
      radius: slideradius,
      relocate: selectedValue,
      notice_period: notice_period,
      last_working_day: last_working_day,
      expected_joining_date: expected_joining_date,
    },
    setonclickdrawer,
    setEffectDependency,
    setyears,
    years,
    setMonths,
    months,
  };
  useEffect(() => {
    setIsOpen(true);
    // setCur(data?.details?.current_loc[0]?.label);
    setyears(data?.details?.year ? data?.details?.year : "");
    setMonths(data?.details?.month ? data?.details?.month : "");
    setResume(data?.details?.resume);

    setPathResume(data?.details?.resume_path);
    setDateR(data?.details?.resume_date);
    setCurrentEmp(data?.details?.current_employer);
    setsliderradius(
      data?.details?.radius
        ? data?.details?.radius !== "0"
          ? data?.details?.radius
          : "NA"
        : ""
    );
    setCurrentsalary(data?.details?.CTC);
    setexpectedsalary(data?.details?.Exp_CTC);
    setSelectedValue(data?.details?.relocate === "Yes" ? "yes" : "no");
    setSelectedOptionCur(data?.details?.current_loc);
    setCurrentLocation(data?.details?.current_loc);
    setPreferredLocation(data?.details?.preferred_loc);
    // setPreLoc(data?.details?.preferred_loc);
    setNotice_period(data?.details?.notice_period);
    setLast_working_day(data?.details?.last_working_day);
    setExpected_joining_date(data?.details?.expected_joining_date);

    if (data?.details?.preferred_loc[0]?.label === "Any Location") {
      setSelectedOptionPre("");
    } else if (data?.details?.preferred_loc[0]?.label === "") {
      setSelectedOptionPre("");
    } else {
      setSelectedOptionPre(data?.details?.preferred_loc);
    }
    if (data?.details?.resume) {
      setReplace(true);
    }
  }, [data?.details]);

  const handleReplace = () => {
    setFlag(false);
    setReplace(true);
  };
  useEffect(() => {
    if (!years && !months) {
      setExperience(false);
    } else {
      let experienceString = "";
      if (years) {
        experienceString += years > 1 ? `${years} Years` : `${years} Year`;
      }
      if (months) {
        if (experienceString) {
          experienceString += " ";
        }
        experienceString += months > 1 ? `${months} Months` : `${months} Month`;
      }
      setExperience(experienceString);
    }
  }, [years, months]);

  const dateObj = new Date(last_working_day);
  const dateObj1 = new Date(expected_joining_date);

  // Get month, day, and year
  const month_lwd = (dateObj.getMonth() + 1).toString().padStart(2, "0");
  const month_ejd = (dateObj1.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based, so we add 1
  // Month is zero-based, so we add 1
  const day_lwd = dateObj.getDate().toString().padStart(2, "0");
  const day_ejd = dateObj1.getDate().toString().padStart(2, "0");
  const year_lwd = dateObj.getFullYear();
  const year_ejd = dateObj1.getFullYear();

  // Format the date as mm-dd-yyyy
  const formattedDate_last_working_day = `${month_lwd}-${day_lwd}-${year_lwd}`;
  const formattedDate_expected_joining_date = `${month_ejd}-${day_ejd}-${year_ejd}`;

  const ArraySorted = preferredLocation
    .slice()
    .sort((a, b) => a.value - b.value);

  return (
    <div className="app">
      {onclickdrawer ? (
        <Professionaldrawer
          date_modified={date_modified}
          drawerProfessionalProps={drawerProfessionalProps}
          duplicate={duplicate}
        />
      ) : (
        ""
      )}
      <Box
        className="resume-block-candiate"
        sx={{ boxShadow: "5px 4px 4px 2px rgba(0, 0, 0, 0.05)" }}
      >
        <Typography variant="h5" className="Resume-heading">
          Resume
        </Typography>
        {data.details.resume_path && replace ? (
          <Box className="px-lg-4">
            <UpdateResume
              dateR={dateR}
              resume={resume}
              pathResume={pathResume}
              handleReplace={() => setReplace(false)}
            />
          </Box>
        ) : (
          <Box className="px-lg-4">
            <Chooseresume
              setGetResumeData={setGetResumeData}
              handleReplace={handleReplace}
              pathResume={pathResume}
              setPathResume={setPathResume}
              setEffectDependency={setEffectDependency}
            />
          </Box>
        )}
      </Box>
      <Box className="basic-page overflow-hidden">
        <Box className="jobeze-basic-div">
          <Typography className="jobeze-basic-details" variant="h6" fontWeight={600}>
            Professional Details
          </Typography>
          <Box sx={{ cursor: "pointer" }}>
            <EditIcon color="primary" onClick={handledrawer} />
          </Box>
        </Box>
        <Box className="py-2">
          <Grid container spacing={2}>
            <Grid item className="d-flex flex-column gap-2" xs={12} sm={6} md={3} ml={3}  >
              {/* Total Experience */}
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={6}>
                  <Typography variant="subtitle1" className="fw-bold text-muted" style={{ whiteSpace: "nowrap" }}>
                    Total Experience
                  </Typography>
                </Grid>
                <Grid item xs={6} style={{ paddingLeft: "100px" }}>
                  <Typography
                    variant="body1"
                    className="fw-bold"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                      flexWrap: "nowrap",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {experience ? (
                      <>
                        {years > 0 &&
                          (years > 1 ? years + " Years " : years + " Year ")}
                        {months > 0 &&
                          (months > 1 ? months + " Months" : months + " Month")}
                      </>
                    ) : (
                      "NA"
                    )}
                  </Typography>
                </Grid>
              </Grid>

              {/* Current Employer */}
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={6}>
                  <Typography variant="subtitle1" className="fw-bold text-muted" style={{ whiteSpace: "nowrap" }}>
                    Current Employer
                  </Typography>
                </Grid>
                <Grid item xs={6} style={{ paddingLeft: "100px" }}>
                  <Typography
                    className="fw-bold"
                    variant="body1"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                      flexWrap: "nowrap",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {currentEmp && currentEmp.trim() !== "" ? currentEmp : "NA"}
                  </Typography>
                </Grid>
              </Grid>

              {/* Current CTC */}
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={6}>
                  <Typography variant="subtitle1" className="fw-bold text-muted" style={{ whiteSpace: "nowrap" }}>
                    Current CTC
                  </Typography>
                </Grid>
                <Grid item xs={6} style={{ paddingLeft: "100px" }}>
                  <Typography
                    variant="body1"
                    className="fw-bold"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                      flexWrap: "nowrap",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {currentsalary && currentsalary.trim() !== ""
                      ? "$ " + currentsalary
                      : "NA"}
                  </Typography>
                </Grid>
              </Grid>

              {/* Expected CTC */}
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={6}>
                  <Typography variant="subtitle1" className="fw-bold text-muted" style={{ whiteSpace: "nowrap" }}>
                    Expected CTC
                  </Typography>
                </Grid>
                <Grid item xs={6} style={{ paddingLeft: "100px" }}>
                  <Typography
                    className="fw-bold"
                    variant="body1"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                      flexWrap: "nowrap",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {expectedsalary && expectedsalary.trim() !== ""
                      ? "$ " + expectedsalary
                      : "NA"}
                  </Typography>
                </Grid>
              </Grid>

              {/* Current Location */}
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={6}>
                  <Typography variant="subtitle1" className="fw-bold text-muted" style={{ whiteSpace: "nowrap" }}>
                    Current Location
                  </Typography>
                </Grid>
                <Grid item xs={6} style={{ paddingLeft: "100px" }}>
                  <Typography
                    className="fw-bold"
                    variant="body1"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                      flexWrap: "nowrap",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {currentLocation.length ? currentLocation[0]?.label : "NA"}
                  </Typography>
                </Grid>
              </Grid>

              {/* Preferred Location */}
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={6}>
                  <Typography variant="subtitle1" className="fw-bold text-muted" style={{ whiteSpace: "nowrap" }}>
                    Preferred Location
                  </Typography>
                </Grid>
                <Grid item xs={6} style={{ paddingLeft: "100px" }}>
                  <Typography
                    variant="body1"
                    className="fw-bold"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                      flexWrap: "nowrap",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {preferredLocation.length
                      ? preferredLocation.map((item) => item.label == "Any Location()" ? "Any Location" : item.label).join(", ")
                      : "NA"}
                  </Typography>
                </Grid>
              </Grid>

              {/* Radius */}
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={6}>
                  <Typography variant="subtitle1" className="fw-bold text-muted" style={{ whiteSpace: "nowrap" }}>
                    Radius
                  </Typography>
                </Grid>
                <Grid item xs={6} style={{ paddingLeft: "100px" }}>
                  <Typography
                    variant="body1"
                    className="fw-bold"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                      flexWrap: "nowrap",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {selectedValue === "no" &&
                      slideradius !== 0 &&
                      slideradius.toString().trim() !== ""
                      ? slideradius + (slideradius > 0 ? " Miles" : "")
                      : "NA"}
                  </Typography>
                </Grid>
              </Grid>

              {/* Notice Period */}
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={6}>
                  <Typography variant="subtitle1" className="fw-bold text-muted" style={{ whiteSpace: "nowrap" }}>
                    Notice Period
                  </Typography>
                </Grid>
                <Grid item xs={6} style={{ paddingLeft: "100px" }}>
                  <Typography
                    variant="body1"
                    className="fw-bold"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                      flexWrap: "nowrap",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {notice_period === null
                      ? "NA"
                      : notice_period === 0
                        ? "Immediate Joiner"
                        : notice_period > 1
                          ? notice_period + " Days"
                          : notice_period + " Day"}
                  </Typography>
                </Grid>
              </Grid>

              {/* Last Working Day */}
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={6}>
                  <Typography variant="subtitle1" className="fw-bold text-muted" style={{ whiteSpace: "nowrap" }}>
                    Last Working Day
                  </Typography>
                </Grid>
                <Grid item xs={6} style={{ paddingLeft: "100px" }}>
                  <Typography
                    variant="body1"
                    className="fw-bold"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                      flexWrap: "nowrap",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {last_working_day ? formattedDate_last_working_day : "NA"}
                  </Typography>
                </Grid>
              </Grid>

              {/* Expected Joining Date */}
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={6}>
                  <Typography
                    variant="subtitle1"
                    className="fw-bold text-muted"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    Expected Joining Date
                  </Typography>
                </Grid>
                <Grid item xs={6} style={{ paddingLeft: "100px" }}>
                  <Typography
                    variant="body1"
                    className="fw-bold"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                      flexWrap: "nowrap",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {expected_joining_date
                      ? formattedDate_expected_joining_date
                      : "NA"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </div>
  );
};

export default Professional;
