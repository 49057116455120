import React, { useState, useEffect } from "react";
import "./UpdateResume.css";
import moment from "moment/moment.js";
import { Button } from "@mui/material";
const UpdateResume = (props) => {
  useEffect(() => {
    if (props.resume) {
      localStorage.setItem("file", props.resume);
    }
  }, [props.resume]);
  return (
    <div className="border rounded-2 d-flex align-center justify-content-between p-3">
      <div className="d-flex align-center">
        {props.pathResume ? (
          <div className="d-flex flex-column gap-2">
            <div className="" style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", }}>
              <img src="/fileicon.png" alt="Job application file icon" title="Manage Job Application Files" className="img-fluid me-2" style={{ height: "19px" }} />
              <a href={props.pathResume} target="_blank" rel="noreferrer">
                {props?.resume
                  ? props?.resume?.length > 25
                    ? props?.resume?.substring(0, 25) +
                    "..." +
                    props?.resume?.split(".")[1]
                    : props?.resume
                  : "No Resume Found"}
              </a>
            </div>
            <div className="fw-bold">
              Added {moment(props.dateR).format("MM/DD/YYYY")}
            </div>
          </div>
        ) : (
          <div style={{ fontWeight: "700" }}>
            No Resume Found
          </div>
        )}
      </div>
      <div className="py-2">
        <Button
          sx={{ fontWeight: "600" }}
          variant="outlined"
          onClick={props.handleReplace}
        >
          REPLACE
        </Button>
      </div>
    </div>
  );
};

export default UpdateResume;
