import axios from "axios";
const baseURL = process.env.REACT_APP_BASEURL;

axios.defaults.baseURL = `${baseURL}`;

  
  axios.interceptors.response.use(
    (resp) => resp,
    async (error) => {
      // console.log("axiosss");
      if (
        error.response.status === 401 &&
        !error.config._retry &&
        error.config.url !== "refresh"
      ) {
        error.config._retry = true;
        console.log("error.config._retry", error);
        
        try {
          // Request to refresh the token
  
          const response = await axios.get("refresh", { withCredentials: true });
          //   const token = useSelector((state) => state.setToken.token);
          //   const isTokenExpired = isAccessTokenExpired(token);
          // Check if the refresh was successful
          if (
            response.status === 200 &&
            response.data &&
            response.data.message.token 
          //   && isAccessTokenExpired(token)
          ) {
            // Get the new access token from the response
            const newToken = response.data.message.token;
            // const renewal_date = response.data.message.data.renewal_date;
            // dispatch(getRenewal_date(renewal_date));
            const d = new Date();
            d.setTime(d.getTime() + 30 * 24 * 60 * 60 * 1000);
            let expires = "expires=" + d.toUTCString();
            document.cookie =
              "_secure_ARJ_" + "=" + newToken + ";" + expires + ";path=/";
            // Update the original request's authorization header with the new token
              // error.config.headers['Cookie'] = `_secure_ARJ_=${newToken}`;
  
            // Retry the original request
            let obj = JSON.parse(error.config.data);
            obj.token = document.cookie;
            error.config.data = JSON.stringify(obj);
            // console.log(error.config.data,"dataaa")
            return axios(error.config);
          }
        } catch (err) {
          console.error("Error refreshing token", err);
        }
      } else if (error.response.status === 401) {
        // Redirect to login page if refresh token fails
        localStorage.clear();
        const d = new Date();
        let expires = "expires=" + d.toUTCString();
        document.cookie = "_secure_ARJ_" + "=" + "" + ";" + expires + ";path=/";
        document.cookie = "_secure_ref" + "=" + "" + ";" + expires + ";path=/";
        document.cookie = "cookiesAccepted" + "=" + "" + ";" + expires + ";path=/";
        window.location.href = "/login";
         // Replace "/login" with the actual login page URL
      }

      return Promise.reject(error);
    }
  );


// Function to check if the access token has expired


