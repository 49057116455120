import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { MuiOtpInput } from "mui-one-time-password-input";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import SyncIcon from "@mui/icons-material/Sync";
import { toast } from "react-toastify";
import axios from "axios";
import { useMediaQuery } from "@mui/material";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const matchIsNumeric = (value) => {
  return /^\d+$/.test(value);
};

const validateChar = (value, index) => {
  const isNumber = typeof text === "number";
  if (isNumber) {
    return true;
  }
  return matchIsNumeric(value);
};

const MuiOtpInputStyled = styled(MuiOtpInput)`
  display: flex;
  gap: 6px;
  max-width: 500px;
  margin-inline: auto;
`

const OtpPopup = ({
  open,
  setOpen,
  handleClose,
  alternativeEmail1,
  setAlternativeEmail1,
  setEffectDependency,
  setEmailPopupOpen,
  setContacts,
}) => {
  const [otp, setOtp] = React.useState("");
  //const [sentOtp, setSentOtp] = React.useState('');

  const handleChange = (newvalue) => {
    setOtp(newvalue);
    //console.log(otp,"55555555555555555");
  };
  // async function otpVerify() {
  //     try {
  //         const response = await axios.get('user/send-otp', { headers: { authorization: document.cookie } })
  //         setSentOtp(response.data.message.otp);
  //     } catch (error) {
  //         console.log(error)
  //     }

  // }
  // useEffect(() => {
  //     otpVerify();
  // })
  //console.log("sentOtp", sentOtp)

  const handleVerify = async () => {
    let payload = {
      email: alternativeEmail1,
      otp: parseInt(otp),
    };

    try {
      await axios
        .post("user/otp-verify", payload, {
          headers: { authorization: document.cookie },
        })
        .then((response) => {
          if (response.data.status === 200) {
            toast.success("OTP Verified", {
              hideProgressBar: true,
              closeOnClick: true,
            });
            setOpen(false);
            setEmailPopupOpen(false);
            setEffectDependency((prev) => !prev);
            setAlternativeEmail1("");
            //setEmail1(alternativeEmail1);
          } else if (response.data.status === 402) {
            //console.log(response.data,"ottttttttttttttttttttttttttp");
            setOtp("");
            toast.error("Invalid OTP", {
              hideProgressBar: true,
              closeOnClick: true,
            });
          } else {
            toast.error("Invalid OTP", {
              hideProgressBar: true,
              closeOnClick: true,
            });
          }
        });
    } catch (error) {
      console.log(error);
      toast.error("Invalid OTP", { hideProgressBar: true, closeOnClick: true });
    }
  };

  const handleResendOtp = async () => {
    let payload = {
      email: alternativeEmail1,
      otp: otp,
    };
    await axios
      .post("user/send-otp", payload, {
        headers: { authorization: document.cookie },
      })
      .then((response) => {
        if (response.data.status === 200) {
          toast.success("OTP Resent", {
            hideProgressBar: true,
            closeOnClick: true,
          });
          setOpen(true);
          //setOtpScreen(true);
          setOtp("");
        } else {
          toast.error("Invalid OTP", {
            hideProgressBar: true,
            closeOnClick: true,
          });
        }
      });
  };

  const isSmallScreen = useMediaQuery('(max-width: 625px)');

  return (
    <>
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="sm"
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "8px",
            height: "69%",
            padding: "30px 30px",
          },
        }}
      >
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          id="customized-dialog-title"
        ></DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent align="center" className=" d-flex flex-column gap-2">
          <Typography variant="h4" className="fw-bold" gutterBottom>
            OTP Verification
          </Typography>
          <Typography
            variant="subtitle1"
            className="fw-bold text-muted break-words overflow-hidden"
            gutterBottom
          >
            Enter a 6 digit OTP sent to{" "}
            <Typography className=" break-words overflow-hidden fw-bold" style={{ textOverflow: "ellipsis", color: "rgba(26, 70, 150, 1)" }}>
              {alternativeEmail1}
            </Typography>
          </Typography>
          <Typography
            variant="subtitle1"
            className="fw-bold text-muted"
            gutterBottom
          >
            <Button
              variant="text"
              className="fw-bold"
              color="inherit"
              startIcon={<BorderColorIcon sx={{ color: "black" }} />}
              onClick={() => setOpen(false)}
            >
              Edit Email
            </Button>
          </Typography>
          <Typography
            align="start"
            gutterBottom
            variant="subtitle1"
            className="fw-bold"
          >
            Enter OTP
          </Typography>
          {/* otp component */}

          {
            isSmallScreen ?
              <MuiOtpInputStyled
                value={otp}
                validateChar={validateChar}
                error={otp.length !== 6}
                onChange={handleChange}
                length={6}
                type="number"
                className="p-0"
                inputMode="numeric"
                variant="standard"
                sx={{
                  "& .MuiInputBase-root": {
                    padding: "0",
                    margin: "0",
                  },
                }}
                TextFieldsProps={{ placeholder: (index) => `i-${index}` }}
              />
              :
              <MuiOtpInput
                value={otp}
                validateChar={validateChar}
                error={otp.length !== 6}
                onChange={handleChange}
                length={6}
                type="number"
                className="p-0"
                inputMode="numeric"
                variant="standard"
                sx={{
                  "& .MuiInputBase-root": {
                    padding: "0",
                    margin: "0",
                  },
                }}
                TextFieldsProps={{ placeholder: (index) => `i-${index}` }}
              />

          }

          <Typography
            align="start"
            variant="body1"
            className="fw-bold text-muted d-flex justify-content-between align-items-center"
            gutterBottom
          >
            <Typography className="fw-bold" variant="subtitle1">
              Didn't receive the OTP?
            </Typography>
            <Typography>
              <Button
                variant="text"
                className="fw-bold"
                color="inherit"
                startIcon={<SyncIcon sx={{ color: "black" }} />}
                onClick={handleResendOtp}
              >
                Resend
              </Button>
            </Typography>
          </Typography>
        </DialogContent>
        <DialogActions className="px-5" sx={{ justifyContent: "center" }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "rgba(5, 83, 155, 1)",
              letterSpacing: "1.5px",
            }}
            className="fw-bold text-capitalize rounded-3"
            size="large"
            fullWidth
            onClick={handleVerify}
            disabled={otp.length !== 6}
          >
            Verify
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};

export default OtpPopup;
