import React from "react";
import { GoogleOAuthProvider ,GoogleLogin} from '@react-oauth/google';
import jwt_decode from 'jwt-decode';
import axios from "axios";
import { useAppDispatch } from "../redux/hooks";
import { getUser, isResumeVerified, setToken } from "../redux/modules/userSlice";


const GoogleAuth = () => {
  const dispatch = useAppDispatch();

    const responseMessage = async(response) => {
        const data = await jwt_decode(response.credential);
        if(data?.email_verified) {
            localStorage.setItem('name', data?.given_name + " " + data?.family_name);
            localStorage.setItem('email', data?.email);
            

            axios.post(`user/google-login`, { data: data }).then((response) => {
              // console.log(response, "response");
              dispatch(getUser(response?.data?.message?.isVerified));
              dispatch(setToken(response.data.message.token));
              dispatch(isResumeVerified(response.data.message.isResume));
              localStorage.setItem('id', response?.data?.message?.result_email?.id);
              localStorage.setItem("linkedIn", response.data.message.is_linkdn_followed)
              const d = new Date();
              // d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000);
              d.setTime(d.getTime() + 1 * 60 * 60 * 1000);
              let expires = "expires="+ d.toUTCString();
              document.cookie = "_secure_ARJ_" + "=" + response?.data?.message?.token + ";" + expires + ";path=/";
              
              if(response?.data?.message?.comment === "Old User") {
                window.location.href = ('/dashboard');
              }
              else {
                window.location.href = ('/upload-page');
              }
            })
        }
      };
      const errorMessage = (error) => {
        console.log("Google Error", error);
      };
  return (
    <div className="opacity-0 overflow-hidden" style={{width : "115px"}} >
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_URL}>
        <GoogleLogin onSuccess={responseMessage} onError={errorMessage} />
      </GoogleOAuthProvider>
    </div>
  );
};

export default GoogleAuth;
