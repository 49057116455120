import { Skeleton } from '@mui/material'
import React from 'react'

const GenerateCvShimmer = () => {
    return (
        <div className="bg-white border rounded-2 p-3">
            <div className="d-flex flex-column justify-content-between ">
                <div className="">
                    <Skeleton
                        variant="rectangular"
                        width="100%"
                        height={30}
                    />
                </div>
                <div className="">
                    <Skeleton
                        variant="text"
                        sx={{
                            fontSize: "1rem",
                            width: "65%",
                            height: "35px",
                        }}
                    />
                </div>
                <div className="">
                    <Skeleton
                        variant="text"
                        sx={{ fontSize: "1rem", width: "75%", height: "35px" }}
                    />
                </div>
                <div className="">
                    <Skeleton
                        variant="text"
                        sx={{ fontSize: "1rem", width: "85%", height: "35px" }}
                    />
                </div>
                <div className="">
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        sx={{ fontSize: "1rem", width: "100%", height: 75 }}
                    />
                </div>
            </div>
        </div>
    )
}

export default GenerateCvShimmer