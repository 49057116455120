import React, { useEffect, useState, useCallback, useRef } from "react";
import Button from "@mui/material/Button";
import { TextField, InputAdornment, IconButton, useMediaQuery } from '@mui/material';
import { Search } from '@mui/icons-material';

function JobFilters({ name, menuOptions, changeRoles, reset, show, count }) {
    const isResetEnabled = menuOptions.some((option) => option.isSelected);
    const handledropdown = (e) => {
        e.stopPropagation();
    };
    const [search, setSearch] = useState('');
    const [matchFound, setMatchFound] = useState(true);
    const isMediumScreen = useMediaQuery('(max-width: 1200px)');
    const handleSearchChange = (e) => {
        const inputValue = e.target.value.toLowerCase();
        setSearch(inputValue);
        const match = menuOptions.some((option) =>
            option.name.toLowerCase().includes(inputValue)
        );
        setMatchFound(match);
    };
    const handleShow = (e) => {
        show();
        setSearch("");
    }
    return (
        <div className="py-2">
            <div className="dropdown fx">
                <button
                    className="btn btn-outline-primary dropdown-toggle rounded-5 dropdownclassesfilter fw-bold"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style={{ letterSpacing: "0.7px" , marginLeft : isMediumScreen ? "15px" : "unset"  }}
                >
                    {name}
                    {
                        count > 0 ? <span className="mx-1 bg-primary rounded-circle px-2 py-1 text-white">{count}</span> : ""
                    }
                </button>
                <div className="dropdown-menu  filterchipDropdown shadow border-0" aria-labelledby="dropdownMenuButton1">
                    {name !== "Job Type" && (
                        <div className="px-3 pt-1 mb-2" >
                            <TextField
                                value={search}
                                onChange={handleSearchChange}
                                variant="outlined"
                                color="primary"
                                fullWidth
                                placeholder={"Search " + name}
                                helperText={!matchFound ? "Not found" : ""}
                                error={!matchFound}
                                InputProps={{
                                    endAdornment: (
                                        <IconButton>
                                            <Search />
                                        </IconButton>
                                    ),
                                    sx: { height: "40px" }
                                }}
                            />
                        </div>
                    )}
                    <ul className="dropUl" style={{ height: (name !== "Job Type") ? "14.5vw" : "8.9vw" }} >
                        <li>
                            {
                                menuOptions.filter((option) => {
                                    return search.toLowerCase() === "" ? option : (option.name).toLowerCase().includes(search);
                                }).map((option, index) => {
                                    const filteredIndex = menuOptions.findIndex((item) => item === option);
                                    return (
                                        <label key={filteredIndex} className="dropdown-item " onClick={handledropdown} style={{ fontWeight: "600" }}>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    name="flexRadioDefault"
                                                    id={`flexRadioDefault${filteredIndex}`}
                                                    style={{ cursor: "pointer" }}
                                                    checked={option.isSelected}
                                                    onChange={(e) => changeRoles(e, filteredIndex)}
                                                />
                                                <div className="text-wrap text-capitalize py-0" style={{ fontSize: "14px", wordBreak: "break-word" }}>
                                                    {option.name}
                                                </div>
                                            </div>
                                        </label>
                                    )
                                })}
                        </li>
                    </ul>
                    <div className="row row-cols-2 m-0 p-0 justify-content-evenly">
                        {
                            !isResetEnabled ?
                                <Button size="medium" className="btn text-dark mx-1 text-end fw-bold col-5" sx={{ letterSpacing: "0.3ch" }} >
                                    Cancel
                                </Button>
                                :
                                <Button size="medium" onClick={() => reset()} className="btn text-dark mx-1 text-end fw-bold col-5" sx={{ letterSpacing: "0.3ch" }} >
                                    Reset
                                </Button>
                        }
                        <Button id="showButton" onClick={handleShow} variant="contained" size="medium" className="col-5 fw-bold" sx={{ letterSpacing: "0.29ch" }} >
                            Show
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default JobFilters;