import React from "react";
import "./Descriptionshimmer.css";
import { Skeleton, Typography } from "@mui/material";

const Descriptionshimmer = () => {
  return (
    <div>
      <div>
        <div className="container-fluid bg-white p-3">
          <div className="">
            <div className="d-flex flex-column justify-content-between gap-3">
              <div className="d-md-flex justify-content-between">
                <div className="">
                  <Skeleton variant="rectangle" width={96} height={76} />
                </div>
                <div className="">
                  <Skeleton
                    variant="text"
                    sx={{ fontSize: "1rem", width: "388px", height: "30px" }}
                  />
                  <Skeleton
                    variant="text"
                    sx={{ fontSize: "1rem", width: "300px", height: "25px" }}
                  />
                </div>
                <div>
                  <Skeleton
                    variant="text"
                    sx={{ fontSize: "1rem", width: "80px", height: "30px" }}
                  />
                  <Skeleton variant="rounded" width={142} height={36} />
                </div>
              </div>

              <div className="">
                <Skeleton
                  variant="text"
                  sx={{
                    fontSize: "1rem",
                    height: "3px",
                    marginTop: "10px",
                  }}
                />
              </div>
              <div className="">
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height={100}
                  sx={{ marginTop: "13px" }}
                />
              </div>
              <div className="">
                <Skeleton
                  variant="text"
                  sx={{
                    fontSize: "1rem",
                    width: "150px",
                    height: "35px",
                    marginTop: "17px",
                  }}
                />
              </div>
              <div className="">
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "1rem", width: "250px", height: "35px" }}
                />
              </div>
              <div className="">
                <Skeleton
                  variant="text"
                  animation="wave"
                  sx={{ fontSize: "1rem", width: "full", height: "35px" }}
                />
              </div>
              <div className="">
                <Skeleton
                  variant="text"
                  animation="wave"
                  sx={{ fontSize: "1rem", width: "full", height: "35px" }}
                />
              </div>
              <div className="">
                <Skeleton
                  variant="text"
                  animation="wave"
                  sx={{ fontSize: "1rem", width: "full", height: "35px" }}
                />
              </div>
              <div className="">
                <Skeleton
                  variant="text"
                  animation="wave"
                  sx={{ fontSize: "1rem", width: "full", height: "35px" }}
                />
              </div>
              <div className="pb-5">
                <Skeleton
                  variant="text"
                  animation="wave"
                  sx={{ fontSize: "1rem", width: "full", height: "35px" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Descriptionshimmer;
