import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
const ResendBackdrop = () => {
    const [open, setOpen] = React.useState(true);
  return (
    <div>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 20,   }} open={open}>
            <CircularProgress color="inherit" />
        </Backdrop>
    </div>
  )
}

export default ResendBackdrop
