import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import QuestionList from "./questionList";
import axios from "axios";
import { useSelector } from "react-redux";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    }
}));

const QuestionPopup = (props) => {
    const jobDetailsData = props?.jobDetails;
    const tokenExists = useSelector((state) => state.user.token);


    useEffect(() => {
        function getQuestionList() {
            axios
                .get(`question-list-job/${jobDetailsData?.joborder_id}`)
                .then((res) => {
                    props.setQuestion(res.data.message);
                    const a = res.data.message.map((question) => {
                        return {
                            question_types: question.question_type,
                            question_id: question.question_id,
                            ques_ans: question.ques_ans,
                            answer_text: "",
                            // answer_id:null,
                            error: null,
                        };
                    });
                    props.setQuesAnsArray(a);
                })
                .catch((error) => {
                    // Handle API error here
                    console.error("Error fetching question list:", error);
                    // You can set a default question list or display an error message to the user
                });
        }
        getQuestionList();
    }, [jobDetailsData.joborder_id]);

    // console.log(jobDetailsData, "55555")

    return (
        <div>
            <BootstrapDialog
                aria-labelledby="customized-dialog-title"
                open={props.openQuestionpopup}
                fullWidth
                maxWidth="md"
                sx={{
                    "& .MuiPaper-root": {
                        borderRadius: "8px",
                        height: { xs: "auto", md: "85vh" },

                    },
                }}
            >
                <DialogTitle
                    sx={{ m: 0, p: 2, px: 3.5 }}
                    className="fw-bold text-truncate text-capitalize overflow-hidden w-75"
                    id="customized-dialog-title"
                >
                    Apply to {jobDetailsData?.title}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={props.handleClickCloseQuestionPopup}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers className="jbscroll">
                    <Box px={{ xs: 0, sm: 0, lg: 2.5 }}>
                        <Box>
                            <Typography
                                className="fw-bold pb-1"
                                sx={{ fontSize: "20px" }}
                                gutterBottom
                            >
                                Additional Questions
                            </Typography>
                        </Box>
                        <Box>
                            <QuestionList
                                question={props.question}
                                jobDetails={jobDetailsData}
                                handleTextFieldChange={props.handleTextFieldChange}
                                textFields={props.textFields}
                                setTextFields={props.setTextFields}
                                handleRadioChange={props.handleRadioChange}
                                radioValues={props.radioValues}
                                setRadioValues={props.setRadioValues}
                                quesErrorHandler={props.quesErrorHandler}
                                setQuesErrorHandler={props.setQuesErrorHandler}
                                quesAnsArray={props.quesAnsArray}
                            />
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={props.handleClickCloseQuestionPopup}
                        variant="text"
                        className="text-muted text-capitalize fw-bold"
                    >
                        Cancel
                    </Button>
                    {jobDetailsData.cover_letter_required === 1 || tokenExists ? (
                        <Button
                            variant="outlined"
                            className="text-capitalize fw-bold rounded-pill"
                            size="small"
                            color="primary"
                            onClick={props.handleNext}
                        >
                            Next
                        </Button>
                    ) : (
                        <Button
                            variant="outlined"
                            className="text-capitalize fw-bold rounded-pill"
                            size="small"
                            color="primary"
                            onClick={props.handleSubmit2}
                        >
                            Apply
                        </Button>
                    )}
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
};

export default QuestionPopup;
