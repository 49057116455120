import { Label } from "@mui/icons-material";
import { Button, Checkbox, Chip, IconButton, useMediaQuery } from "@mui/material";
import React from "react";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import { useState } from "react";
import { useEffect } from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import axios from "axios";
import { toast } from "react-toastify";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DeleteIcon from '@mui/icons-material/Delete';

const baseURL = process.env.REACT_APP_BASEURL;

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.grey,
        color: "white",
        boxShadow: theme.shadows[1],
        fontSize: 15,
        fontWeight: 500,
    },
}));

const RecommendAIjobs = ({
    isSaveJob,
    data,
    onUnsave,
    setcountjobs,
    setcheckjobid,
    countjobs,
    checkjobid,
    summit,
    isRecommended,
    handleSave,
    save,
    setSave,
}) => {

    const [check, setCheck] = useState(false);
    const [jobIds, setJobIds] = useState([]);

    useEffect(() => {
        const isSelected = checkjobid.includes(data.joborder_id);
        setCheck(isSelected);
    }, [data.joborder_id, checkjobid]);

    // useEffect(() => {
    //   setCheck(false);
    // }, [summit]);

    // const handleCheck = () => {
    //   const checkedCheckboxes = checkjobid.length;
    //   if (checkedCheckboxes >= 7 && !check) {
    //     toast.error("You can select upto 7 jobs to apply", {
    //       hideProgressBar: true,
    //       closeOnClick: true,
    //     });
    //     return;
    //   }
    //   setCheck(!check);
    //   let jobOrderDest = [...checkjobid];
    //   if (check) {
    //     const joborderIndex = jobOrderDest.findIndex(
    //       (e) => e === data.joborder_id
    //     );
    //     if (joborderIndex > -1) {
    //       jobOrderDest.splice(joborderIndex, 1);
    //       setcheckjobid(jobOrderDest);
    //     }
    //     setcountjobs(countjobs - 1);
    //   } else {
    //     setcountjobs(countjobs + 1);
    //     jobOrderDest.push(data.joborder_id);
    //     setcheckjobid(jobOrderDest);
    //   }
    // };
    const handleCheck = () => {
        const checkedCheckboxes = checkjobid.length;

        if (!check) {
            if (checkedCheckboxes >= 7) {
                toast.error("You can select up to 7 jobs to apply", {
                    hideProgressBar: true,
                    closeOnClick: true,
                });
                return;
            }

            setcountjobs(countjobs + 1);
        } else {
            setcountjobs(countjobs - 1);
        }

        setCheck(!check);

        let jobOrderDest = [...checkjobid];
        if (check) {
            const joborderIndex = jobOrderDest.findIndex(
                (e) => e === data.joborder_id
            );
            if (joborderIndex > -1) {
                jobOrderDest.splice(joborderIndex, 1);
                setcheckjobid(jobOrderDest);
            }
        } else {
            jobOrderDest.push(data.joborder_id);
            setcheckjobid(jobOrderDest);
        }
    };



    const beforeRecommendDate = new Date(`${data.date_created}`);
    const Remmonedtoday = new Date();
    const RecommendedDiffTime = Math.abs(Remmonedtoday - beforeRecommendDate);
    const RecommendedDiffDays = Math.ceil(RecommendedDiffTime / (1000 * 60 * 60 * 24));

    // var postedMessages = "";
    // if (RecommendedDiffDays === 1) {
    //     postedMessages = "Posted 1 day ago";
    // }
    // else if (RecommendedDiffDays > 1 && RecommendedDiffDays <= 31) {
    //     postedMessages = "Posted " + RecommendedDiffDays + " days ago";
    // }
    // else if (RecommendedDiffDays > 30) {
    //     postedMessages = "Re-posted " + (RecommendedDiffDays % 31) + (RecommendedDiffDays % 31 === 1 ? " day ago" : " days ago");
    // }
    // else {
    //     postedMessages = "Posted " + RecommendedDiffDays + " days ago";
    // }

    var postedMessages = "";
    if (RecommendedDiffDays === 1) {
        postedMessages = "Posted 1 day ago";
    } else if (RecommendedDiffDays === 0) {
        postedMessages = "Posted today";
    } else if (RecommendedDiffDays > 1 && RecommendedDiffDays <= 30) {
        postedMessages = "Posted " + RecommendedDiffDays + " days ago";
    } else if (RecommendedDiffDays > 30) {
        if (RecommendedDiffDays % 30 === 0) {
            postedMessages = "Posted today";
        } else {
            postedMessages =
                "Re-posted " +
                (RecommendedDiffDays % 30) +
                (RecommendedDiffDays % 30 === 1 ? " day ago" : " days ago");
        }
    } else {
        postedMessages = "Posted " + RecommendedDiffDays + " days ago";
    }

    const handleDirect = () => {
        if (data.status === "Closed" || data.is_deleted === 1) {
            return;
        } else {
            const url = `/jobDetail/${data.joborder_id}`;
            window.open(url, "_blank");
        }
    };

    const getJobStatusProps = (data) => {
        if (data.is_deleted == 1) {
            return{
                tooltipClasses : "",
                chipLabel : "This Job is no longer available",
                chipColor : "error",
                border : "4px",
                variant: "filled",
                marginfordelete : "20px",

            }
        }
       
        if (data.status === "Closed") {
            return {
                tooltipTitle: "This job is no longer accepting applications",
                chipLabel: "Closed",
                chipColor: "error",
                variant: "outlined",

            };
        } else if (data.job_applied) {
            return {
                tooltipTitle: "Job Applied",
                chipLabel: "Applied",
                chipColor: "primary",
                variant: "outlined",

            };
        }
        return null;
    };

    const jobStatusProps = getJobStatusProps(data);
    const isSmallScreen = useMediaQuery('(max-width: 625px)');
    // const isMediumScreen = useMediaQuery('(max-width: 1200px)');

    // console.log(data.questionnaire_required, "\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\");

    return (
        <div className=" shadow bg-white rounded-3 p-lg-3 p-md-3 p-2 my-lg-2 my-md-2">
            <>
                <div className="row justify-content-lg-between align-items-lg-start">
                    <div className={` col-lg-1 ${isSmallScreen ? "col-1 p-0" : ""} `} >
                        <Tooltip title={(data.cover_letter_required === 1 || data.questionnaire_required === 1) ? "This job requires additional steps" : ""} placement="top" arrow>
                            <IconButton>
                                <Checkbox
                                    checked={check}
                                    disabled={data.cover_letter_required === 1 || data.questionnaire_required === 1  ? true : false}
                                    onClick={handleCheck}
                                />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <div className={`  pt-2 ${isSmallScreen ? "col-9 ps-3 p-0" : "col-lg-8"} `}  style = {{ opacity : data.is_deleted === 1 ? 0.6 : 1 }}>
                        <div className={isSaveJob ? "staticSavedJobs" : ""}
                            style={{ fontSize: isSmallScreen ? "13px" : "unset" }}
                        >
                            <div className="d-lg-flex"  >
                                <div
                                    className={`fw-bold saved-title ${data.status === "Closed" ? "disabled" : ""
                                        }`}
                                    onClick={handleDirect}
                                    style={{
                                        cursor: data.status === "Closed" ? "default" : "pointer",
                                    }}
                                >
                                    {data.title}
                                </div>
                                <div className="mx-2 d-none d-lg-block ">
                                    {jobStatusProps && (
                                        <LightTooltip title={jobStatusProps.tooltipTitle} placement="top">
                                            <Chip
                                              variant= {jobStatusProps.variant}
                                              color={jobStatusProps.chipColor}
                                              className="fw-bold fs-6"
                                              label={jobStatusProps.chipLabel}
                                              style={{ borderRadius: jobStatusProps.border,  marginLeft: jobStatusProps.marginfordelete }}
                                            />
                                        </LightTooltip>
                                    )}
                                </div>
                            </div>
                            <div className="fw-bold">{data.name}</div>
                            <div className="text-muted fw-bold pt-1"> {data.city}, {data.state}</div>
                            <div className="d-flex align-items-center pt-2">
                                <AccountCircleIcon fontSize="small" />
                                <span className="text-muted fw-bold px-1">
                                    {data.total_recruited} Candidate(s) applied recently
                                </span>
                            </div>
                            <div className="text-muted fw-bold py-2">
                                {postedMessages}
                            </div>
                        </div>
                    </div>
                    <div className={` col-lg-3 text-end ${isSmallScreen ? "col-1 p-0" : ""}`} >
                        <Checkbox
                            // {...Label}
                            checked={save}
                            icon={<BookmarkBorderIcon />}
                            checkedIcon={<BookmarkIcon />}
                            onClick={() => handleSave(data.joborder_id)}
                        />
                    </div>
                    <div className="col-4 ms-auto d-lg-none d-md-none d-sm-none d-block ">
                        {jobStatusProps && (
                            <LightTooltip title={jobStatusProps.tooltipTitle} placement="top">
                                <Chip
                                    variant= {jobStatusProps.variant}
                                    color={jobStatusProps.chipColor}
                                    className="fw-bold text-end fs-6"
                                    label={jobStatusProps.chipLabel}
                                    style={{ borderRadius: jobStatusProps.border,  marginLeft: jobStatusProps.marginfordelete }}
                                />
                            </LightTooltip>
                        )}
                    </div>
                </div>
            </>
        </div>
    );
};

export default RecommendAIjobs;
