import React, { useEffect, useState } from 'react';
import Creatable from 'react-select/creatable';
import axios from 'axios';
import _debounce from 'lodash/debounce';


    
const Selects = (props) => {
    const [selectedOption, setSelectedOption] = useState([]);
    const[data, setData] = useState([]);


    // useEffect(()=> {
    //     axios.get(`all-skills`).then((res) => {
    //         setSelectedOption(res.data.message)
    //     })
    // },[selectedOption.length]);

    // const handleChange = (data) => {
    //     props.setData(data.map((e)=>e.label));
    //     setSelectedOption(data);
    // }  
    // const[janewaliSkill, setJanewaliSkill] = useState("");

    
    const skillssearch = async (val) => {
      // console.log("valllllllllllllllllllllllllllllllllllllllllll", val);
      try {
        if (val) {
          const response = await axios.get(
            `all-skills?search=${val}`,
          );
          // console.log("response", response);
          setSelectedOption(response.data.message);
        }
      } catch (error) {
        console.error("Error", error);
      }
    };

    // useEffect(() => {
    //   console.log('effect pageeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee', janewaliSkill);
    //   skillssearch(janewaliSkill);
    // }, [janewaliSkill]);

   

    const handleChange = (data) => { 
      // console.log("dataaaaaaaaaaaaaaaaaaaaaaaaaaaaaa", data);
        const trimmedData = data.map((e) => {
          return {
            label : e.label.trim(),
            value : e.value
          }
        });

        const ids = trimmedData.map(({ label }) => label.toLowerCase());
        const filteredData = trimmedData.filter(({ label }, index) =>
        !ids.includes(label.toLowerCase(), index + 1));
    
       
      
        props.setData(data.map((e)=>e.label));
        // setSelectedOption((prev) => {
        //     return { ...prev, skill: filteredData };
        // });
        setSelectedOption(filteredData);
        // setData(data)
      };

     
      // const handleInputChange = (inputValue) => {
      //   setJanewaliSkill(inputValue)
      //   console.log("inputValue", inputValue);
      // }

      const handleInputChange = _debounce((inputValue) => {
        // setJanewaliSkill(inputValue);
        // console.log("inputValue", inputValue);
    
        // Call your API search function here (skillssearch)
        skillssearch(inputValue);
      },415); 



    const DropdownIndicator = () => null;

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      height: '100px', // Replace 'your-custom-height' with the desired height
      alignItems: 'flex-start',
    }),
  };
    
  return (
    <div>
        <Creatable
            value={data.skill}
            onChange={(data)=>handleChange(data)}
            onInputChange={(val) => handleInputChange(val)}
            options={selectedOption}
            isMulti
            isClearable
            name="colors"
            className="basic-multi-select"
            classNamePrefix="select"
            allowCreateWhileLoading
            components={{ DropdownIndicator }}
            styles={customStyles}
            noOptionsMessage={() => "Enter your skills"}
            
        />
    </div>
  )
}

export default Selects