import React from 'react'
import Homeheader from '../components/homeheader'
import FooterN from '../components/FooterN'
import { Helmet } from 'react-helmet'

const GDPR = () => {
  return (
    <>
      <Helmet>
        <title>GDPR Compliance | Jobeze Job Search Website</title>
        <meta name="title" content='GDPR Compliance | Jobeze Job Search Website' />
        <meta name="description" content="Learn about Jobeze's commitment to GDPR compliance. Discover how we protect your data and your rights as a user. Your privacy matters—read our GDPR policy today." />
      </Helmet>
      <div>
        <div className='sticky-top bg-white'>
          <Homeheader />
        </div>
        <div className='container fx'>
          <h1>
            <div style={{ fontSize: "48px" }} className='py-3 text-center'>GDPR EU Cookies Policy</div>
          </h1>
          <div className='row pb-5'>
            <div className='row m-0'>
              This GDPR EU Cookies Policy outlines how Jobeze.com, a job portal operating in the United States, respects the General Data Protection Regulation (GDPR) and implements cookie practices in accordance with the GDPR for users accessing our website from the European Union (EU).
            </div>
            <div className='row m-0 py-2'>
              We are committed to protecting your privacy and providing transparency regarding the data we collect through cookies. By using our website, you consent to the use of cookies as described in this policy.
            </div>
            <div className='row'>
              <ul className="list-group list-group-numbered fs-5">
                <li className="list-group-item border-0 fw-bolder">
                  <span>What are Cookies?</span>
                  <div className='fs-6 fw-normal px-4'>Cookies are small text files that are stored on your computer or device when you visit a website. These files contain data that can be accessed by the website or third-party services to enhance your browsing experience and provide personalized features.</div>
                </li>
                <li className="list-group-item border-0 fw-bolder">
                  <span>Types of Cookies Used</span>
                  <ol className='fs-6 fw-normal' type='a'>
                    <li>
                      Essential Cookies: These cookies are necessary for the functioning of Jobeze.com. They enable core features such as user authentication, account security, and site navigation. Disabling essential cookies may impact the usability of the website.
                    </li>
                    <li>
                      Analytics Cookies: We use analytics cookies to collect information about how visitors use our website. This helps us analyze website traffic, track user engagement, and improve our services. The data collected is aggregated and does not personally identify individuals.
                    </li>
                    <li>
                      Preference Cookies: Preference cookies allow the website to remember your preferences and provide a more personalized experience. They may store information such as your preferred language, location, or display settings.
                    </li>
                    <li>
                      Marketing Cookies: We may use marketing cookies to deliver targeted advertisements based on your interests and browsing behavior. These cookies help us measure the effectiveness of our advertising campaigns and track conversions.
                    </li>
                  </ol>
                </li>

                <li className="list-group-item border-0 fw-bolder">
                  <span>Lawful Basis for Processing</span>
                  <div className='fs-6 fw-normal px-4'>For users accessing our website from the EU, we rely on your consent as the lawful basis for processing cookies, as required by the GDPR. When you first visit our website, we will display a cookie banner or pop-up requesting your consent to use cookies. You have the right to withdraw your consent at any time by managing your cookie preferences through the browser settings or cookie consent tool provided on our website.</div>
                </li>

                <li className="list-group-item border-0 fw-bolder">
                  <span>Data Transfers</span>
                  <div className='fs-6 fw-normal px-4'>As a job portal operating in the United States, your personal information may be transferred and stored outside the EU. We have implemented appropriate safeguards, such as the use of standard contractual clauses or Privacy Shield certification, to ensure the protection of your personal information in accordance with GDPR requirements.</div>
                </li>

                <li className="list-group-item border-0 fw-bolder">
                  <span>Data Retention</span>
                  <div className='fs-6 fw-normal px-4'>The information collected through cookies is retained for a limited period necessary to fulfill the purposes for which it was collected. Data retention periods may vary depending on the type of cookie and its purpose. Please refer to our Privacy Policy for more information on data retention practices.</div>
                </li>

                <li className="list-group-item border-0 fw-bolder">
                  <span>Your Rights</span>
                  <div className='fs-6 fw-normal px-4'>As an EU user, you have certain rights under the GDPR, including the right to access, rectify, or erase your personal information, as well as the right to restrict or object to the processing of your personal information. You also have the right to lodge a complaint with a supervisory authority if you believe that your rights have been violated.</div>
                </li>

                <li className="list-group-item border-0 fw-bolder">
                  <span> Updates to this Policy</span>
                  <div className='fs-6 fw-normal px-4'>We may update this GDPR EU Cookies Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any material changes by posting the updated policy on Jobeze.com or through other communication channels.</div>
                </li>

                <li className="list-group-item border-0 fw-bolder">
                  <span>Contact Us</span>
                  <div className='fs-6 fw-normal px-4'>If you have any questions or concerns about our use of cookies or this policy, please contact us at <a className='text-decoration-none' href='mailto:support@jobeze.com.'>support@jobeze.com.</a></div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <FooterN />
      </div>
    </>
  )
}

export default GDPR