import React from 'react'
import Homeheader from '../components/homeheader'
import FooterN from '../components/FooterN'
import { Helmet } from 'react-helmet'

const TermsCondition = () => {
  return (
    <>
      <Helmet>
        <title>Terms and Conditions | Jobeze | Job Search Website in the USA</title>
        <meta name="title" content='Terms and Conditions | Jobeze | Job Search Website in the USA' />
        <meta name="description" content="Review the Terms and Conditions for using Jobeze. Understand your rights and responsibilities while using our job search platform. Stay informed about our policies." />
      </Helmet>
      <div>
        <div className='sticky-top bg-white'>
          <Homeheader />
        </div>
        <div className='container fx'>
          <h1>
            <div style={{ fontSize: "48px" }} className='py-3 text-center'>
              Terms & Conditions
            </div>
          </h1>
          <div className='row pb-5'>
            <div className='row m-0 pb-2'>
              Welcome to Jobeze.com, an online job portal that connects job seekers and employers. These terms and conditions govern your use of our website and services. By accessing or using Jobeze.com, you agree to comply with these terms. Please read them carefully.
            </div>
            <div className='row m-0 p-0'>
              <ul className="list-group list-group-numbered fs-5">
                <li className="list-group-item border-0 fw-bolder">
                  <span>Acceptance of Terms</span>
                  <div className='fs-6 fw-normal px-4'>By using Jobeze.com, you acknowledge that you have read, understood, and agree to be bound by these terms and conditions. If you do not agree with any part of these terms, please refrain from using our website.</div>
                </li>
                <li className="list-group-item border-0 fw-bolder">
                  <span>Eligibility</span>
                  <div className='fs-6 fw-normal px-4'>Jobeze.com is available only to individuals who are at least 18 years old and capable of forming legally binding contracts. By accessing or using our services, you represent and warrant that you meet these eligibility requirements.</div>
                </li>
                <li className="list-group-item border-0 fw-bolder">
                  <span>User Accounts</span>
                  <div className='fs-6 fw-normal px-4'>To access certain features of Jobeze.com, you may need to create a user account. You are responsible for maintaining the confidentiality of your account information and ensuring that it remains accurate and up to date. You are solely responsible for all activities that occur under your account.</div>
                </li>
                <li className="list-group-item border-0 fw-bolder">
                  <span className=''>User Responsibilities</span>
                  <ol className='fs-6 fw-normal fw-normal' type='a'>
                    <li>
                      Use of the website: You agree to use Jobeze.com solely for lawful purposes and in compliance with all applicable laws and regulations. You shall not engage in any activity that may disrupt or interfere with the functioning of the website or its services.
                    </li>
                    <li>
                      User-generated content: When using Jobeze.com, you may have the opportunity to post, upload, or submit content, including but not limited to job listings, resumes, and reviews. You retain ownership of your content but grant Jobeze.com a non-exclusive, worldwide, royalty-free license to use, reproduce, modify, adapt, publish, translate, distribute, and display your content.
                    </li>
                    <li>
                      Prohibited content: You agree not to post, upload, or submit any content that is illegal, offensive, defamatory, discriminatory, or infringes upon the rights of others. Jobeze.com reserves the right to remove or disable any content that violates these terms.
                    </li>
                  </ol>
                </li>
                <li className="list-group-item border-0 fw-bolder">
                  <span className=''>Job Listings and Applications</span>
                  <ol className='fs-6 fw-normal fw-normal' type='a'>
                    <li>
                      Employers: Jobeze.com provides a platform for employers to post job listings. Employers are solely responsible for the accuracy and legality of their job postings, including any information provided about the job, company, or application process. Jobeze.com does not guarantee the availability, quality, or suitability of any job listing or employer.
                    </li>
                    <li>
                      Job seekers: Jobeze.com allows job seekers to search and apply for job listings. Job seekers are responsible for providing accurate and up-to-date information in their resumes and applications. Jobeze.com does not guarantee the accuracy, completeness, or success of any job application.
                    </li>
                    <li>
                      Communication between users: Jobeze.com provides communication tools for job seekers and employers to interact. Users are solely responsible for their interactions and communications with others. Jobeze.com is not responsible for any disputes, damages, or losses that may arise from these interactions.
                    </li>
                  </ol>
                </li>
                <li className="list-group-item border-0 fw-bolder">
                  <span className=''> Fees and Payments</span>
                  <ol className='fs-6 fw-normal fw-normal' type='a'>
                    <li>
                      General access to Jobeze.com is free. However, certain features or services may require payment of fees. Any applicable fees and payment terms will be clearly indicated before you make a purchase or subscribe to a service.
                    </li>
                    <li>
                      Payments are processed through secure third-party payment gateways. You agree to provide accurate and complete payment information and authorize Jobeze.com to charge the specified fees to your chosen payment method.
                    </li>
                    <li>
                      Refunds: Fees paid for services or subscriptions are generally non-refundable, unless otherwise specified or required by applicable law.
                    </li>
                  </ol>
                </li>
                <li className="list-group-item border-0 fw-bolder">
                  <span className=''>Intellectual Property Rights</span>
                  <ol className='fs-6 fw-normal fw-normal' type='a'>
                    <li>
                      Jobeze.com and its content, including but not limited to text, graphics, logos, and software, are the property of Jobeze.com or its licensors and are protected by intellectual property laws. You may not reproduce, modify, distribute, or create derivative works based on any part of the website without explicit permission.
                    </li>
                    <li>
                      User content: As stated in Section 4(b), you retain ownership of the content you post on Jobeze.com. However, by posting content, you grant Jobeze.com a license to use and display that content as described in Section 4(b).
                    </li>
                    <li>

                    </li>
                  </ol>
                </li>
                <li className="list-group-item border-0 fw-bolder">
                  <span className=''>Limitation of Liability</span>
                  <ol className='fs-6 fw-normal fw-normal' type='a'>
                    <li>
                      To the extent permitted by law, Jobeze.com and its affiliates, officers, directors, employees, and agents shall not be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with the use of Jobeze.com or the services provided, even if advised of the possibility of such damages.
                    </li>
                    <li>
                      Jobeze.com does not guarantee the accuracy, completeness, or reliability of any content on the website. You acknowledge that any reliance on such content is at your own risk.
                    </li>
                  </ol>
                </li>
                <li className="list-group-item border-0 fw-bolder">
                  <span>Indemnification</span>
                  <div className='fs-6 fw-normal px-4'>You agree to indemnify, defend, and hold harmless Jobeze.com and its affiliates, officers, directors, employees, and agents from and against any claims, liabilities, damages, losses, costs, or expenses arising out of or in connection with your use of the website or any violation of these terms.</div>
                </li>
                <li className="list-group-item border-0 fw-bolder">
                  <span>Modification and Termination</span>
                  <div className='fs-6 fw-normal px-4'>Jobeze.com reserves the right to modify, suspend, or terminate any part of the website or services at any time, with or without notice. We may also modify these terms and conditions from time to time. It is your responsibility to review the most current version of the terms.</div>
                </li>
                <li className="list-group-item border-0 fw-bolder">
                  <span>Governing Law and Dispute Resolution</span>
                  <div className='fs-6 fw-normal px-4'>These terms and conditions shall be governed by and construed in accordance with the laws of [Jurisdiction]. Any disputes arising out of or in connection with these terms shall be subject to the exclusive jurisdiction of the courts of [Jurisdiction].</div>
                </li>
                <li className="list-group-item border-0 fw-bolder">
                  <span>Severability</span>
                  <div className='fs-6 fw-normal px-4'>If any provision of these terms and conditions is found to be invalid, illegal, or unenforceable, the remaining provisions shall continue in full force and effect.</div>
                </li>
                <li className="list-group-item border-0 fw-bolder">
                  <span>Entire Agreement</span>
                  <div className='fs-6 fw-normal px-4'>These terms and conditions constitute the entire agreement between you and Jobeze.com regarding the use of the website and supersede any prior agreements or understandings.
                    If you have any questions or concerns regarding these terms and conditions, please contact us at support@jobeze.com Please note that the above terms and conditions are provided as a sample and should be reviewed and customized to suit your specific requirements and jurisdiction. It is always recommended to seek legal advice to ensure compliance with applicable laws and regulations.</div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <FooterN />
      </div>
    </>
  )
}

export default TermsCondition