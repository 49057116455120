import React from 'react';
import "./Endpage.css";
import Header from './header'
import Companycard from './Companycard'

const Endpage = () => {
  return (
    <><Header skill={true} /><div className='viewdetailpage'>
      <div className="aaaaa" >
        <div className="detail-1-div" style={{ display: 'flex', gap: '20px', paddingTop: '1.5%' }}>
          <img src="/backarrow.png" alt="Return to previous job search results" title='Back to Job Search Results' className="arrowimg" />
          <p className="detail-tittle">My Application</p>
        </div>
      </div>
      <div className="view-all-cards" style={{ marginTop: '-23px' }}>
        <Companycard />
        <Companycard />
        <Companycard />
        <Companycard />
        <Companycard />
      </div>
    </div>
    </>
  )
}

export default Endpage