import React, { useEffect, useState } from "react";
import Homeheader from "../components/homeheader";
import { Link, useNavigate } from "react-router-dom";
// import WhatshotIcon from "@mui/icons-material/Whatshot";
import axios from "axios";
// import QuickApplyCards from "../components/quickApplyCards";
import SearchBar from "../components/searchBar";
// import CandidateCard from "../components/candidateCard";
// import Homepagefooter from "./../components/Homepagefooter";
import FooterN from "../components/FooterN";
import Button from "@mui/material/Button";
import MuiAccordion from "@mui/material/Accordion";
import { styled } from "@mui/material/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useCookies } from "react-cookie";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box, Divider } from "@mui/material";
import { Helmet } from "react-helmet";

const reviews = [
  {
    id: 1,
    text: "Jobeze was a game-changer in my job hunt, offering a diverse range of creative roles. Its easy navigation and excellent customer support made my experience exceptional.",
    author: "Emily Smith, Senior Graphic Designer",
  },
  {
    id: 2,
    text: "The Jobeze portal provided an efficient and user-friendly experience, connecting me with high-quality opportunities in the tech sector. Highly recommend for its precise job matching and excellent follow-up services.",
    author: "Raj Patel, IT Project Manager",
  },
  {
    id: 3,
    text: "I was impressed by Jobeze's comprehensive approach to job searching.The platform's insightful industry trends and personalized job alerts were instrumental in landing my current role.",
    author: "Sophia Johnson, Marketing Specialist",
  },
  {
    id: 4,
    text: "Jobeze stood out with its advanced search filters and detailed job descriptions. It made finding a role suited to my specific skill set and experience level incredibly straightforward.",
    author: "Luis Hernandez, Data Analyst",
  }

  // Add more reviews as needed
];

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 8000,

};

function Homepage() {
  const [categories, setCategories] = useState([]);
  const [skill, setSkill] = useState("");
  const [location, setLocation] = useState("");
  const navigate = useNavigate();
  const [getPageNumber, setPageNumber] = useState(1);
  const [cookies, setCookie] = useCookies([]);
  const nav = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    axios.get(`job-by-category`).then((response) => {
      // console.log(response,"vavajvs");
      setCategories(response.data.message.splice(0, 24));
    });
  }, []);

  useEffect(() => {
    if (cookies._secure_ARJ_ || cookies._secure_ref) {
      nav("/dashboard");
    }
  });
  // const handleSearch = () => {
  //   navigate("/jobsearch/allsearchdata", {
  //     state: { skill: skill, location: "" },
  //   });
  // };
  // const handleClick = (item) => {
  //   navigate("/jobsearch/allsearchdata", {
  //     state: { skill: "", location: "", title: "", pageData: getPageNumber, category: [{ name: item.value, isSelected: true }] },
  //   });
  // };
  const handleClick = (item) => {
    const param_title = item.value;
    // ? item.value.replaceAll("/", "/")
    // : item.value;
    navigate(
      `/jobsearch/allsearchdata?search=${param_title}&location=${"World Wide"}&jobRole=${param_title}`,
      {
        // state: { pageData: getPageNumber, categories: categories },
        state: {
          skill: "",
          location: "",
          title: "",
          pageData: getPageNumber,
          category: [{ name: item.value, isSelected: true }],
        },
      }
    );
  };
  const [open1] = useState(true);

  const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} {...props} />
  ))(({ theme }) => ({
    // border: 0,
    // border: `1px solid ${theme.palette.divider}`,
    // '&:not(:last-child)': {
    borderBottom: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
      // border : 0
    },
    // },
    "&:before": {
      display: "none",
    },
  }));
  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary {...props} expandIcon={<KeyboardArrowDownIcon />} />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "",
    flexDirection: "row",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(180deg)",
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
    },
  }));
  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    paddingLeft: 23,
    // paddingRight: 45,
    // padding: theme.spacing(2),
    // borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));
  const [expanded, setExpanded] = useState("panel1");
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };


  return (
    <>
      <div className="sticky-top bg-white">
        <Helmet>
          <title>Find Your Dream Job with AI | Apply for Jobs in USA | Jobeze</title>
          <meta name="title" content='Find Your Dream Job with AI | Apply for Jobs in USA | Jobeze' />
          <meta name="description" content="Looking for a job? Easily find jobs near you with Jobeze, the leading job search website in the USA. Explore job openings with AI and start your job hunt today." />
        </Helmet>
        <Homeheader />
      </div>
      <div className="container-fluid fx">
        <div className="container py-5">
          <div className="row">
            <div className="col-lg-6 m-auto">
              <div className="fw-bold ">
                <div
                  className="display-4 text-capitalize"
                  style={{ fontWeight: "800" }}
                >
                  AI Powered Assistant to your Dream Job!
                </div>
                <div
                  className="text-muted py-3 pb-5"
                  style={{ fontSize: "24px" }}
                >
                  Apply to Your Dream Job Effortlessly, <br /> No Login Required
                </div>
                <div className="position-relative pb-5 mobisearch">
                  <SearchBar
                    skill={skill}
                    setSkill={setSkill}
                    location={location}
                    setLocation={setLocation}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 p-0 text-lg-end text-center">
              <img
                className="img-fluid"
                fetchpriority="high"
                src="/Framehim.svg"
                width="400.16px"
                height="538.89px"
                alt="Effortless online job search with AI automation"
                title="AI-Powered Job Search Automation"
              />
            </div>
          </div>
        </div>
        <section>
          <div className="container py-5 p-0">
            <div className="row pb-3 m-0">
              <div className="col-lg-6 m-auto">
                <h1 className="fw-bold  pb-2" style={{ fontSize: "56px" }}>
                  Top jobs on the platform
                </h1>
                <p
                  className="text-muted fx fw-bold"
                  style={{ fontSize: "24px" }}
                >
                  Browse through the top jobs
                </p>
              </div>
              <div className="col-lg-6 py-2">
                <div className="">
                  {open1 && (
                    <div className="row row-cols-lg-3 row-cols-md-3 row-cols-sm-2 row-cols-2 fx">
                      {categories.map((item, index) => {
                        return (
                          <div key={index}>
                            {index < 9 && (
                              <div
                                className="col-lg-3 col-sm-2 border my-2 hover-new"
                                style={{
                                  width: "90%",
                                  borderRadius: "16px",
                                  // filter:
                                  //   "drop-shadow(2px 4px 30px rgba(63, 149, 234, 0.12))",
                                }}
                                onClick={() => {
                                  handleClick(item);
                                }}
                              >
                                <div
                                  className="px-2 ps-3 py-2"
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "14px",
                                  }}
                                >
                                  <p
                                    className="card-title fw-bold "
                                    style={{
                                      width: "100%",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {item.value.length >= 30
                                      ? item.value.substring(0, 50) + ""
                                      : item.value}
                                  </p>
                                  <p className="card-text text-muted fw-bold ">
                                    {index === 0 || index === 1 ? (
                                      // <WhatshotIcon style={styleFire} />
                                      <></>
                                    ) : (
                                      ""
                                    )}
                                    {item.total}+ Jobs
                                    <KeyboardArrowRightIcon />
                                  </p>
                                </div>
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="py-5">
          <div className="container ">
            <div className="row text-center m-auto fw-bold">
              <h2 className="fw-bold pb-2 ">
                Hassle-Free job applications in a few clicks
              </h2>
              <p className="m-auto text-muted ">
                Say goodbye to the complexities and frustrations of traditional
                job applications. Experience the <br /> ease of applying with
                just a few clicks, saving you time and energy in your job
                search.
              </p>
            </div>
          </div>
        </section>
        <section className="homepageAllimg">
          <section className="">
            <div className="container">
              <div className="row m-0 align-items-center">
                <div className="col-lg-6 px-4 fw-bold">
                  <h2 className="fw-bold display-5 pb-2">
                    Say goodbye to <br /> complex logins{" "}
                  </h2>
                  <p className=" text-muted " style={{ fontSize: "24px" }}>
                    Streamlined job application process for <br /> you. Submit
                    your resume directly and apply <br /> to jobs seamlessly.
                  </p>
                </div>
                <div className="col-lg-5 m-auto">
                  <img
                    alt="Say goodbye to complex job applications"
                    title="Simplify Your Job Search with Jobeze"
                    fetchpriority="high"
                    className="img-fluid"
                    width="460px"
                    height="460px"
                    src="/GoodbyeComplex.svg"
                  />
                </div>
              </div>
            </div>
          </section>
          <section className="">
            <div className="container">
              <div className="row m-0 align-items-center homingFlex">
                <div className="col-lg-7">
                  <img
                    alt="Build your professional profile for job opportunities"
                    title="Create Your Job Profile for Career Opportunities"
                    fetchpriority="high"
                    width="460px"
                    height="460px"
                    className="img-fluid"
                    src="./ProfileOpportunity.svg"
                  />
                </div>
                <div className="col-lg-5 px-4 fw-bold">
                  <h2 className="fw-bold display-5 pb-2">
                    Dive into the pool of opportunities
                  </h2>
                  <p className=" text-muted " style={{ fontSize: "24px" }}>
                    Our app ensures that everyone can easily
                    <br /> apply to jobs, regardless of their technical <br />{" "}
                    expertise.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className="">
            <div className="container">
              <div className="row m-0 align-items-center">
                <div className="col-lg-6 px-4 fw-bold">
                  <h2 className="fw-bold display-5 pb-2">
                    An online resume <br /> that says a lot about <br /> your
                    career.{" "}
                  </h2>
                  <p className=" text-muted " style={{ fontSize: "24px" }}>
                    Create your profile that act as an online
                    <br /> resume for recruiters
                  </p>
                </div>
                <div className="col-lg-5 m-auto">
                  <img
                    alt="Enhance your resume for job applications"
                    title="Boost Your Resume for Better Job Applications"
                    fetchpriority="high"
                    width="460px"
                    height="460px"
                    className="img-fluid"
                    src="./ResumeCareer.svg"
                  />
                </div>
              </div>
            </div>
          </section>
        </section>
        <section className="pt-5 pb-2">
          <div className="container py-5">
            <div className="row text-center m-auto fw-bold">
              <h2 className="fw-bold" style={{ fontSize: "34px" }}>
                Start Applying Now
              </h2>
              <p className="m-auto text-muted ">
                Join our community of job seekers and take control of your
                career journey. With just a few clicks, you <br /> can submit
                your resume and apply instantly. Don't miss out on the perfect
                opportunity – click below <br /> to get started now!
              </p>
            </div >
          </div >
          <div className="text-center pb-5">
            <Link
              to="/jobsearch/allsearchdata"
              style={{ textDecoration: "none", color: "unset" }}
            >
              <Button
                sx={{ backgroundColor: "#006CB7" }}
                variant="contained"
                size="large"
                className="fw-bold px-4"
              >
                {" "}
                Get Started{" "}
              </Button>
            </Link>
          </div>
        </section >

        <div className="container-lg ">
          <Slider {...settings}>
            {reviews.map((review) => (
              <div key={review.id} className="text-center px-lg-5 d-flex flex-column justify-content-between align-items-center px-3">
                <img src="./Stars.svg" fetchpriority="high" className="img-fluid m-auto" alt="Top-rated AI job search platform" title="Jobeze - Best Job Search Engine with AI" />
                <Typography variant="body1" className=" fw-bold fs-5 py-3 px-lg-5">{review.text}</Typography>
                <Typography variant="body2" className="fw-bold text-muted">- {review.author}</Typography>
              </div>
            ))}
          </Slider>
        </div>
        <Box className="container py-4 border-bottom"></Box>
        <section className="py-5 mt-3">
          <div className="container">
            <div className="row m-0">
              <div className="col-lg-7 fx float-start">
                <div className="fw-bold fx">
                  <p>HOW IT WORKS</p>
                </div>
                <div className="fw-bold">
                  <h2 className="fw-bolder fx display-5">
                    Simple Steps to Your
                    <br />
                    Dream Job
                  </h2>
                </div>
                <div className="py-4">
                  <Link
                    to="/jobsearch/allsearchdata"
                    style={{ textDecoration: "none", color: "unset" }}
                  >
                    <Button
                      variant="contained"
                      size="large"
                      sx={{ backgroundColor: "#006CB7", letterSpacing: "1px" }}
                      className="fs-6 fw-bold px-4 fx"
                    >
                      Get Started
                    </Button>
                  </Link>
                </div>
              </div>
              <div className="col-lg-5 fx">
                <div className="d-flex flex-column ">
                  <div className="d-flex pb-5">
                    <div className="">
                      <div
                        className="rounded-circle text-center"
                        style={{
                          background: "#F2F2F2",
                          width: "48px",
                          height: "48px",
                        }}
                      >
                        <span
                          style={{ color: "rgb(0,0,0,0.4", fontSize: "28px" }}
                          className="material-symbols-rounded mt-2"
                        >
                          arrow_selector_tool
                        </span>
                      </div>
                      <div
                        className="border-start border-3 mt-2"
                        style={{ height: "86%", marginLeft: "23px" }}
                      ></div>
                    </div>
                    <div className="px-4">
                      <div className="fw-bold">
                        <div style={{ fontSize: "20px" }}>
                          Choose Your Preferred Job
                        </div>
                      </div>
                      <div
                        className="text-muted py-2"
                        style={{
                          fontFamily: "Roboto, sans-serif",
                          fontWeight: 600,
                        }}
                      >
                        Browse through a wide range of job opportunities and
                        select the one that matches your skills and interests.
                      </div>
                    </div>
                  </div>
                  <div className="d-flex pb-5">
                    <div>
                      <div
                        className="rounded-circle text-center"
                        style={{
                          background: "#F2F2F2",
                          width: "48px",
                          height: "48px",
                        }}
                      >
                        <span
                          style={{ color: "rgb(0,0,0,0.4)", fontSize: "28px" }}
                          className="material-symbols-rounded mt-2"
                        >
                          upload
                        </span>
                      </div>
                      <div
                        className="border-start border-3 mt-2"
                        style={{ height: "89%", marginLeft: "23px" }}
                      ></div>
                    </div>
                    <div className="px-4">
                      <div className="fw-bold">
                        <div style={{ fontSize: "20px" }}>
                          Upload Your Resume
                        </div>
                      </div>
                      <div
                        className="text-muted py-2"
                        style={{
                          fontFamily: "Roboto, sans-serif",
                          fontWeight: 600,
                        }}
                      >
                        Submit your resume directly without the need for a
                        login.
                        <br /> Showcase your qualifications to potential
                        employers
                        <br /> effortlessly.
                      </div>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div>
                      <div
                        className="rounded-circle text-center"
                        style={{
                          background: "#F2F2F2",
                          width: "48px",
                          height: "48px",
                        }}
                      >
                        <span
                          style={{
                            color: "rgb(0,0,0,0.4)",
                            fontSize: "28px",
                            marginTop: "0.7rem",
                            marginLeft: "5px",
                          }}
                          className="material-symbols-rounded"
                        >
                          send
                        </span>
                      </div>
                    </div>
                    <div className="px-4">
                      <div className="fw-bold">
                        <div style={{ fontSize: "20px" }}>Apply Instantly</div>
                      </div>
                      <div
                        className="text-muted py-2"
                        style={{
                          fontFamily: "Roboto, sans-serif",
                          fontWeight: 600,
                        }}
                      >
                        With just a click, send your application directly to the{" "}
                        <br /> employer. It's quick, convenient, and
                        hassle-free.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="container">
          {/* <hr /> */}
          <div className="spreation-line"></div>
        </section>
        <section className="py-5">
          <div className="container">
            <div className="row m-0">
              <div className="col-lg-7  fx">
                <h2 className="fw-bold display-5 pb-2 fx">
                  Frequently asked questions
                </h2>
                <p className="text-muted fw-bold h4">
                  Get your questions answered
                </p>
              </div>
              <div className="col-lg-5 m-auto fx">
                <Accordion
                  expanded={expanded === "panel1"}
                  onChange={handleChange("panel1")}
                >
                  <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                  >
                    <Typography
                      className="fw-bold"
                      style={{ fontSize: "18px" }}
                    >
                      What industries does Jobeze cater to?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      className="fw-bold"
                      style={{ fontSize: "16px", color: "rgba(0, 0, 0, 0.87)" }}
                    >
                      Jobeze caters to a wide range of industries, including
                      technology, finance, marketing, healthcare, engineering,
                      and more. Whatever your field of expertise, Jobeze is
                      designed to help you find relevant job opportunities.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === "panel2"}
                  onChange={handleChange("panel2")}
                >
                  <AccordionSummary
                    aria-controls="panel2d-content"
                    id="panel2d-header"
                  >
                    <Typography
                      className="fw-bold"
                      style={{ fontSize: "18px" }}
                    >
                      Can I use Jobeze internationally?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      className="fw-bold"
                      style={{ fontSize: "16px", color: "rgba(0, 0, 0, 0.87)" }}
                    >
                      Yes, Jobeze is available internationally. Whether you are
                      searching for jobs locally or exploring opportunities in
                      different countries, Jobeze can assist you in your job
                      search process.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === "panel3"}
                  onChange={handleChange("panel3")}
                >
                  <AccordionSummary
                    aria-controls="panel3d-content"
                    id="panel3d-header"
                  >
                    <Typography
                      className="fw-bold"
                      style={{ fontSize: "18px" }}
                    >
                      How secure is my personal information on Jobeze?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      className="fw-bold"
                      style={{ fontSize: "16px", color: "rgba(0, 0, 0, 0.87)" }}
                    >
                      Jobeze takes the security and privacy of your personal
                      information seriously. They employ industry-standard
                      security measures to safeguard your data and ensure that
                      your information remains confidential.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === "panel4"}
                  onChange={handleChange("panel4")}
                >
                  <AccordionSummary
                    aria-controls="panel4d-content"
                    id="panel4d-header"
                  >
                    <Typography
                      className="fw-bold"
                      style={{ fontSize: "18px" }}
                    >
                      Can I switch careers using Jobeze?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      className="fw-bold"
                      style={{ fontSize: "16px", color: "rgba(0, 0, 0, 0.87)" }}
                    >
                      Yes, Jobeze can be a valuable resource if you're looking
                      to switch careers. By highlighting your transferable
                      skills and interests, Jobeze can provide you with relevant
                      job recommendations in your desired industry.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === "panel5"}
                  onChange={handleChange("panel5")}
                >
                  <AccordionSummary
                    aria-controls="panel5d-content"
                    id="panel5d-header"
                  >
                    <Typography
                      className="fw-bold"
                      style={{ fontSize: "18px" }}
                    >
                      Is Jobeze suitable for freelancers?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      className="fw-bold"
                      style={{ fontSize: "16px", color: "rgba(0, 0, 0, 0.87)" }}
                    >
                      Absolutely! Jobeze caters to both full-time job seekers
                      and freelancers. Whether you're looking for long-term
                      employment or freelance opportunities, Jobeze can help you
                      find the right fit.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
          </div>
        </section>
        {/* <section className="p-0">
          <img src="/PolygonTriangle.svg" fetchpriority="high" className="w-100 img-fluid" alt="PolygonTriangle" style={{ transform: "scaleY(-1)" }} />
        </section> */}
        <section className="py-5">
          <div className="container py-5">
            <div className="row text-center m-auto fw-bold">
              <h2 className="fw-bold pb-2 fx" style={{ fontSize: "34px" }}>
                Effortless job applications at your fingertips
              </h2>
              <p className="m-auto text-muted ">
                Start applying to your dream job now. No login required
              </p>
            </div>
          </div>
          <div className="text-center pb-5">
            <Link
              to="/jobsearch/allsearchdata"
              style={{ textDecoration: "none", color: "unset" }}
            >
              <Button
                variant="contained"
                sx={{ backgroundColor: "#006CB7" }}
                size="large"
                className="fw-bold px-4"
              >
                Get Started
              </Button>
            </Link>
          </div>
        </section>
      </div >
      <FooterN />
    </>
  );
}

export default Homepage;
