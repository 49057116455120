import React, { useEffect, useState } from "react";
import "./Other.css";
import OtherSkill from "./OtherSkill";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { toast } from "react-toastify";
import axios from "axios";
import Backdrops from "./Backdrop";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import Otherdrawer from "./otherdrawer";

const Other = ({ getData, data, setEffectDependency, duplicate }) => {
  const [edu, setEdu] = useState({});
  const [skill, setSkill] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [onclickdrawer, setonclickdrawer] = useState(false);
  const [linkedin, setLinkedin] = useState("");
  const [portfolio, setportfolio] = useState("");

  const date_modified = data?.details?.date_modified;

  const drawerOtherProps = {
    OtherDrawerField: {
      portfolio,
      linkedin,
      skill,
      highest_educ: edu
    },
    setonclickdrawer,
    setEffectDependency,
  };

  useEffect(() => {
    setIsOpen(true);
    setEdu(data.details.highest_educ);
    setSkill(data.details.skill);
    setportfolio(data.details.portfolio);
    setLinkedin(data.details.linkedIn);
    setIsOpen(false);
  }, [data.details]);

  const handledrawer = () => {
    setonclickdrawer(true);
  };

  // const skills = skill.length > 0 ? skill.map(e => e.label).join(', ') : '';
  const skills =
    skill && skill.length > 0
      ? skill
          .sort((a, b) => a.label.localeCompare(b.label))
          .map((e) => e.label)
          .join(", ")
      : "";

  const isSmallScreen = useMediaQuery("(max-width:430px)");

  return (
    <div className="other-viewmode">
      {onclickdrawer ? (
        <Otherdrawer
          drawerOtherProps={drawerOtherProps}
          date_modified={date_modified}
          duplicate={duplicate}
        />
      ) : (
        ""
      )}
      <div className="jobeze-basic-div">
        <div className="jobeze-basic-details">Other Details</div>
        <div style={{ cursor: "pointer" }}>
          <EditIcon color="primary" onClick={handledrawer} />
        </div>
      </div>
      <div className="parent-other">
        <div className="child-other">
          <div className="childDiv">Highest Education</div>
          <div
            className="vbn break-words-o"
            style={{ textAlignLast: isSmallScreen ? "end" : "" }}
          >
            {!(edu?.highest_education === 'not available' || edu?.highest_education === "Not Available") ? edu?.highest_education : "NA"}
          </div>
        </div>
        <div className="child-other" style={{ marginTop: "16px" }}>
          <div className="childDiv">Linkedin URL</div>
          <div
            className="vbn break-words-o"
            style={{ textAlignLast: isSmallScreen ? "end" : "" }}
          >
            {linkedin ? (
              <a href={linkedin} target="_blank" rel="noopener noreferrer">
                {linkedin}
              </a>
            ) : (
              "NA"
            )}
          </div>
        </div>
        <div className="child-other" style={{ marginTop: "16px" }}>
          <div className="childDiv">Portfolio Link</div>
          <div
            className="vbn portfolioWidth break-words-o"
            style={{ textAlignLast: isSmallScreen ? "end" : "" }}
          >
            {portfolio ? (
              <a
                href={portfolio}
                className=""
                target="_blank"
                rel="noopener noreferrer"
              >
                {portfolio}
              </a>
            ) : (
              "NA"
            )}
          </div>
        </div>
        <div
          className="child-other"
          style={{ marginTop: "16px", paddingBottom: "16px" }}
        >
          <div className="childDiv">Skills</div>
          <div
            className="text-capitalize vbn break-words-o"
            style={{ textAlignLast: isSmallScreen ? "end" : "" }}
          >
            {skills ? skills : "NA"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Other;

{
  /* <div className="basic-page pb-0">
{isOpen && <Backdrops />}
  <div className="basic-heading1">Other Details</div>
  <div className="other-BasicHr"></div>
  <div className='p-4'>
      <FormControl sx={{ width:'38%' }}>
        <InputLabel className='fw-bold' id="demo-simple-select-label">Highest Education</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={edu}
          label="Select from the list"
          onChange={handleChange}
          MenuProps={MenuProps}
          className='fw-bold'
        >
          {
            education_list.map(e=><MenuItem className='fw-bold'  value={e}>{e}</MenuItem>)
          }
        
        </Select>
      </FormControl>
  </div>
  <div className='text-capitalize fw-bold p-4 w-50'>
    <Typography sx={{ fontSize:'18px', fontWeight:'600'}}>Skills</Typography>
    <OtherSkill setFlag={setFlag} setdata={setSkill} setEdu={setEdu} setIsOpen={setIsOpen}/>
  </div>
  <div className="p-4" style={{marginTop : "13.5vw"}}>
    <Button onClick={handleSubmit} variant='contained' className='basic-1-button px-5 fw-bold' disabled={flag}>Save</Button>
  </div>
</div>

  // if(!linkedInRegex.test(linkdin) && linkdin !== "") {
    //   setIsOpen(false);
    //   setFlag(true);
    //   toast.error("Please Enter Valid Linkedin URL", {hideProgressBar: true, closeOnClick: true});
    // }
*/
}
