import React, { useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Box, FormControl, OutlinedInput, useMediaQuery } from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import GenerateCvShimmer from "./generateCvShimmer";
import axios from "axios";
import { useAppDispatch } from "../redux/hooks";
import { getCredits, getRenewal_date } from "../redux/modules/userSlice";
import moment from "moment";
import { useSelector } from "react-redux";
import { useCookies } from "react-cookie";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

const GenerateCVPopup = (props) => {
    const dispatch = useAppDispatch();
    const jobData = props.jobData;
    const isSmallScreen = useMediaQuery("(max-width: 625px)");
    const reactQuillRef = useRef();
    const [currentLength, setCurrentCharCount] = useState(0);
    const [exceededLimit, setExceededLimit] = useState(false);
    const [renewalDate, setRenewalDate] = useState("");
    const parsedDate = moment(renewalDate);
    const formattedDate = parsedDate.format("MM/DD/YYYY");
    const formattedHtmlContent = props.HtmlContent.replace(
        /\n/g,
        "<br/>"
    ).replace(/\r/g, "");
    const tokenExists = useSelector((state) => state.user.token);
    const [cookies, setCookie] = useCookies([]);

    useEffect(() => {
        if (!(cookies._secure_ARJ_ || cookies._secure_ref)) {
            return;
        }
        async function fetchData() {
            try {
                const response = await axios.get("/credit-info", {
                    headers: { authorization: document.cookie },
                });
                // console.log(response.data.message);
                props.setCredits(response.data.message.credits);
                dispatch(getCredits(response.data.message.credits));
                setRenewalDate(response.data.message.renewal_date);
                dispatch(getRenewal_date(response.data.message.renewal_date));
            } catch (error) {
                console.error("Error fetching credit information:", error);
            }
        }
        fetchData();
    }, []);

    const handleKeyDown = (e) => {
        // Prevent copying (Ctrl+C or Command+C)
        if ((e.metaKey || e.ctrlKey) && e.key.toLowerCase() === "c") {
            e.preventDefault();
            toast.warning("You can't copy");
        }
    };

    const checkCharacterCount = (e) => {
        const unprivilegedEditor = reactQuillRef.current.getEditor();
        const length = unprivilegedEditor.getLength();
        setCurrentCharCount(length);
        if (length > 3999 ) {
            // e.preventDefault();
            setExceededLimit(true);
        } else {
            setExceededLimit(false);
        }
    };

    const handlePaste = (e) => {
        const pastedData = e.clipboardData.getData("text/plain");
        const pastedLength = pastedData.length;
        const totalLength = currentLength + pastedLength;

        if (totalLength > 3999) {
            setExceededLimit(true);
        } else {
            setExceededLimit(false);
        }
    };

    const handleAdditionInfoChange = (e) => {
        const { value } = e.target;
        if (value.length > 500) {
            props.setExceededLimitAdditionalInfo(true);
        } else {
            props.setExceededLimitAdditionalInfo(false);
            props.handleAdditionInfoChange(e);
        }
    };

    const handleContextMenu = (e) => {
        // Prevent right-click context menu
        e.preventDefault();
    };

    const shouldDisableButton = () => {
        if (jobData.questionnaire_required === 1 && jobData.cover_letter_required === 0) {
            return false; // Button active hoga
        }
        else if (jobData.questionnaire_required === 1 && jobData.cover_letter_required === 1) {
            // HTML content aur additional information ki validation check karein
            return (
                props.HtmlContent === null || props.loading ||
                ((props.HtmlContent === "<p><br></p>" || props.HtmlContent === "")) ||
                exceededLimit ||
                currentLength > 3999 ||
                props.exceededLimitAdditionalInfo
            );
        }
        else if (jobData.questionnaire_required === 0 && jobData.cover_letter_required === 0) {
            return (
                props.HtmlContent === null || props.loading ||
                ((props.HtmlContent === "<p><br></p>" || props.HtmlContent === "") && props.additionInfoData === "") ||
                exceededLimit ||
                currentLength > 3999 ||
                props.exceededLimitAdditionalInfo
            ); // Button disabled hoga
        }
        else if (jobData.questionnaire_required === 0 && jobData.cover_letter_required === 1) {
            return (
                props.HtmlContent === null || props.loading ||
                ((props.HtmlContent === "<p><br></p>" || props.HtmlContent === "") && props.additionInfoData === "") ||
                exceededLimit ||
                currentLength > 3999 ||
                props.exceededLimitAdditionalInfo
            ); // Button disabled hoga
        }
        else {
            return true;
        }
    };

    // const getRenewalDate = () => {
    //     const currentDate = new Date();
    //     const nextMonth = new Date(currentDate);
    //     nextMonth.setMonth(currentDate.getMonth() + 1);
    //     nextMonth.setDate(1); // Set the date to the 1st day of the next month

    //     // Format the date in US date format (MM/DD/YYYY)
    //     const formattedDate = `${(nextMonth.getMonth() + 1).toString().padStart(2, '0')}/01/${nextMonth.getFullYear()}`;

    //     return formattedDate;
    // };

    return (
        <div>
            <BootstrapDialog
                // onClose={props.handleCVPopupClose}
                aria-labelledby="customized-dialog-title"
                open={props.openCV}
                fullWidth
                maxWidth="md"
                sx={{
                    "& .MuiPaper-root": {
                        borderRadius: "8px",
                        height: { xs: "auto", md: "90vh" },
                    },
                }}
            >
                <DialogTitle
                    sx={{ m: 0, p: 2 }}
                    className="fw-bold px-4 text-truncate overflow-hidden w-75"
                    id="customized-dialog-title"
                >
                    Apply to {jobData.title}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={props.handleCVPopupClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers className="px-lg-4">
                    <Typography
                        gutterBottom
                        display={isSmallScreen ? "block" : "flex"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                    >
                        <Typography gutterBottom>
                            <Typography variant="h6" className="fw-bold" gutterBottom>
                                Cover Letter{" "}
                                <AutoAwesomeIcon sx={{ color: "rgb(156,123,227)" }} />
                            </Typography>
                            <Typography variant="body2" className={`text-muted fw-bold ${isSmallScreen ? "" : "w-75"}`}>
                                Personalize your application by attaching an AI-composed cover
                                letter for the job.
                            </Typography>
                        </Typography>
                        <Typography
                            className="pt-3"
                            textAlign={isSmallScreen ? "start" : "center"}
                            display={isSmallScreen ? "flex" : "block"}
                            gap={isSmallScreen ? 2.5 : 0}
                            justifyContent={isSmallScreen ? "" : "flex-start"}
                            alignItems={isSmallScreen ? "center" : "flex-start"}
                        >
                            <Typography gutterBottom={isSmallScreen ? false : true}>
                                <Button
                                    startIcon={ isSmallScreen ? null : <AutoAwesomeIcon />}
                                    variant="contained"
                                    onClick={props.handleGenerateCV}
                                    disabled={props.loading || props.credits <= 0}
                                    className={`rounded-pill px-lg-4 fw-bold  text-capitalize ${props.credits === 0
                                        ? "bg-secondary text-white"
                                        : "generatebtn"
                                        } `}
                                >
                                    {props.loading
                                        ? "Generating..."
                                        : props.credits <= 0
                                            ? "Insufficient Credits"
                                            : "Generate"}
                                </Button>
                            </Typography>

                            {
                                isSmallScreen ? (
                                    <Typography>
                                        <Typography variant="subtitle2" className="fw-bold">
                                            {props.credits ? props.credits : "0"} / 10 Credits
                                        </Typography>
                                        {props.credits <= 0 && (
                                            <Typography variant="subtitle2" className="fw-bold">
                                                Renews on <br /> {formattedDate}
                                            </Typography>
                                        )}
                                    </Typography>
                                )
                                    :
                                    <>
                                        <Typography variant="subtitle2" className="fw-bold">
                                            {props.credits ? props.credits : "0"} / 10 Credits
                                        </Typography>
                                        {props.credits <= 0 && (
                                            <Typography variant="subtitle2" className="fw-bold">
                                                Renews on {formattedDate}
                                            </Typography>
                                        )}
                                    </>
                            }

                        </Typography>
                    </Typography>
                    <Typography
                        onKeyDown={handleKeyDown}
                        onContextMenu={handleContextMenu}
                        onPaste={handlePaste}
                        className="pt-3 position-relative"
                    >
                        {props.loading ? (
                            <GenerateCvShimmer />
                        ) : (
                            <ReactQuill
                                value={formattedHtmlContent}
                                placeholder="Write here..."
                                onChange={props.handleCVHtmlChange}
                                onKeyDown={checkCharacterCount}
                                onKeyPress={checkCharacterCount}
                                onFocus={checkCharacterCount}
                                onBlur={checkCharacterCount}
                                onContextMenu={handleContextMenu}
                                onChangeSelection={checkCharacterCount}
                                onPaste={handlePaste}
                                ref={reactQuillRef}
                                preserveWhitespace
                                modules={{
                                    toolbar: [
                                        [{ header: [1, 2, false] }],
                                        ["bold", "italic", "underline"],
                                        [{ list: "ordered" }, { list: "bullet" }],
                                    ],
                                }}
                                theme="snow"
                                scrollingContainer={".MuiDialogContent-root"}
                            // dangerouslySetInnerHTML={{ __html: props.HtmlContent }}
                            />
                        )}
                        <Box
                            display={"flex"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            className="py-1"
                        >
                            <Typography>
                                {exceededLimit && (
                                    <Typography
                                        className="fw-bold text-danger py-2"
                                        sx={{ fontSize: "13px" }}
                                    >
                                        {" "}
                                        Maximum 4000 characters allowed{" "}
                                    </Typography>
                                )}
                            </Typography>
                            <Typography
                                className="fw-bold text-end text-muted px-1"
                                sx={{ fontSize: "10px" }}
                            >
                                {" "}
                                max-characters : {currentLength} / 4000{" "}
                            </Typography>
                        </Box>
                    </Typography>
                    <Typography gutterBottom>
                        <Typography className="fw-bold" gutterBottom>
                            Additional info
                        </Typography>
                        <Typography>
                            <FormControl fullWidth variant="outlined">
                                <OutlinedInput
                                    multiline
                                    minRows={2.5}
                                    value={props.additionInfoData}
                                    placeholder="Add more info"
                                    className="w-100"
                                    name="additionInfo"
                                    id="additionInfo"
                                    //   onInput={props.handleAdditionInfoChange}
                                    onChange={handleAdditionInfoChange}
                                    type="text"
                                    aria-describedby="additionInfo-helper-text"
                                    inputProps={{
                                        "aria-label": "additionInfo",
                                        // maxLength: 500, // Set the maximum character limit here
                                    }}
                                />
                            </FormControl>
                            {props.exceededLimitAdditionalInfo && (
                                <Typography
                                    variant="subtitle2"
                                    color="error"
                                    className="fw-bold text-danger py-2"
                                    sx={{ fontSize: "13px" }}
                                >
                                    Maximum 500 characters allowed
                                </Typography>
                            )}
                        </Typography>
                    </Typography>
                </DialogContent>
                <DialogActions
                    className="px-4"
                    sx={{ justifyContent: "space-between" }}
                >
                    <Button
                        onClick={props.handlePopUpBack}
                        variant="outlined"
                        sx={{ color: "#444a46" }}
                        className="text-muted text-capitalize fw-bold rounded-pill border-1 border-secondary px-4"
                    >
                        Back
                    </Button>
                    <Box>
                        <Button
                            onClick={props.handleCVPopupClose}
                            variant="text"
                            className="text-muted text-capitalize fw-bold"
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="outlined"
                            className="rounded-pill text-capitalize px-3 fw-bold"
                            onClick={(e) => props.handleQuickApply(e)}
                            disabled={shouldDisableButton()}
                        >
                            Apply
                        </Button>
                    </Box>
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
};

export default GenerateCVPopup;
