import React, { useEffect } from "react";
import "./Basic.css";
import { TextField, Typography, useMediaQuery } from "@mui/material";
import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Backdrops from "./Backdrop";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Basicdrawer from "./Basicdrawer";
import Userprofilecompletion from "./Userprofilecompletion";
import moment from "moment/moment.js";
import dayjs from "dayjs";
import ResendBackdrop from "../ResendBackdrop";
import { useAppSelector } from "../redux/hooks";
import { selectUser } from "../redux/modules/userSlice";
import { useNavigate } from "react-router-dom";
import Draggable from "react-draggable";
import ProfilePopup from "./profilePopup";
import DeleteIcon from "@mui/icons-material/Delete";
import DeletePopup from "./deletePopup";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { event } from "react-ga";

const Basic = ({
  data,
  duplicate,
  getData,
  setToggleChangePasswordPage,
  setEffectDependency,
  setGetName,
}) => {
  const date_modified = data?.details?.date_modified;
  const { isVerified } = useAppSelector(selectUser);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setMobile] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [address, setAddress] = useState("");
  const [workAuthorization, setWorkAuthorization] = useState({});
  const [gender, setgender] = useState("");
  const [dob, setdob] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [flag, setFlag] = useState(true);
  const [onclickdrawer, setonclickdrawer] = useState(false);

  const [emailPopupOpen, setEmailPopupOpen] = useState(false);
  const [numberPopupOpen, setNumberPopupOpen] = useState(false);
  const [alternativeEmail1, setAlternativeEmail1] = useState("");
  //const [alternativeEmail2, setAlternativeEmail2] = useState("");
  //const [email1, setEmail1] = useState("");
  const [checked, setChecked] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isPhoneNumberAdded, setIsPhoneNumberAdded] = useState(false);
  const [deletePopupOpen, setDeletePopupOpen] = useState(false);
  const [deleteNoPopupOpen, setDeleteNoPopupOpen] = useState(false);
  // const [alternateEmails, setAlternateEmails] = useState([
  //   {id:1, alternateEmail: alternativeEmail1},
  //   {id:2, alternateEmail: alternativeEmail2}
  // ]);
  const [contacts, setContacts] = useState([]);
  const [contactId, setContactId] = useState("");
  const [deleteEmail, setDeleteEmail] = useState("");
  const [deleteNoId, setDeleteNoId] = useState("");

  //const [contactDetails, setContactDetails] = useState([]);
  const [phoneNo, setPhoneNo] = useState([]);

  const nav = useNavigate();
  const linkedInRegex =
    /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)\/([-a-zA-Z0-9]+)\/*/gm;

  const handledrawer = () => {
    setonclickdrawer(true);
  };

  const handleResend = () => {
    setIsOpen(true);
    axios
      .post(`user/resend-verification/mail`, {
        email: data?.details?.email,
      })
      .then((response) => {
        if (response.status === 200) {
          setIsOpen(false);
          toast.success("Verification link sent to your mail", {
            hideProgressBar: true,
            closeOnClick: true,
          });
        } else {
          setIsOpen(false);
          toast.error("Please try again", {
            hideProgressBar: true,
            closeOnClick: true,
          });
        }
      });
  };

  const drawerBasicProps = {
    basicDrawerField: {
      fullname: name,
      mobile: number,
      email,
      address,
      gender,
      dob,
      countryCode,
      work_authorization: workAuthorization,
      work_authorization_text : data?.details?.work_authorization_text
    },
    setonclickdrawer,
    setEffectDependency,
  };

  useEffect(() => {
    setIsOpen(true);
    setName(data?.details?.name);
    setGetName(data?.details?.name);
    setEmail(data?.details?.email);
    setdob(data?.details?.dob);
    setMobile(data?.details?.mobile);
    setCountryCode(data?.details?.countryCode);
    setgender(data?.details?.gender);
    // setLinkdin(response?.data?.message?.basic_details?.linkedIn ? response?.data?.message?.basic_details?.linkedIn : '');
    setAddress(data?.details?.address);
    setWorkAuthorization(data?.details?.work_authorization);
    setContacts(data?.details?.emaliContacts);
    setPhoneNo(data?.details?.mobileContacts);
    //console.log("mobile", data?.details?.mobileContacts[0]?.contact);
    //setContactDetails(data?.details?.emaliContacts);
    //console.log(data?.details?.emaliContacts , "contacts");

    setChecked(
      data?.details?.emaliContacts?.map((contact) =>
        contact?.allow_notification === 1 ? true : false
      )
    );

    setIsOpen(false);
  }, [data?.details]);

  //console.log("contactssss",contactDetails.length);

  const handleOpenPopup = (type) => {
    if (type === "email") {
      setEmailPopupOpen(true);
    } else if (type === "number") {
      setNumberPopupOpen(true);
    }
  };

  // const contactEmail = contacts?.map((contact) => {
  //   return contact?.email
  // })
  // const elementWithId2 = contacts.map(item => item.id === 1);
  // const nameOfId2 = elementWithId2 ? elementWithId2.contact : 'Not found';
  // console.log(nameOfId2, "nameOfId2");
  const handleClosePopup = () => {
    //setPhoneNumber('');
    setNumberPopupOpen(false);
    setEmailPopupOpen(false);
  };
  const handleOpenDeletePopup = (contact, index, type) => {
    //console.log(type,"type");
    if (type === "email") {
      //console.log(contact, index);
      setContactId(contact.contact_id);
      setDeleteEmail(contact.contact);
      setDeletePopupOpen(true);
    } else if (type === "number") {
      setDeleteNoPopupOpen(true);
      setDeleteNoId(contact.contact_id);
    }
  };
  const handleCloseDeletePopup = () => {
    setDeletePopupOpen(false);
    setDeleteNoPopupOpen(false);
    //console.log(deleteNoId, "deleteNoId");
    //console.log(contactId, "contactId");
  };
  const handleCheckboxChange = async (event, contact, index) => {
    const isChecked = event.target.checked;

    let payload = {
      contact_id: contact.contact_id,
      allow_notification: isChecked,
    };

    try {
      const response = await axios.post("allow-notification", payload, {
        headers: { authorization: document.cookie },
      });

      if (response.data.status === 200) {
        toast.success(response.data.message, {
          hideProgressBar: true,
          closeOnClick: true,
        });
        setEffectDependency((prev) => !prev);
      } else {
        toast.error(response.data.message, {
          hideProgressBar: true,
          closeOnClick: true,
        });
      }
    } catch (error) {
      // Handle error
      console.error("An error occurred:", error);
      toast.error("An error occurred while processing your request.", {
        hideProgressBar: true,
        closeOnClick: true,
      });
    }
  };

  const isSmallScreen = useMediaQuery("(max-width: 625px)");

  return (
    <>
      <div className="basic-page ">
        {isOpen && <ResendBackdrop />}
        {onclickdrawer ? (
          <Basicdrawer
            date_modified={date_modified}
            drawerBasicProps={drawerBasicProps}
            duplicate={duplicate}
          />
        ) : (
          ""
        )}
        <div className="jobeze-basic-div">
          <div className="jobeze-basic-details">Basic Details</div>
          <div
            style={{
              cursor: "pointer",
              padding: "8px",
              borderRadius: "50%",
              transition: "background-color 0.3s",
            }}
            className="hover-effect"
          >
            <div className="web-edit">
              <EditIcon
                color="primary"
                onClick={handledrawer}
                style={{ fontSize: 24 }}
              />
            </div>
            <div className="edit-drag">
              <EditIcon
                color="primary"
                onClick={handledrawer}
                style={{ fontSize: 24 }}
              />
            </div>
          </div>
        </div>
        <div className="jobeze-details-block">
          <div className="jobeze-candidate-details">
            <div>Name</div>
            <div>Gender</div>
            <div>Date of Birth</div>
            <div>Work Authorization</div>
            <div>Current Address</div>
          </div>
          <div className="jobeze-candidate-filled-details">
            <div className="text-capitalize break-words">
              {name ? name : "NA"}
            </div>
            <div>
              {gender === "M"
                ? "Male"
                : gender === "F"
                  ? "Female"
                  : gender === "O"
                    ? "Prefer not to respond"
                    : "NA"}
            </div>
            <div>
              {dob
                ? dob.$d
                  ? moment(dob.$d).format("MM-DD-YYYY")
                  : moment(dob).format("MM-DD-YYYY")
                : "NA"}
            </div>
            <div className="fyi break-words">
              {/* {!(
                workAuthorization?.work_authorization === "not available" ||
                workAuthorization?.work_authorization === "Not Available"
              )
                ? workAuthorization?.work_authorization
                : "NA"} */}
              {(() => {
                const workAuth = workAuthorization?.work_authorization;
                const workAuthText = data?.details?.work_authorization_text;
                console.log(workAuth, workAuthText);
                if (
                  workAuth === "not available" ||
                  workAuth === "Not Available" ||
                  workAuth === "NA"
                ) {
                  return "NA";
                }

                if (workAuth === "Others" && workAuthText) {
                  return `${workAuthText}`;
                }
                return workAuth;
              })()}
            </div>
            <div className="fyi break-words">{address ? address : "NA"}</div>
          </div>
        </div>
      </div>
      {isSmallScreen ? (
        <>
          <div className="border-top border-2 p-3">
            <h4 className="fw-bold px-1">Contact Details</h4>
            <div className="d-flex flex-column gap-2 pt-3">
              <div className=" d-flex justify-content-between fw-bold p-2">
                <div className="text-muted">Email</div>
                <div className="break-words overflow-hidden">
                  {email ? email : "NA"}
                </div>
              </div>
              <div className="">
                {contacts?.map(
                  (contact, index) =>
                    index < 2 && (
                      <div className="d-flex flex-column gap-2 border-top py-2">
                        <div
                          className="d-flex justify-content-between fw-bold px-2 align-items-center"
                          key={index}
                        >
                          <div className="text-muted">
                            Alternate Email {index + 1}
                          </div>
                          <div className="break-words text-dark">
                            <Typography
                              className="fw-bold overflow-hidden"
                              sx={{ textOverflow: "ellipsis" }}
                            >
                              {contact.contact}
                            </Typography>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center px-2">
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    contacts[index]?.allow_notification == 1
                                      ? true
                                      : false
                                  }
                                  onChange={(event) =>
                                    handleCheckboxChange(event, contact, index)
                                  }
                                />
                              }
                              label={
                                <span style={{ fontSize: "0.8rem" }}>
                                  Receive notification on this Email
                                </span>
                              }
                            />
                          </FormGroup>
                          <div className="">
                            <Button
                              variant="text"
                              color="error"
                              className="fw-bold"
                              startIcon={<DeleteIcon />}
                              onClick={() =>
                                handleOpenDeletePopup(contact, index, "email")
                              }
                            ></Button>
                          </div>
                        </div>
                      </div>
                    )
                )}
                {isVerified ? (
                  <>
                    {contacts && contacts.length < 2 && (
                      <div
                        className="d-flex justify-content-between align-items-center border-top fw-bold p-2"
                        key={1}
                      >
                        <div className="text-muted fw-bold">
                          Alternate Email {contacts ? contacts.length + 1 : 1}
                        </div>
                        <Button
                          className="fw-bold"
                          variant="text"
                          onClick={() => handleOpenPopup("email")}
                        >
                          Add Email
                        </Button>
                      </div>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div>
                {phoneNo?.map((mobileNo, index) => (
                  <>
                    <div
                      key={index}
                      className="fw-bold d-flex justify-content-between align-items-center border-top p-2 pt-3"
                    >
                      <div className="">
                        <div className="text-muted">Mobile Number </div>
                      </div>
                      <div className="text-end">
                        <div className="break-words">
                          {!mobileNo.contact ? (
                            <Button
                              variant="text"
                              onClick={() => handleOpenPopup("number")}
                              className="fw-bold"
                            >
                              Add Number
                            </Button>
                          ) : (
                            <div className="">
                              <span>{"+1 " + mobileNo.contact}</span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-end">
                      <Button
                        variant="text"
                        color="error"
                        className="fw-bold me-2"
                        startIcon={<DeleteIcon />}
                        onClick={() =>
                          handleOpenDeletePopup(mobileNo, index, "number")
                        }
                      ></Button>
                    </div>
                  </>
                ))}
                {phoneNo && phoneNo.length < 1 && (
                  <div
                    className="border-top d-flex justify-content-between align-items-center fw-bold p-2"
                    key={1}
                  >
                    <div className="">
                      <div className="text-muted">Mobile Number</div>
                    </div>
                    <Button
                      className="fw-bold"
                      variant="text"
                      onClick={() => handleOpenPopup("number")}
                    >
                      Add Number
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="basic-page mt-4 py-3 fw-bold ">
          <h4>
            <div className="jobeze-basic-details px-4 pb-2">
              Contact Details
            </div>
          </h4>
          <div className="jobeze-details-block py-0">
            <div
              className="jobeze-candidate-details"
              style={{ paddingTop: `${isVerified === 1 ? "" : "8px"}` }}
            >
              <div className="text-muted">Email</div>
            </div>
            <div className="jobeze-candidate-filled-details pb-3">
              <div className="break-words overflow-hidden">
                {email ? email : "NA"}
                {isVerified === 0 ? (
                  <Button
                    className="fw-bold verify-hide "
                    variant="text"
                    onClick={handleResend}
                  >
                    Verify
                  </Button>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          <div className="">
            {contacts?.map(
              (contact, index) =>
                index < 2 && (
                  <div className="jobeze-details-block pb-2" key={index}>
                    <div className="jobeze-candidate-details py-2">
                      <div className="text-muted">
                        Alternate Email {index + 1}
                      </div>
                    </div>
                    <div className="jobeze-candidate-filled-details">
                      <div className="break-words">
                        <>
                          <div className="jobeze-contact-details-delete">
                            <Typography
                              className="fw-bold w-75 overflow-hidden  "
                              sx={{ textOverflow: "ellipsis" }}
                            >
                              {contact.contact}
                            </Typography>
                            <Button
                              variant="text"
                              color="error"
                              className="fw-bold"
                              startIcon={<DeleteIcon />}
                              onClick={() =>
                                handleOpenDeletePopup(contact, index, "email")
                              }
                            >
                              Delete
                            </Button>
                          </div>
                          <div style={{ marginTop: "0px" }}>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={checked[index]}
                                    onChange={(event) =>
                                      handleCheckboxChange(
                                        event,
                                        contact,
                                        index
                                      )
                                    }
                                    sx={{
                                      "& .MuiSvgIcon-root": {
                                        fontSize: "1.2rem",
                                        marginTop: "1px",
                                      },
                                    }}
                                  />
                                }
                                label={
                                  <Typography
                                    className="fw-bold"
                                    style={{ fontSize: "0.8rem" }}
                                  >
                                    Receive notification on this Email
                                  </Typography>
                                }
                              />
                            </FormGroup>
                          </div>
                        </>
                      </div>
                    </div>
                  </div>
                )
            )}
            {isVerified ? (
              <>
                {contacts && contacts.length < 2 && (
                  <div className="jobeze-details-block pb-2" key={1}>
                    <div className="jobeze-candidate-details py-2">
                      <div className="text-muted">
                        Alternate Email {contacts ? contacts.length + 1 : 1}
                      </div>
                    </div>
                    <div className="jobeze-candidate-filled-details">
                      <div className="break-words">
                        {" "}
                        <>
                          <Button
                            className="fw-bold"
                            variant="text"
                            onClick={() => handleOpenPopup("email")}
                          >
                            Add Email
                          </Button>
                        </>
                      </div>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <></>
            )}
          </div>
          <div>
            {phoneNo?.map((mobileNo, index) => (
              <div key={index} className="jobeze-details-block py-0">
                <div className="jobeze-candidate-details py-1">
                  <div className="text-muted">Mobile Number </div>
                </div>
                <div className="jobeze-candidate-filled-details">
                  <div className="break-words">
                    {!mobileNo.contact ? (
                      <Button
                        variant="text"
                        onClick={() => handleOpenPopup("number")}
                        className="fw-bold"
                      >
                        Add Number
                      </Button>
                    ) : (
                      <>
                        <div className="jobeze-contact-details-delete">
                          <span>{"+1 " + mobileNo.contact}</span>
                          {/* <Button variant="text" onClick={() => {
                                  setPhoneNumber(mobileNo.contact);
                                  handleOpenPopup("number");
                                  }} className="fw-bold verify-hide">
                                  Edit
                                </Button> */}
                          <Button
                            variant="text"
                            color="error"
                            className="fw-bold"
                            startIcon={<DeleteIcon />}
                            onClick={() =>
                              handleOpenDeletePopup(mobileNo, index, "number")
                            }
                          >
                            Delete
                          </Button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            ))}
            {phoneNo && phoneNo.length < 1 && (
              <div className="jobeze-details-block pb-2" key={1}>
                <div className="jobeze-candidate-details py-2">
                  <div className="text-muted">Mobile Number</div>
                </div>
                <div className="jobeze-candidate-filled-details">
                  <div className="break-words">
                    <>
                      <Button
                        className="fw-bold verify-hide"
                        variant="text"
                        onClick={() => handleOpenPopup("number")}
                      >
                        Add Number
                      </Button>
                    </>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {/* 
          <div className="jobeze-details-block py-0">
          <div className="jobeze-candidate-details py-1">
            <div className="text-muted">Mobile Number</div>
          </div>
          <div className="jobeze-candidate-filled-details">
            <div className="break-words">
              {" "}
              {!isPhoneNumberAdded && !phoneNo ? (
                <Button variant="text" onClick={() => handleOpenPopup("number")} className="fw-bold verify-hide">
                  Add Number
                </Button>
              ) : <>
                {phoneNo}
                <Button variant="text" onClick={() => {
                  setPhoneNumber(phoneNo);
                  handleOpenPopup("number")
                }} className="fw-bold verify-hide">
                  Edit
                </Button>
              </>}
 
            </div>
          </div>
        </div> 
        */}
      <div
        className="jobeze-change-pass"
        style={{ cursor: "pointer" }}
        onClick={() => {
          setToggleChangePasswordPage(true);
          window.scrollTo({ top: 0, behavior: "smooth" });
        }}
      >
        <div className="jobeze-change-heading">Change Password</div>
        <div className="jobeze-forward-icon">
          <ArrowForwardIosIcon />
        </div>
      </div>

      <ProfilePopup
        handleClosePopup={handleClosePopup}
        emailPopupOpen={emailPopupOpen}
        setEmailPopupOpen={setEmailPopupOpen}
        numberPopupOpen={numberPopupOpen}
        alternativeEmail1={alternativeEmail1}
        setAlternativeEmail1={setAlternativeEmail1}
        phoneNumber={phoneNumber}
        setPhoneNumber={setPhoneNumber}
        isPhoneNumberAdded={isPhoneNumberAdded}
        setIsPhoneNumberAdded={setIsPhoneNumberAdded}
        setNumberPopupOpen={setNumberPopupOpen}
        phoneNo={phoneNo}
        setPhoneNo={setPhoneNo}
        setContacts={setContacts}
        setEffectDependency={setEffectDependency}
      />
      <DeletePopup
        handleCloseDeletePopup={handleCloseDeletePopup}
        deletePopupOpen={deletePopupOpen}
        deleteNoId={deleteNoId}
        phoneNo={phoneNo}
        setPhoneNo={setPhoneNo}
        deleteNoPopupOpen={deleteNoPopupOpen}
        contacts={contacts}
        setContacts={setContacts}
        contactId={contactId}
        deleteEmail={deleteEmail}
        setEffectDependency={setEffectDependency}
        setPhoneNumber={setPhoneNumber}
      />
      <div
        className="jobeze-change-pass1"
        style={{ cursor: "pointer" }}
        onClick={() => nav("/change-password")}
      >
        <div className="jobeze-change-heading">Change Password</div>
        <div className="jobeze-forward-icon">
          <ArrowForwardIosIcon />
        </div>
      </div>
    </>
  );
};

export default Basic;
