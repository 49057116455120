import React from 'react'
import "./Shimmercard.css"

const Shimmercard = () => {
  return (
    <div>
      <div>
	<div className="box">
  <div className="skeleton">
  	    <div className="skeleton-left">
			<div className="line h17 w40 m10"></div>
			<div className="line w20" ></div>
            <div className="line"></div>
            <div className="line  w75"></div>
			<div className="line h8 w50"></div>
	    </div>
    </div>
  </div>
</div>
    </div>
  )
}

export default Shimmercard
