import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Homeheader from "../components/homeheader";
import SavedJobs from "../components/SavedJobs";
import FooterZ from "../components/FooterZ";
import RecommendedJobs from "../components/RecommendedJobs";
import Recommendedempty from "../components/Recommendedempty";
import { useParams, useSearchParams } from "react-router-dom";
import ShortList from "../components/shortList";
import { Helmet } from "react-helmet";

const baseURL = process.env.REACT_APP_BASEURL;

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ zIndex: "1" }}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  let [searchParams, setSearchParams] = useSearchParams();
  const initialValue =
    searchParams.get("job") === "recommendedJobs"
      ? 0
      : searchParams.get("job") === "shortlistedJobs"
        ? 2
        : 1;
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    if (searchParams.get("job") === "recommendedJobs") {
      setValue(0);
    } else if (searchParams.get("job") === "savedJobs") {
      setValue(1);
    } else if (searchParams.get("job") === "shortlistedJobs") {
      setValue(2);
    }
  }, [searchParams.get("job")]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
      setSearchParams({ job: "recommendedJobs" });
    } else if (newValue === 2) {
      setSearchParams({ job: "shortlistedJobs" });
    } else if (newValue === 1) {
      setSearchParams({ job: "savedJobs" });
    }
  };

  return (
    <>
      <Helmet>
        <title>Preferred Jobs – Tailored Job Listings for You</title>
        <meta name="title" content='Preferred Jobs – Tailored Job Listings for You' />
        <meta name="description" content="Explore your preferred jobs on Jobeze. Find tailored job listings that match your skills and preferences for an efficient job search experience." />
      </Helmet>
      <div>
        <>
          <Homeheader />
        </>
        <Box className=" px-4 bg-white py-2">
          <div className="container p-0">
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              className="p-0"
            >
              <Tab
                style={{ fontWeight: "600" }}
                label="Recommended"
                {...a11yProps(0)}
              />
              <Tab
                style={{ fontWeight: "600" }}
                className="p-0"
                label="Saved"
                {...a11yProps(1)}
              />
              <Tab
                style={{ fontWeight: "600" }}
                className="px-3"
                label="Shortlisted"
                {...a11yProps(2)}
              />
            </Tabs>
          </div>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <RecommendedJobs />
        </CustomTabPanel>
        <div style={{ background: "#f6f6f6" }}>
          <CustomTabPanel value={value} index={1}>
            <SavedJobs />
          </CustomTabPanel>
        </div>
        <CustomTabPanel value={value} index={2}>
          <ShortList />
        </CustomTabPanel>
        <div className="">
          <FooterZ />
        </div>
      </div>
    </>
  );
}
