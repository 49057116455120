import * as React from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ClearIcon from "@mui/icons-material/Clear";
import Creatable from "react-select/creatable";
import { Divider, Typography } from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import _debounce from 'lodash/debounce';

const baseURL = process.env.REACT_APP_BASEURL;

const styles = {
  option: (styles, state) => ({
    ...styles,
    cursor: "pointer",
  }),
  control: (styles, state) => ({
    ...styles,
    cursor: "pointer",
  }),
  menu: (styles, state) => ({
    ...styles,
    cursor: "pointer",
  }),
  singleValue: (styles, state) => ({
    ...styles,
    cursor: "pointer",
  }),
  input: (styles, state) => ({
    ...styles,
    cursor: "pointer",
  }),
};

const DropdownIndicator = () => null;
export default function Recommendedrawer({ setopen, setClose, updateSkilledDrawer, setUpdateSkilledDrawer }) {
  const [selectOption, setSelectOption] = React.useState([]);
  const [skills, setSkills] = React.useState([]);
  const [state, setState] = React.useState({ right: true });
  const [highestEducationData, setHighestEducationData] = React.useState({});
  const [linkedinData, setLinkedinData] = React.useState([]);
  const [portfolioData, setPortfolioData] = React.useState([]);
  const [date_modified, setDate_modified] = React.useState("");

  const skillssearch = async (val) => {
    try {
      if (val) {
        const response = await axios.get(
          // process.env.REACT_APP_BASEURL + `/all-skills?search=${val}`,
          `all-skills?search=${val}`,
        );
        // console.log("response", response);
        setSelectOption(response?.data?.message);
      }
      // getData();

    } catch (error) {
      console.error("Error", error);
    }
  };

  // React.useEffect(() => {
  //   console.log('effect pageeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee', janewaliSkill);
  //   skillssearch(janewaliSkill);
  // }, [janewaliSkill]);


  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleChange = (data) => {
    setSkills(data);
  };

  const getData = async () => {
    try {
      const response = await axios.get(`user/dashboard/details`, {
        headers: { authorization: document.cookie },
      });
      const skillsData = response.data?.message?.details?.skill;
      setSkills(skillsData);
      setHighestEducationData(response.data?.message?.details?.highest_educ);
      console.log(highestEducationData,"955");
      setLinkedinData(response.data?.message?.details?.linkedIn);
      setPortfolioData(response.data?.message?.details?.portfolio);
      setDate_modified(response.data.message.details.date_modified);
      // setSelectOption(skillsData.map((skill) => ({ label: skill, value: skill })));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  React.useEffect(() => {
    getData();
  }, []);

  const handleSkills = () => {
    if (skills.length < 5) {
      toast.error("Please select atleast 5 skills", {
        hideProgressBar: true,
        closeOnClick: true,
      })
    }
    else {
      const payload = {
        highest_educ: highestEducationData.highest_education_id,
        linkedin: linkedinData,
        portfolio: portfolioData,
        profile: "other",
        token: document.cookie,
        skill: skills.map((lab) => lab.label),
        original_date_modified: date_modified
      };
      axios
        .post(`user/details/update-details`, payload)
        .then((res) => {
          if (res.status === 201) {
            setClose(false);
            setUpdateSkilledDrawer(true);
            toast.success("Skills updated successfully", {
              hideProgressBar: true,
              closeOnClick: true,
            });
          } else {
            toast.error("Please Try Again After Sometime", {
              hideProgressBar: true,
              closeOnClick: true,
            });
          }
        })
        .catch((err) => {
          toast.error("Please Try Again After Sometime error", {
            hideProgressBar: true,
            closeOnClick: true,
          });
        });
    }
  };


  const handleInputChange = _debounce((inputValue) => {
    // console.log("inputValue", inputValue);

    if (inputValue.trim() === '') {
      // If input is empty, clear the options array
      setSelectOption([]);
    } else {
      // Call your API search function here (skillssearch)
      skillssearch(inputValue);
    }
  }, 415);

  return (
    <>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onOpen={toggleDrawer(anchor, true)}

          >
            <Box
              sx={{
                width: anchor === "top" || anchor === "bottom" ? "auto" : 425,
                height: "100vh",
              }}
              role="presentation"
            >
              <List className="fx" sx={{height: "100%"}}>
                <Box sx={{ height: "100%" }} display={"flex"} flexDirection={"column"} >
                  <Typography variant="h6" display={"flex"} justifyContent={"space-between"} px={2} py={1.5}>
                    <Typography variant="h6" fontWeight={600}>Add Skills</Typography>
                    <ClearIcon sx={{ cursor: 'pointer' }} onClick={() => setClose(false)} />
                  </Typography>
                  <Divider sx={{ border: '1px solid' }} />
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    justifyContent={"space-between"}
                    sx={{ height: "100%" }}
                  >

                    <Box display={"flex"} flexDirection={"column"} px={2} gap={1.5} py={2}>
                      <Typography variant="h6" fontWeight={600} letterSpacing={1.1} >
                        Skills
                      </Typography>
                      <Creatable
                        value={skills}
                        onChange={(data) => handleChange(data)}
                        onInputChange={handleInputChange}
                        options={selectOption}
                        isMulti
                        isClearable
                        name="colors"
                        className="basic-multi-select"
                        classNamePrefix="select"
                        allowCreateWhileLoading
                        components={{ DropdownIndicator }}
                        styles={styles}
                        noOptionsMessage={() => "Enter your skills"}
                      />

                    </Box>

                    <Box>
                      <Divider sx={{ border: '1px solid' }} />
                      <Box pt={1} px={2} display={"flex"} justifyContent={"space-between"} gap={1}>
                        <Button
                          sx={{ fontWeight: "600" }}
                          variant="text"
                          onClick={() => setClose(false)}
                        >
                          CANCEL
                        </Button>
                        <Button variant="contained" onClick={handleSkills} >SAVE</Button>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </List>
            </Box>
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </>
  );
}
