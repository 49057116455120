import React, { useState } from 'react';
import Alert from '@mui/material/Alert';
import { Box, Button, Stack, Typography, useMediaQuery } from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Backdrops from './Backdrop';
import { useAppDispatch } from '../redux/hooks';
import { getUser } from '../redux/modules/userSlice';
import ResendBackdrop from '../ResendBackdrop';


const AlertShow = (props) => {
  const nav = useNavigate();
  const isSmallScreen = useMediaQuery('(max-width: 625px)');
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useAppDispatch();
  const handleResend = () => {
    setIsOpen(true);
    axios.post(`user/resend-verification/mail`, { email: props?.email ? props?.email : localStorage.getItem('email') }).then((response) => {
      if (response.status === 200) {
        setIsOpen(false);
        toast.success("Verification link sent to your mail", { hideProgressBar: true, closeOnClick: true });
      }
      else {
        setIsOpen(false);
        toast.error("Please try again", { hideProgressBar: true, closeOnClick: true });
      }
    })
  }
  return (
    <div className='AlertShow'>
      {isOpen && <ResendBackdrop />}
      <Alert
        className={`w-100 ${isSmallScreen ? 'rounded-0 shadow-sm ' : ''} `}
        variant={!isSmallScreen ? 'filled' : 'standard'}
        severity={props.status}
        onClose={() => { props.setAlert(false) }}
        action={
          <Button color="inherit" className={` fw-bold ${isSmallScreen ? 'm-auto ' : ''}  `} size="small" onClick={handleResend}>
            Verify
          </Button>
        }
      >
        <div className='fw-bold' style={{ letterSpacing: '0.17px' }} >{props.title}</div>
      </Alert>
    </div>
  )
}

export default AlertShow