import React, { useEffect, useState } from 'react';
import Creatable from 'react-select/creatable';
import axios from 'axios';
import _debounce from 'lodash/debounce';


const OtherSkill = (props) => {
  const [selectedOption, setSelectedOption] = useState([]);
  const [selectOption, setSelectOption] = useState([]);
  

  const styles = {
    option: (styles, state) => ({
      ...styles,
      cursor: 'pointer'
    }),
    control: (styles, state) => ({
      ...styles,
      cursor: 'pointer',

    }),
    menu: (styles, state) => ({
      ...styles,
      cursor: 'pointer'
    }),
    singleValue: (styles, state) => ({
      ...styles,
      cursor: 'pointer'
    }),
    input: (styles, state) => ({
      ...styles,
      cursor: 'pointer'
    })

  }

  // console.log("present selected skills",props.drawerData.skill)
  // console.log("duplicate skills",props.duplicate.details.skill)



    const skillssearch = async (val) => {
      // console.log("valllllllllllllllllllllllllllllllllllllllllll", val);
      try {
        if (val) {
          const response = await axios.get(
            // process.env.REACT_APP_BASEURL + `/all-skills?search=${val}`,
            `all-skills?search=${val}`,
          );
          // console.log("response", response);
          setSelectOption(response.data.message);
        }
      } catch (error) {
        console.error("Error", error);
      }
    };


    const handleInputChange = _debounce((inputValue) => {
      // Call your API search function here (skillssearch)
      skillssearch(inputValue);
    },415); 



    const areArraysEqual = (arr1, arr2) => {
      if (arr1?.length !== arr2?.length) {
        return false;
      }
    
      for (let i = 0; i < arr1.length; i++) {
        if(!arr2.some(obj => obj.label === arr1[i].label)) {
          return false;     
        }
      }
    
      return true;
    };

  const handleChange = (data) => {
    const trimmedData = data.map((e) => {
      return {
        label: e.label.trim(),
        value: e.value
      }
    });

    const ids = trimmedData.map(({ label }) => label.toLowerCase());
    const filteredData = trimmedData.filter(({ label }, index) =>
      !ids.includes(label.toLowerCase(), index + 1));


    props.SetDrawerData((prev) => {
      return { ...prev, skill: filteredData };
    });

    if(areArraysEqual(filteredData, props.duplicate?.details?.skill)) {
      props._disableskill(true);
    } else {
      props._disableskill(false);
    }
  };
  


  const DropdownIndicator = () => null;

  return (
    <div>
      <Creatable
        value={props.drawerData.skill}
        onChange={(data) => handleChange(data)}
        onInputChange={(val) => handleInputChange(val)}
        options={selectOption}
        isMulti
        isClearable
        name="colors"
        className="basic-multi-select"
        classNamePrefix="select"
        allowCreateWhileLoading
        components={{ DropdownIndicator }}
        styles={styles}
        noOptionsMessage={() => "Enter your skills"}
      />
    </div>
  )
}

export default OtherSkill