import {
    Box,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    TextField,
    Typography,
} from "@mui/material";
import React from "react";
// question_type = 1 : Descriptive
// question_type = 2 : Numeric
// question_type = 3 : Yes/No


const QuestionList = (props) => {



    return (
        <Box display={"flex"} flexDirection={"column"} gap={2}>
            {props.question.map((item, index) => {
                return (
                    <div key={index}>

                        {item.question_type === 1 && (
                            <Box display={"flex"} flexDirection={"column"} gap={1}>
                                <Typography variant="body1" className="fw-bold text-muted">
                                    {/* {index + 1}. {item.question} */}
                                    {index + 1}. {item.question.charAt(0).toUpperCase() + item.question.slice(1)}
                                </Typography>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    type="text"
                                    placeholder="Please enter answer"
                                    multiline
                                    rows={4}
                                    InputProps={{
                                        className: "pe-1 px-3",
                                        inputProps: {
                                            className: "jbscrolling p-0",
                                            maxLength: 501
                                        },
                                    }}
                                    fullWidth
                                    autoFocus={index === 0}
                                    required
                                    value={props.textFields[item.question_id] || ""}
                                    onChange={(e) => props.handleTextFieldChange(e, item.question_id, index)}
                                    error={(props.quesAnsArray[index].error !== null)}
                                    helperText={props.quesAnsArray[index].error}
                                />
                            </Box>
                        )}

                        {item.question_type === 2 && (
                            <Box display={"flex"} flexDirection={"column"} gap={1}>
                                <Typography variant="body1" className="fw-bold text-muted">
                                    {/* {index + 1}. {item.question} */}
                                    {index + 1}. {item.question.charAt(0).toUpperCase() + item.question.slice(1)}
                                </Typography>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    type="tel"
                                    fullWidth
                                    placeholder="Enter the number"
                                    required
                                    InputProps={{
                                        className: "px-0",
                                        inputProps: {
                                            maxLength: 13,
                                            onKeyPress: (e) => {
                                                const charCode = e.which ? e.which : e.keyCode;
                                                if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                                                    e.preventDefault();
                                                }
                                            },
                                            pattern: "[0-9]*"
                                        }
                                    }}
                                    value={props.textFields[item.question_id] || ""}

                                    onPaste={(e) => {
                                        e.preventDefault();
                                        const pastedText = e.clipboardData.getData('text/plain');
                                        const filteredText = pastedText.replace(/[^\d]/g, ''); // Keep only digits
                                        navigator.clipboard.writeText(filteredText)
                                            .then(() => {
                                                // Update the field value after pasting filtered text
                                                e.target.value = filteredText;
                                                props.handleTextFieldChange(e, item.question_id, index);
                                            })
                                            .catch(err => {
                                                console.error('Error writing to clipboard: ', err);
                                            });
                                    }}

                                    onChange={(e) => props.handleTextFieldChange(e, item.question_id, index)}
                                    error={(props.quesAnsArray[index].error !== null)}
                                    helperText={props.quesAnsArray[index].error}
                                />
                            </Box>
                        )}

                        {item.question_type === 3 && (
                            <Typography key={item.question_id} className="fw-bold text-dark">
                                <FormControl
                                    className="fw-bold text-dark"
                                    id={item.question_id}
                                >
                                    <FormLabel
                                        id="demo-row-radio-buttons-group-label"
                                        className="fw-bold text-muted"
                                    >
                                        {/* {index + 1}. {item.question} */}
                                        {index + 1}. {item.question.charAt(0).toUpperCase() + item.question.slice(1)}
                                    </FormLabel>
                                    <RadioGroup
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name={`row-radio-buttons-group-${item.question_id}`}
                                        className="fw-bold text-dark"
                                        required
                                        // value={props.radioValues[item.question_id] || ""}
                                        onChange={(e) => props.handleRadioChange(e, item.question_id, index)}
                                    >
                                        <FormControlLabel
                                            value="yes"
                                            //value={item.ques_ans[0].answer_id}
                                            id={item.ques_ans[0].answer_id}
                                            className="fw-bold text-dark"
                                            control={<Radio />}
                                            label="Yes"
                                        />
                                        <FormControlLabel
                                            value="no"
                                            //value={item.ques_ans[1].answer_id}
                                            id={item.ques_ans[1].answer_id}
                                            className="fw-bold text-dark"
                                            control={<Radio />}
                                            label="No"
                                        />
                                    </RadioGroup>

                                    {
                                        props.quesAnsArray[index].error &&
                                        <Typography variant="caption" className="px-3 text-danger">
                                            {props.quesAnsArray[index].error}
                                        </Typography>
                                    }



                                </FormControl>
                            </Typography>
                        )}

                    </div>
                );
            })}
        </Box>
    );
};

export default QuestionList;
