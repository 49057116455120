import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { FormControl, InputLabel, MenuItem, Select, TextField, Typography, useMediaQuery } from '@mui/material';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ClearIcon from '@mui/icons-material/Clear';
import axios from 'axios';
import dayjs, { Dayjs } from 'dayjs';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import OtherSkill from './OtherSkill';
import { useState } from 'react';
import { toast } from "react-toastify";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const validateLinkedInURL = (url) => {
  const pattern = /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)\/([-a-zA-Z0-9]+)\/*/gm;
  return pattern.test(url);
};

export default function Otherdrawer({ drawerOtherProps, duplicate, date_modified }) {

  const [drawerData, SetDrawerData] = React.useState(drawerOtherProps.OtherDrawerField);
  const [edu, setEdu] = useState('');
  const [linkedinError, setLinkedinError] = React.useState(false);
  const [linkedinHelperText, setLinkedinHelperText] = React.useState(false);
  const [flag, setFlag] = useState(true);
  const [skill, setSkill] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [state, setState] = React.useState({ right: true });
  const [portfolioError, setPortfolioError] = React.useState(false);
  const [portfolioHelperText, setPortfolioHelperText] = React.useState('');



  const [disablehighest_educ, _disablehighest_educ] = useState(true);
  const [disablelinkedin, _disablelinkedin] = useState(true);
  const [disableportfolio, _disableportfolio] = useState(true);
  const [disableskill, _disableskill] = useState(true);



  const duplicates = {
    highest_educ: duplicate?.details?.highest_educ,
    linkedin: duplicate?.details?.linkedIn,
    portfolio: duplicate?.details?.portfolio,
    skill: duplicate?.details?.skill
  }
  const validateURL = (url) => {
    const pattern = /(https: \/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})(\.[a-zA-Z0-9]{2,})?/;
    return pattern.test(url);
  };

  const MAX_PORTFOLIO_URL_LENGTH = 128;
  const validatePortfolioURL = (url) => {
    return validateURL(url) && url.length <= MAX_PORTFOLIO_URL_LENGTH;
  };

  const handlePortfolioFieldFocus = () => {
    setPortfolioError(false);
    setPortfolioHelperText('');
  };

  const onChangeDrawerHandler = (e) => {
    const { name, value } = e.target;
    setLinkedinError(false);
    setLinkedinHelperText(false);
    // if (name === 'highest_educ') {
    //   if (duplicates['highest_educ'] === value) {
    //     _disablehighest_educ(true)
    //   } else {
    //     _disablehighest_educ(false)
    //   }
    // }
    SetDrawerData({ ...drawerData, [name]: value });
    if (name === 'linkedin') {
      if (value === duplicates['linkedin'] || (!value && !duplicates['linkedin'])) {
        _disablelinkedin(true);
      }
      else {
        _disablelinkedin(false)
      }
    }
    if (name === 'portfolio') {
      // if (duplicates['portfolio'] === "" || value === "") {
      //   return _disableportfolio(true);
      // }
      if (duplicates['portfolio'] === value || (!value && !duplicates['portfolio'])) {
        _disableportfolio(true)
      } else {
        _disableportfolio(false)
      }
    }
  }

  const handleHighestEducationChange = (e, child) => {
    const { name } = e.target;
    const { children, value } = child.props
    SetDrawerData({ ...drawerData, ["highest_educ"]: { highest_education: children, highest_education_id: value } })
    //const camelCaseValue = convertToCamelCase(value);
    if (name === 'highest_educ') {
      if (value === duplicates?.['highest_educ']?.highest_education_id) {
        _disablehighest_educ(true);
        console.log("true");
      }
      else {
        console.log("false");
        _disablehighest_educ(false);
      }
    }
  }


  const handleClose = () => {
    drawerOtherProps.setonclickdrawer(false)
  }

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleSubmit = () => {


    if (drawerData.linkedin && !validateLinkedInURL(drawerData.linkedin)) {
      setLinkedinError(true);
      setLinkedinHelperText("Invalid LinkedIn URL");
      return;
    }

    if (drawerData.portfolio && !validatePortfolioURL(drawerData.portfolio)) {
      setPortfolioError(true);
      setPortfolioHelperText("Invalid URL or URL length exceeds 128 characters");
      return;
    }

    if (!drawerData.skill || drawerData.skill.length < 5) {
      toast.error("Please select atleast 5 skills", { hideProgressBar: true, closeOnClick: true });
      return;
    }


    const payload = {
      token: document.cookie,
      profile: "other",
      highest_educ: drawerData.highest_educ ? drawerData.highest_educ?.highest_education_id : '',
      linkedin: drawerData.linkedin ? drawerData.linkedin : null,
      portfolio: drawerData.portfolio ? drawerData.portfolio : null,
      skill: drawerData.skill.map(lab => lab.label),
      original_date_modified: date_modified,
      // skill: drawerData.skill.sort((a, b) => a.label.localeCompare(b.label)).map(lab => lab.label)

    };
    // console.log(drawerData.skill.map(lab=>lab.label), "119")
    drawerOtherProps.setEffectDependency(true);
    axios.post(`user/details/update-details`, payload)
      .then((response) => {
        if (response.status === 201) {
          drawerOtherProps.setonclickdrawer(false)
          drawerOtherProps.setEffectDependency(false);
          // getData();
          toast.success("Details Saved Successfully", { hideProgressBar: true, closeOnClick: true });
        }
        else {
          drawerOtherProps.setonclickdrawer(false)
          toast.error("Please Try Again After Sometime", { hideProgressBar: true, closeOnClick: true });
        }
      }).catch((err) => {
        drawerOtherProps.setonclickdrawer(false)
      })
    localStorage.setItem("Lid", drawerData.linkedin ? drawerData.linkedin : "");

  }
  const isSmallScreen = useMediaQuery('(max-width:430px)');

  const [selectEducation, setSelectEducation] = useState([]);

  const educationList = async () => {
    try {
      const response = await axios.get(`/highest-education`, { headers: { authorization: document.cookie } });
      setSelectEducation(response.data.message);
    }
    catch (error) {
      console.log(error);
    }
  }
  React.useEffect(() => {
    educationList();
  }, [])

  const list = (anchor) => (
    <Box
      sx={{ width: isSmallScreen ? "100vw" : 425 }}
      role="presentation"
    >
      <List className='fx '>
        <form>
          <div className="basic-drawer-buttons">
            <div className="drawer-title">Other Details</div>
            <div className="app" style={{ cursor: 'pointer' }}><ClearIcon onClick={handleClose} /></div>

          </div>
          <div className="basic-drawer-divider"></div>
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "88vh" }}>
            <div>
              <div className="other-edit-mode-fields">
                <FormControl sx={{ width: '85%', marginTop: '24px' }}>
                  <InputLabel className='fw-bold' id="demo-simple-select-label">Highest Education</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name='highest_educ'
                    value={drawerData.highest_educ?.highest_education_id === 1 ? "" : drawerData.highest_educ?.highest_education_id}
                    label="Select from the list"
                    // onChange={(e) => onChangeDrawerHandler(e)}
                    onChange={(e, child) => handleHighestEducationChange(e, child)}
                    MenuProps={MenuProps}
                    className='fw-bold'
                  >
                    {
                      selectEducation.map(e => <MenuItem className='fw-bold' value={e.highest_education_id}>{e.highest_education}</MenuItem>)
                    }

                  </Select>
                </FormControl>
              </div>
              <div className="other-edit-mode-fields">
                <TextField
                  id="outlined-basic"
                  label="LinkedIn URL"
                  name='linkedin'
                  error={linkedinError}
                  helperText={linkedinHelperText ? linkedinHelperText : ''}
                  value={drawerData.linkedin}
                  onChange={(e) => onChangeDrawerHandler(e)}
                  inputProps={{
                    style: { fontWeight: 800 }
                  }}
                  variant="outlined"
                  style={{ width: "85%", marginTop: '25px' }}
                />
              </div>
              <div className="other-edit-mode-fields">
                <TextField
                  id="outlined-basic"
                  label="Portfolio Link"
                  name='portfolio'
                  value={drawerData.portfolio}
                  onChange={(e) => onChangeDrawerHandler(e)}
                  onFocus={handlePortfolioFieldFocus}
                  inputProps={{
                    style: { fontWeight: 800 }
                  }}
                  variant="outlined"
                  style={{ width: "85%", marginTop: '25px' }}
                  error={portfolioError}
                  helperText={portfolioHelperText}
                />
              </div>
              <div className='text-capitalize fw-bold p-4 w-85'>
                <Typography sx={{ fontSize: '18px', fontWeight: '600' }}>Skills</Typography>
                <OtherSkill setFlag={setFlag} SetDrawerData={SetDrawerData} setEdu={setEdu} drawerData={drawerData}
                  setIsOpen={setIsOpen} duplicate={duplicate}
                  disableskill={disableskill}
                  _disableskill={_disableskill}
                />
              </div>
            </div>

            <div>
              <div className="other-drawer-divider-2"></div>
              <div className="drawer-buttons">
                <Button sx={{ fontWeight: '600' }} onClick={handleClose}>CANCEL</Button>
                <Button variant="contained" onClick={handleSubmit} disabled={disablehighest_educ && disablelinkedin && disableportfolio && disableskill}>SAVE</Button>
              </div>
            </div>
          </div>
        </form>
      </List>
    </Box>
  );

  return (
    <div>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            disableSwipeToClose={true}
            // onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
            allowSwipeInChildren={true}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );

}
