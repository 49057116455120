import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Typography, useMediaQuery } from "@mui/material";
import { toast } from "react-toastify";
import axios from "axios";

const DeletePopup = ({
  handleCloseDeletePopup,
  deletePopupOpen,
  deleteNoPopupOpen,
  setPhoneNumber,
  deleteNoId,
  phoneNo,
  setPhoneNo,
  deleteEmail,
  contacts,
  setContacts,
  contactId,
  setEffectDependency,
}) => {
  //const [open, setOpen] = React.useState(false);

  // const handleClickOpen = () => {
  //   //setOpen(true);
  // };

  const handleDeleteNumber = async (id) => {
    await axios
      .delete(`user/delete-contact/${id}`, {
        headers: { authorization: document.cookie },
      })
      .then((response) => {
        if (response.data.status === 200) {
          toast.success("Number deleted successfully", {
            hideProgressBar: true,
            closeOnClick: true,
          });
          setEffectDependency((prev) => !prev);
          setPhoneNumber("");
        } else {
          toast.error(response.data.message, {
            hideProgressBar: true,
            closeOnClick: true,
          });
        }
      });
    //setContacts(contacts.filter((contact) => contact.contact_id !== id));
    setPhoneNo(phoneNo.filter((contact) => contact.contact_id !== id));
    handleCloseDeletePopup();
  };

  const handleDeleteEmail = async (id) => {
    //setEmail1("");
    // handleCloseDeletePopup();

    await axios
      .delete(`user/delete-contact/${id}`, {
        headers: { authorization: document.cookie },
      })
      .then((response) => {
        if (response.data.status === 200) {
          toast.success("Email deleted successfully", {
            hideProgressBar: true,
            closeOnClick: true,
          });
          setEffectDependency((prev) => !prev);
        } else {
          toast.error(response.data.message, {
            hideProgressBar: true,
            closeOnClick: true,
          });
        }
      });
    setContacts(contacts.filter((contact) => contact.contact_id !== id));
    handleCloseDeletePopup();
  };
  const handleClose = () => {
    handleCloseDeletePopup();
  };
  const isSmallScreen = useMediaQuery('(max-width: 625px)');
  return (
    <>
      {deletePopupOpen && (
        <Dialog
          open={deletePopupOpen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{
            "& .MuiPaper-root": {
              borderRadius: "16px",
              textAlign: "center",
              padding: "22px 15px",
            },
          }}
        >
          <DialogTitle
            id="alert-dialog-title"
            style={{ textAlign: "center", fontWeight: "bold" }}
          >
            <span style={{ color: "red" }}>Delete</span> Alternate Email
          </DialogTitle>
          <DialogContent>
            <Typography
              variant="subtitle1"
              className={`fw-bold text-muted ${!isSmallScreen ? "break-words overflow-hidden" : ""}`}
              gutterBottom
            >
              Are you sure, you want to delete{" "}
              <Typography variant="subtitle1" className=" break-words overflow-hidden fw-bold w-75 m-auto" title={deleteEmail} style={{ textOverflow: "ellipsis", color: "rgba(26, 70, 150, 1)" }}>
                {deleteEmail}
              </Typography>
            </Typography>
            <DialogContentText id="alert-dialog-description"></DialogContentText>
          </DialogContent>
          <DialogActions
            style={{
              justifyContent: "center",
            }}
          >
            <Button
              variant="outlined"
              onClick={handleClose}
              style={{ width: "150px", marginRight: "10px" }}
            >
              No
            </Button>

            <Button
              variant="contained"
              onClick={() => handleDeleteEmail(contactId)}
              style={{ width: "150px" }}
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {deleteNoPopupOpen && (
        <Dialog
          open={deleteNoPopupOpen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{
            "& .MuiPaper-root": {
              borderRadius: "16px",
              textAlign: "center",
              padding: "22px 15px",
            },
          }}
        >
          <DialogTitle
            id="alert-dialog-title"
            style={{ textAlign: "center", fontWeight: "bold" }}
          >
            <span style={{ color: "red" }}>Delete</span> Mobile Number
          </DialogTitle>
          <DialogContent>
            <Typography
              variant="subtitle1"
              className="fw-bold text-muted"
              gutterBottom
            >
              Are you sure, you want to delete ?
            </Typography>
            <DialogContentText id="alert-dialog-description"></DialogContentText>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <Button
              variant="outlined"
              onClick={handleClose}
              style={{ width: "150px", marginRight: "10px" }}
            >
              No
            </Button>

            <Button
              variant="contained"
              onClick={() => handleDeleteNumber(deleteNoId)}
              style={{ width: "150px" }}
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
export default DeletePopup;
