import { useNavigate } from "react-router-dom";
import Header from "./header";
import "./Skillspage.css";
import Selects from "./Select";
import { useState } from "react";
import axios from "axios";
import Backdrops from "./Backdrop";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { toast } from 'react-toastify';
import Fouter from './Fouter';
import FooterZ from "./FooterZ";
import Homeheader from "./homeheader";
import ResendBackdrop from "../ResendBackdrop";
import { Box, Button } from "@mui/material";
import { Helmet } from "react-helmet";


export function Skillpage() {
  // console.log("qqqqqqqqqqqqqqq");
  const nav = useNavigate();
  const [skillData, setSkillData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const handleBack = () => {
    nav('/upload-page');
  }
  const handleSave = () => {

    if (!skillData || skillData.length < 5) {
      toast.error("Please select atleast 5 skills", { hideProgressBar: true, closeOnClick: true });
      return
    }

    setIsOpen(true);
    if (!skillData) {
      setIsOpen(false);
      toast.error("Please select skills", { hideProgressBar: true, closeOnClick: true });
    }
    else {
      axios.post(`user-skills-update`, { skills: skillData, candidate_id: localStorage.getItem('id') }, { headers: { 'authorization': document.cookie } }).then((res) => {
        setIsOpen(false);
        toast.success("Skills updated successfully", { hideProgressBar: true, closeOnClick: true });
        window.location.href = '/dashboard';
      })
    }
  }
  // const handleSkip = () => {
  //   nav('/dashboard');
  // }

  return (
    <>
      <Helmet>
        <title>Skill Development – Enhance Your Job Search</title>
        <meta name="title" content='Skill Development – Enhance Your Job Search' />
        <meta name="description" content="Discover essential skills for job seekers on Jobeze. Enhance your job search with our resources and tips for skill improvement in today's job market." />
      </Helmet>
      <div className="container-fluid border-bottom p-3 text-center ">
        <img
          className="img-fluid"
          src="/Logo.svg"
          alt='Jobeze logo - Job search platform' title='Jobeze - AI-Powered Job Search Platform'
        />
      </div>
      {isOpen && <ResendBackdrop />}
      <div>
        <div className="upload-parent-div">
          <div className="fx">
            <div className="Uploadpage bg-white">
              <div className="section-upload rounded-3" style={{ border: "rgba(0, 0, 0, 0.16) 1px solid" }} >
                <p className="heading1-upload fw-bold">Skills</p>
                <div className="heading2-upload fw-bold text-muted ">Tell recruiters what you know or what you are known for</div>
                <div className="select-div text-capitalize" style={{ paddingTop: '15px' }}>
                  <Selects setData={setSkillData} />
                </div>
                {/* <div className="text-muted pt-1" style={{ fontSize: "12px" }}> Skills are separated by comma </div> */}
                <div className="pt-2">
                  <Button
                    variant="contained"
                    fullWidth
                    className="fw-bold"
                    sx={{ letterSpacing: '2px' }}
                    onClick={handleSave}
                  >
                    Next
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="phscreen">
          <div className="phone-skill-title">Skills</div>
          <div className="phone-skill-sub-heading">Tell recruiters what you’re known for</div>
          <div className="select-div text-capitalize phone-skills-chip" style={{ paddingTop: '15px' }}>
            <Selects setData={setSkillData} />
          </div>
          <div className="skills-button-skills">
            <button className="button-back-skills" type="button" onClick={handleBack}>
              Back
            </button>
            <button className="button-skip-skills" type="button" onClick={handleSave}>
              Save
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
