import React, { useState } from 'react'
import Homeheader from './homeheader'
import { Button, TextField } from '@mui/material'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import Backdrops from './Backdrop';
import { Helmet } from 'react-helmet';



const ForgetPassword = () => {
  const [email, setEmail] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  const nav = useNavigate();
  const handlegetData = (event) => {
    setEmail(event.target.value);
  }
  const handleSendData = () => {
    if (!regex.test(email)) {
      setIsOpen(false);
      toast.error("Please Enter Valid Email ID", { hideProgressBar: true, closeOnClick: true });
    }
    else {
      setIsOpen(true);
      axios.post(`user/verification/email`, { email: email }).then((response) => {
        if (response.status === 200) {
          setIsOpen(false);
          toast.success("Please Check Your Mail", { hideProgressBar: true, closeOnClick: true });
          localStorage.clear();
          const d = new Date();
          let expires = "expires=" + d.toUTCString();
          document.cookie = "_secure_ARJ_" + "=" + "" + ";" + expires + ";path=/";
          document.cookie = "_secure_ref" + "=" + "" + ";" + expires + ";path=/";
          document.cookie = "cookiesAccepted" + "=" + "" + ";" + expires + ";path=/";
          nav('/login');
        }
        else {
          setIsOpen(false);
          toast.error("Please Try Again!", { hideProgressBar: true, closeOnClick: true });
        }
      }).catch((err) => {
        setIsOpen(false);
        toast.error("Please enter a registered email !", { hideProgressBar: true, closeOnClick: true });
      })
    }
  }

  const onEnterPress = (e) => {
    if (e.keyCode == 13 && e.shiftKey == false) {
      handleSendData();
    }
  }

  return (
    <>
      <Helmet>
        <title>Reset Your Jobeze Password Securely</title>
        <meta name="title" content='Reset Your Jobeze Password Securely' />
        <meta name="description" content="Forgot your password? Reset it easily and securely here. Regain access to your Jobeze account and continue your job search journey" />
      </Helmet>
      <div>
        {isOpen && <Backdrops />}
        <Homeheader />
        <div className="d-flex justify-content-center fx align-items-center" style={{ height: "85vh" }} >
          <div className='p-5' style={{ boxShadow: "0px 1px 16px rgba(0, 0, 0, 0.16)" }}>
            <h2 className='fw-bold'>Forgot Password</h2>
            <div className="py-2">
              <h6 className='fw-bold'>Enter Your Registered Email Id</h6>
            </div>
            <div className="row m-auto gap-3">
              <TextField onKeyDown={onEnterPress} id="outlined-basic" label="Email ID" variant="outlined" onChange={handlegetData} />

              <Button className='fw-bold' variant="contained" sx={{ height: '50px', fontSize: '15px' }} onClick={handleSendData}>Send Reset Link</Button>
            </div>
          </div>
        </div>
      </div></>
  )
}

export default ForgetPassword