import React from 'react';
import { useNavigate } from 'react-router-dom';

const Errorpage = () => {
    const navigate = useNavigate();
    const handleShow = () => {
        document.cookie.split('_secure_ARJ_')[1] ? navigate('/dashboard') : navigate('/');
    }
    return (
        <div className='container-fluid m-0 p-0'>
            <div className="">
                <div style={{ height: "70vh" }} className='text-center d-flex justify-content-center align-items-center'>
                    <div className=''>
                        <div className='d-flex justify-content-center align-items-center'>
                            <img className='img-fluid' src="/Layer.svg" alt='Job search layers and filters' title='Filter Job Listings Easily on Jobeze' />
                        </div>
                        <div className='py-3'>
                            <div className='col-12 fs-3'>No result found</div>
                            <div style={{ color: "#00000099" }} className='col-12  '>
                                <p>Come back later as recruiters are always adding new jobs.</p>
                            </div>
                        </div>
                        <div className='col-12'>
                            <button className='btn btn-primary px-3 text-uppercase' style={{ backgroundColor: "#006CB7" }} type='button' onClick={handleShow}>Back to home</button>
                        </div>
                        <div></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Errorpage