import * as React from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import {
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ClearIcon from "@mui/icons-material/Clear";
import axios from "axios";
import dayjs, { Dayjs } from "dayjs";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import { toast } from "react-toastify";
import { useState } from "react";
import FormLabel from "@mui/material/FormLabel";
import moment from "moment";
import PlusOneIcon from "@mui/icons-material/PlusOne";
import { camelCase, set } from "lodash";

export default function Basicdrawer({
  drawerBasicProps,
  duplicate,
  date_modified,
}) {
  const [state, setState] = React.useState({ right: true });
  const [drawerData, SetDrawerData] = React.useState(
    drawerBasicProps.basicDrawerField
  );
  const [mobileNumber, setMobileNumber] = React.useState([]);
  const [mobileValid, setMobileValid] = React.useState(false);
  const [mobileError, setMobileError] = React.useState(false);
  const [codeError, setCodeError] = React.useState(false);
  const [countycodeerror, setcountycodeerror] = React.useState(false);
  const [validMobile, setValidMobile] = React.useState(false);
  const [mobileFocused, setMobileFocused] = React.useState(false);
  const [isFormDirty, setIsFormDirty] = useState(true);
  const [finalchangename, setFinalChangeName] = useState("");
  const [selectWorkAuthorisation, setSelectWorkAuthorisation] = useState([]);
  // const [dobError, setDobError] = React.useState(false);

  const [disableFirstName, _disableFirst] = useState(true);
  const [disableAddress, _disableAddress] = useState(true);
  const [disableGender, _disableGender] = useState(true);
  const [disableDob, _disableDob] = useState(true);
  const [disableMobile, _disableMobile] = useState(true);
  const [disableCode, _disableCode] = useState(true);
  const [isOtherSelected, setIsOtherSelected] = useState(false);
  const [disableWorkAuth, _disableWorkAuth] = useState(true);
  const [customworkAuthorization, setCustomWorkAuthorization] = useState("");
  const duplicates = {
    fullname: duplicate?.details?.name,
    countryCode: duplicate?.details?.countryCode,
    address: duplicate?.details?.address,
    mobile: duplicate?.details?.mobile,
    dob: duplicate?.details?.dob,
    gender: duplicate?.details?.gender,
  };

  const [error, setError] = useState({
    fullname: false,
    mobile: false,
    countryCode: false,
    other_work_authorization: false,
  });

  const regexName = /^[a-zA-Z\s]+$/;

  React.useEffect(() => {
    axios.get(`countries`).then((response) => {
      setMobileNumber(response.data.message.map((e) => e["Dialing Code"]));
    });
  }, []);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 150,
      },
    },
  };

  function convertToCamelCase(input) {
    if (
      input === null ||
      input?.length === 0 ||
      input === undefined ||
      input === ``
    ) {
      return input;
    }
    const words = input?.split(" ");
    const camelCaseWords = words.map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });

    return camelCaseWords.join(" ");
    // return camelCase(input);
  }
  // console.log("drawerData", drawerData);
  const onChangeDrawerHandler = (e) => {
    const { name, value } = e.target;
    // SetDrawerData({ ...drawerData, [name]: value });
    const camelCaseValue = convertToCamelCase(value);
    // console.log("address", duplicates['address']);
    // if (name === 'address') {
    //   if (camelCaseValue === convertToCamelCase(duplicates['address'])) {
    //     _disableAddress(true);
    //   }
    //   else if( camelCaseValue === '' && convertToCamelCase(duplicates['address']) === null) {
    //     _disableAddress(true);
    //   }
    //   else {
    //     _disableAddress(false);
    //   }
    // }
    if (Object.keys(duplicates).includes(name)) {
      if (camelCaseValue === "") {
        SetDrawerData({ ...drawerData, [name]: camelCaseValue });
      } else if (regexName.test(value)) {
        // console.log("Valid input:", camelCaseValue);
        SetDrawerData({ ...drawerData, [name]: camelCaseValue });
      } else {
        // console.log("Invalid input:", value);
        _disableAddress(true);
        // if (camelCaseValue === convertToCamelCase(duplicates['fullname'])) {
        //   _disableFirst(true);
        //   return;
        // }
        return;
      }
      setError({ ...error, fullname: false });
    }

    if (name === "fullname") {
      if (camelCaseValue === convertToCamelCase(duplicates["fullname"])) {
        _disableFirst(true);
      } else {
        _disableFirst(false);
      }
    }
    if (name === "gender") {
      if (camelCaseValue === convertToCamelCase(duplicates["gender"])) {
        // console.log("name", name, camelCaseValue, convertToCamelCase(duplicates['gender']))
        _disableGender(true);
      } else {
        // console.log("name dd")
        _disableGender(false);
      }
    }
    // if (name === 'address') {
    //   if (camelCaseValue === '') {
    //     _disableAddress(true);
    //   }
    //   else if (camelCaseValue === convertToCamelCase(duplicates['address'])) {
    //     _disableAddress(true);
    //   }
    //   else {
    //     _disableAddress(false);
    //   }
    // }
  };

  const handleAddress = (e) => {
    const { name, value } = e.target;
    SetDrawerData({ ...drawerData, [name]: value });
    if (name === "address") {
      if (value === duplicates["address"]) {
        _disableAddress(true);
      } else if (value === "" && duplicates["address"] === null) {
        _disableAddress(true);
      } else {
        _disableAddress(false);
      }
    }
  };

  const handleWorkAuthorisationChange = (e, child) => {
    const { name } = e.target;
    const { children, value } = child.props;
    if (children === "Others") {
      setIsOtherSelected(true);
    }
    else {
      setIsOtherSelected(false);
      setCustomWorkAuthorization("");
    }
    SetDrawerData({
      ...drawerData,
      ["work_authorization"]: {
        work_authorization: children,
        work_authorization_id: value,
      },
    });
    //const camelCaseValue = convertToCamelCase(value);
    if (name === "work_authorization") {
      if (value === duplicates["work_authorization"]?.work_authorization_id) {
        _disableWorkAuth(true);
      } else {
        _disableWorkAuth(false);
      }
    }
  };
  const handleCustomWorkAuthorizationChange = (event) => {
    setCustomWorkAuthorization(event.target.value);
    if (event.target.value) {
      setError({ ...error, other_work_authorization: false });
    }

  };
  // const handleMobileChange = (e) => {
  //   const { name, value } = e.target;
  //   SetDrawerData({ ...drawerData, [name]: value });
  //   setError({ ...error, mobile: false });
  //   setMobileError(false);
  //   setValidMobile(false);

  //   if (value === "") {
  //     // Empty mobile number is allowed
  //     setValidMobile(true);
  //   } else if (!isNaN(value) && value.length === 7) {
  //     // Validate if only numbers are inputted and length is 7
  //     setValidMobile(true);
  //   } else {
  //     setMobileError(true);
  //   }
  // };

  const handleMobileChange = (e) => {
    const { name, value } = e.target;
    const truncatedMobile = value.replace(/\D/g, "").slice(0, 10); // Keep only the first 7 digits
    const camelCaseValue = convertToCamelCase(value);
    if (Object.keys(duplicates).includes(name)) {
      SetDrawerData({ ...drawerData, [name]: truncatedMobile });
      setError({ ...error, mobile: false });
      setMobileError(false);
      setValidMobile(false);

      if (truncatedMobile === "") {
        // Empty mobile number is allowed
        setValidMobile(true);
      } else if (!isNaN(truncatedMobile) && truncatedMobile.length === 10) {
        // Validate if only numbers are inputted and length is 7
        setValidMobile(true);
      } else {
        setMobileError(true);
      }
    }
    // console.log("camelCaseValue",camelCaseValue, convertToCamelCase(duplicates['mobile']))

    if (convertToCamelCase(duplicates["mobile"]) === null) {
      let len = camelCaseValue.length;
      if (len === 0) {
        _disableMobile(true);
      } else if (len > 0) {
        _disableMobile(false);
      }
    } else {
      if (camelCaseValue === convertToCamelCase(duplicates["mobile"])) {
        _disableMobile(true);
      } else {
        _disableMobile(false);
      }
    }
  };

  const handleMobileFocus = () => {
    setMobileFocused(true);
  };

  const handleMobileBlur = () => {
    setMobileFocused(false);
  };

  // const handleCountryCodeChange = (e) => {
  //   const { name, value } = e.target;
  //   SetDrawerData({ ...drawerData, [name]: value });
  //   setError({ ...error, countryCode: false });
  //   setCodeError(false);
  // };

  const handleCountryCodeChange = (e) => {
    const { name, value } = e.target;
    const countryCode = value.replace(/\D/g, ""); // Remove non-numeric characters
    const truncatedCountryCode = countryCode.slice(0, 3); // Keep only the first 3 digits
    const camelCaseValue = convertToCamelCase(value);
    if (Object.keys(duplicates).includes(name)) {
      SetDrawerData({ ...drawerData, [name]: truncatedCountryCode });
      setError({ ...error, countryCode: false });
      setCodeError(false);
      setcountycodeerror(false);
    }

    const compVal = duplicates["countryCode"]
      ? convertToCamelCase(duplicates["countryCode"])
      : null;
    if (compVal === null) {
      let len = camelCaseValue.length;
      if (len === 0) {
        _disableCode(true);
      } else if (len > 0) {
        _disableCode(false);
      }
    } else {
      if (camelCaseValue === compVal) {
        _disableCode(true);
      } else {
        _disableCode(false);
      }
    }
  };

  const handleClose = () => {
    drawerBasicProps.setonclickdrawer(false);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  // console.log("drawerData.mobile", drawerData.mobile.split('4')[0]);
  // console.log("drawerData.mobile", drawerData.mobile.split('4')[1]);
  const handleDateChange = (e) => {
    let date = "";
    if (e) {
      let m = e.$d.getMonth() + 1;
      m = m < 10 ? `0${m}` : m;
      let d = ("0" + e.$D).slice(-2);
      let date = e.$d.getFullYear() + "-" + m + "-" + d;
      // console.log(date,"dobbb");
      SetDrawerData({ ...drawerData, dob: date });
    }
    if (date === duplicates["dob"]?.split("T")[0]) {
      // console.log("Himanshu**********************", date,duplicates['dob'].split('T')[0]);
      _disableDob(true);
    } else {
      _disableDob(false);
    }
  };

  // console.log(drawerData.dob,"drawerdate");

  const handleSubmit = () => {
    if (!drawerData.fullname) {
      setError({ ...error, fullname: true });
      return;
    }
    if (!customworkAuthorization && isOtherSelected) {
      setError({ ...error, other_work_authorization: true });
      return;
    }
    // if (drawerData.countryCode && !drawerData.mobile) {
    //   setMobileError(true);
    //   return;
    // }

    // if (drawerData.countryCode && drawerData.countryCode.length !== 3) {
    //   setcountycodeerror(true);
    //   return;
    // }

    if (
      drawerData.mobile &&
      (isNaN(drawerData.mobile) || drawerData.mobile.length !== 10)
    ) {
      setMobileError(true);
      return;
    }

    // if(drawerData?.mobile?.length === 10){
    //   setCodeError(true);
    //   return;
    // }
    else {
      const payload = {
        token: document.cookie,
        profile: "basic",
        mobile: drawerData.mobile ? drawerData.mobile : "",
        fullname: drawerData.fullname ? drawerData.fullname.trim() : "",
        email: drawerData.email ? drawerData.email : "",
        address: drawerData.address
          ? drawerData.address.charAt(0).toUpperCase() +
          drawerData.address.slice(1)
          : null,
        gender: drawerData.gender
          ? drawerData.gender.charAt(0).toUpperCase()
          : "",
        dob: drawerData.dob ? drawerData.dob : "",
        countryCode: drawerData.countryCode
          ? "+1 " + drawerData.countryCode
          : "",
        original_date_modified: date_modified,
        work_authorization: drawerData.work_authorization
          ? drawerData.work_authorization?.work_authorization_id
          : "",
        work_authorization_text: customworkAuthorization ? customworkAuthorization : "",
      };
      drawerBasicProps.setEffectDependency(true);
      axios
        .post(`user/details/update-details`, payload)
        .then((response) => {
          if (response.status === 201) {
            drawerBasicProps.setonclickdrawer(false);
            drawerBasicProps.setEffectDependency(false);
            toast.success("Details Saved Successfully", {
              hideProgressBar: true,
              closeOnClick: true,
            });
          } else {
            drawerBasicProps.setonclickdrawer(false);
            toast.error("Please try after sometimes", {
              hideProgressBar: true,
              closeOnClick: true,
            });
          }
        })
        .catch((err) => {
          drawerBasicProps.setonclickdrawer(false);
        });
    }
  };

  const isSmallScreen = useMediaQuery("(max-width:430px)");

  const workAuthorityList = async () => {
    try {
      const response = await axios.get(`/work-authorization`, {
        headers: { authorization: document.cookie },
      });
      setSelectWorkAuthorisation(response.data.message);
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    workAuthorityList();
    let ifOthertrue = drawerBasicProps?.basicDrawerField?.work_authorization?.work_authorization
    let other_work_authorization = drawerBasicProps?.basicDrawerField?.work_authorization_text;
    console.log(ifOthertrue, other_work_authorization)
    if (ifOthertrue === "Others") {
      setIsOtherSelected(true);
      setCustomWorkAuthorization(other_work_authorization);
      _disableWorkAuth(false);
    }
    else {
      setIsOtherSelected(false);
      setCustomWorkAuthorization("");
    }
  }, []);



  // console.log("ifOthertrue", ifOthertrue);
  // console.log(drawerBasicProps?.basicDrawerField?.work_authorization_text);
  // console.log(customworkAuthorization, "customworkAuthorization");
  const list = (anchor) => (
    <Box sx={{ width: isSmallScreen ? "100vw" : 425 }} role="presentation">
      <List className="fx ">
        <form>
          <div className="basic-drawer-buttons">
            <div className="drawer-title">Basic Details</div>
            <div className="app" style={{ cursor: "pointer" }}>
              <ClearIcon onClick={handleClose} />
            </div>
          </div>

          <div className="basic-drawer-divider"></div>
          <div className="basic-fields-handle">
            <div>
              <div className="drawer-field-1">
                <TextField
                  label="Full Name"
                  variant="outlined"
                  name="fullname"
                  value={drawerData.fullname}
                  style={{ width: "85%", marginTop: "20px" }}
                  // onChange={(e) => onChangeDrawerHandler(e)}
                  onChange={(e) => {
                    if (e.target.value.length <= 35) {
                      onChangeDrawerHandler(e);
                    }
                  }}
                  inputProps={{ style: { fontWeight: 600 } }}
                  error={error.fullname ? error.fullname : ""}
                  helperText={error.fullname ? "Please Enter Your Name" : ""}
                  required
                />
              </div>
              <Box
                sx={{
                  minWidth: 120,
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <FormControl sx={{ width: "85%" }}>
                  <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="gender"
                    value={drawerData.gender}
                    label="Gender"
                    onChange={(e) => onChangeDrawerHandler(e)}
                    style={{ fontWeight: 600 }}
                  >
                    <MenuItem value={"M"}>Male</MenuItem>
                    <MenuItem value={"F"}>Female</MenuItem>
                    <MenuItem value={"O"}>Prefer not to respond</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "85%",
                  margin: "12px auto 0px auto ",
                }}
              >
                <Grid sx={{ width: "100%" }} item>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      // className="basic-dob"
                      sx={{ width: "100%" }}
                      label="Date of Birth"
                      value={drawerData.dob ? dayjs(drawerData.dob) : null}
                      format="MM/DD/YYYY"
                      onChange={handleDateChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                        // Set the input field as read-only
                        />
                      )}
                      slotProps={{
                        textField: {
                          readOnly: true,
                        },
                      }}
                      disableFuture={true}
                      maxDate={dayjs(moment().subtract("years", 18))}
                      minDate={dayjs(moment().subtract("years", 70))}
                    />
                  </LocalizationProvider>
                </Grid>
              </Box>
              {/* <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <TextField
                  id="outlined-basic"
                  label="Email"
                  disabled
                  value={drawerData.email}
                  variant="outlined"
                  style={{ width: "85%" }}
                  inputProps={{
                    style: { fontWeight: 800 },
                  }}
                />
              </Box> */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "20px",
                  marginLeft: "2rem",
                  marginRight: "2rem",
                }}
              >
                <Box style={{ display: "flex", flexDirection: "column" }}>
                  {/* <FormControl error={codeError} >
                          <TextField

                         InputProps={{
                           startAdornment: (
                           <InputAdornment position="start" >
                        <PlusOneIcon />
                           </InputAdornment>
                              ),
                            }}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Country Code"
                            sx={{ width: "120px"  }}
                            placeholder="Code"
                            // MenuProps={MenuProps}
                            name="countryCode"
                            value={drawerData.countryCode}
                        
                            onChange={(e) => handleCountryCodeChange(e)}
                          >
                          </TextField>
                         
                        </FormControl> */}
                  {/* {codeError && <FormHelperText sx={{color:'red'}}>Please Enter Code</FormHelperText>}
                        {countycodeerror && <FormHelperText sx={{color:'red'}}>Please Enter valid code</FormHelperText>} */}
                  {/* <FormLabel
                          style={{
                            display: error.countryCode ? "" : "none"
                          }}
                          sx={{ color: "red", fontSize: "11px", marginTop: "10px" }}
                        >
                          (Please Select Code)
                        </FormLabel> */}
                </Box>
                {/* <TextField
                  id="outlined-basic"
                  label="Mobile Number"
                  fullWidth
                  type="number"
                  placeholder="Mobile Number"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">+1</InputAdornment>,
                  }}
                  inputProps={{
                    onKeyPress: (e) => {
                      const charCode = e.which ? e.which : e.keyCode;
                      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                        e.preventDefault();
                      }
                    }
                  }}
                  name="mobile"
                  value={drawerData.mobile}
                  // disabled={drawerData.countryCode.length !== 3 ? true : false}
                  onChange={(e) => handleMobileChange(e)}
                  onFocus={handleMobileFocus}
                  onBlur={handleMobileBlur}
                  variant="outlined"
                  error={mobileError && !mobileFocused}
                  helperText={mobileError && !mobileFocused ? "It should contain exactly 10 digits" : ""}
                /> */}
              </Box>
              <Box
                sx={{
                  minWidth: 120,
                  display: "flex",
                  justifyContent: "center",
                  // marginTop: "10px",
                }}
              >
                <FormControl sx={{ width: "85%" }}>
                  <InputLabel id="demo-simple-select-label">
                    Work Authorization
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="work_authorization"
                    value={
                      drawerData.work_authorization?.work_authorization_id === 1
                        ? ""
                        : drawerData.work_authorization?.work_authorization_id
                    }
                    label="Work Authorization"
                    placeholder="Work Authorization"
                    // onChange={(e) => onChangeDrawerHandler(e)}
                    onChange={(e, child) =>
                      handleWorkAuthorisationChange(e, child)
                    }
                    style={{ fontWeight: 600 }}
                  >
                    {selectWorkAuthorisation.map((e) => (
                      <MenuItem
                        value={e.work_authorization_id}
                        key={e.work_authorization_id}
                      >
                        {e.work_authorization}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box
                sx={{
                  minWidth: 120,
                  display: "flex",
                  justifyContent: "center",
                  // marginTop: "10px",
                }}
              >
                <FormControl sx={{ width: "85%" }}>
                  {(isOtherSelected) && (
                    <TextField
                      size="small"
                      fullWidth
                      label="Other Work Authorization"
                      value={customworkAuthorization}
                      onChange={handleCustomWorkAuthorizationChange}
                      margin="normal"
                      error={error.other_work_authorization}
                      helperText={error.other_work_authorization ? "This field is required" : ""}
                    />
                  )}
                </FormControl>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <TextField
                  rows={4}
                  multiline
                  id="outlined-basic"
                  label="Current Address"
                  variant="outlined"
                  name="address"
                  placeholder="Current Address"
                  type="text"
                  value={drawerData.address}
                  // onChange={(e) => onChangeDrawerHandler(e)}
                  onChange={(e) => handleAddress(e)}
                  inputProps={{
                    maxLength: 150,
                    style: { fontWeight: 800 },
                  }}
                  style={{ width: "85%" }}
                />
              </Box>
            </div>

            <div>
              <div className="basic-drawer-divider-1"></div>
              <div className="drawer-buttons">
                <Button sx={{ fontWeight: "600" }} onClick={handleClose}>
                  CANCEL
                </Button>
                <Button
                  variant="contained"
                  onClick={handleSubmit}
                  disabled={
                    disableFirstName &&
                    disableAddress &&
                    disableGender &&
                    disableCode &&
                    disableDob &&
                    disableWorkAuth
                  }
                >
                  SAVE
                </Button>
              </div>
            </div>
          </div>
        </form>
      </List>
    </Box>
  );

  return (
    <div>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            disableSwipeToClose={true}
            // onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
            allowSwipeInChildren={true}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}
