import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Card from "./card";
import axios from "axios";
import Pagination from "@mui/material/Pagination";
import moment from "moment/moment.js";
import Errorpage from "./Error404";
import FooterZ from "./FooterZ";
import { toast } from "react-toastify";
import Homeheader from "./homeheader";
import Shareable from "./Shareable";
import ShareIcon from '@mui/icons-material/Share';
import { useSearchParams } from "react-router-dom";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import ScheduleOutlinedIcon from "@mui/icons-material/ScheduleOutlined";
import { Box, Chip, Stack, useMediaQuery } from "@mui/material";
import Html from "../js/Html";
import NorthEastIcon from "@mui/icons-material/NorthEast";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { Helmet } from "react-helmet";



const Viewjobs = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const nav = useNavigate();
  const locations = useLocation();
  const [data, setdata] = useState("");
  const [border, setBorder] = useState(false);
  const [tempId, setTempId] = useState();
  const [getJobId, setJobID] = useState("");
  const [city, setCity] = useState();
  const [state, setState] = useState();
  const [companyname, setCompanyName] = useState("");
  const [getDate, setgetDate] = useState("");
  const [getAppliedData, setAppliedData] = useState(true);
  const [imageData, setImageData] = useState("");
  const [description, setDescription] = useState();
  const [title, setTitle] = useState();
  const [open, setOpen] = useState(false);
  const [shareurl, setShareurl] = useState('');

  const [isEmployerApplied, setIsEmployerApplied] = useState(0);


  const [skill, setSkill] = useState(
    searchParams.get("search") ? searchParams.get("search") : ""
  );
  const [location, setLocation] = useState(
    searchParams.get("location") ? searchParams.get("location") : "World Wide"
  );
  const [errorData, setErrorData] = useState(false);

  useEffect(() => {
    axios
      .post(`user/job/job-id/detail`, {
        token: document.cookie,
        job_id: locations?.state?.job_id,
      })
      .then((response) => {
        const list = response.data.message.filter((item) => item.candidate_status !== 2900);
        setdata(list);
        setJobID(response?.data?.message[0]);
        setTempId(response?.data?.message[0]?.joborder_id);
        setImageData(response?.data?.message[0]?.logo);
        setTitle(response?.data?.message[0]?.title);
        setCompanyName(response?.data?.message[0]?.name);
        setCity(response?.data?.message[0]?.city);
        setState(response?.data?.message[0]?.state);
        setDescription({ __html: response?.data?.message[0]?.description });
        setgetDate(response?.data?.message[0]?.applied_date);
        setIsEmployerApplied(response?.data?.message[0]?.isEmployerApplied);
      })
      .catch((err) => {
        console.log("error", err);
      });
  }, []);
  useEffect(() => {
    setTimeout(() => {
      setErrorData(true);
    }, 1500);
  }, []);

  const isSmallScreen = useMediaQuery('(max-width: 625px)');
  const isMediumScreen = useMediaQuery('(max-width: 1200px)');

  const onJobClickForSmallScreen = (jobId) => {
    nav(`/jobDetail/${jobId}`);
  };

  const setonClick = (jobId) => {
    setJobID(jobId);
    setTempId(jobId);
    setBorder(true);
    let desc = data.filter((item) => item.joborder_id === jobId);
    setCity(desc[0]?.city);
    setState(desc[0]?.state);
    if (isSmallScreen) {
      onJobClickForSmallScreen(jobId.joborder_id);
    }


    // setImageData(desc[0].logo);
    // setDescription({ __html: desc[0].description });
    // setTitle(desc[0].title);
  };

  const handleViewFullpage = (e) => {
    const shareUrl = process.env.REACT_APP_FRONTEND_URL;
    const url = `${shareUrl}/jobDetail/${getJobId.joborder_id}`;
    navigator.clipboard.writeText(url);
    toast.success("Link is Copies Successfully", {
      hideProgressBar: true,
      closeOnClick: true,
    });

    setOpen(false);
    setShareurl(url);
    // setAnchorEl(e.currentTarget);
    // window.open(url, "_blank");
  };
  function SharableUrl() {
    const shareUrl = process.env.REACT_APP_FRONTEND_URL;
    const url = `${shareUrl}/jobDetail/${getJobId.joborder_id}`;
    setShareurl(url);
  }
  const handleSearch = () => {
    setSearchParams({ search: skill, location: location });
    nav(`/jobsearch/allsearchdata?search=${skill}&location=${location}`);
  };
  const beforeDate = new Date(getJobId.date_created);
  const today = new Date();
  const diffTime = Math.abs(today - beforeDate);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  // var postedMessages = "";
  // if (diffDays === 1) {
  //   postedMessages = "Posted 1 day ago";
  // }
  // else if (diffDays > 1 && diffDays <= 30) {
  //   postedMessages = "Posted " + diffDays + " days ago";
  // }
  // else if (diffDays > 30) {
  //   postedMessages = "Re-posted " + (diffDays % 30) + (diffDays % 30 === 1 ? " day ago" : " days ago");
  // }
  // else {
  //   postedMessages = "Posted " + diffDays + " days ago";
  // }

  var postedMessages = "";
  if (diffDays === 1) {
    postedMessages = "Posted 1 day ago";
  } else if (diffDays === 0) {
    postedMessages = "Posted today";
  } else if (diffDays > 1 && diffDays <= 30) {
    postedMessages = "Posted " + diffDays + " days ago";
  } else if (diffDays > 30) {
    if (diffDays % 30 === 0) {
      postedMessages = "Posted today";
    } else {
      postedMessages =
        "Re-posted " +
        (diffDays % 30) +
        (diffDays % 30 === 1 ? " day ago" : " days ago");
    }
  } else {
    postedMessages = "Posted " + diffDays + " days ago";
  }


  switch (getJobId.type) {
    case "C":
      getJobId.text = "Contract";
      break;
    case "H":
      getJobId.text = "Hire";
      break;
    case "C2H":
      getJobId.text = "Contract to Hire";
      break;
    case "F":
      getJobId.text = "Freelance";
      break;
    default:
      break;
  }


  return (
    <>
      <Helmet>
        <title>View Jobs – Explore Job Openings on Jobeze</title>
        <meta name="title" content='View Jobs – Explore Job Openings on Jobeze' />
        <meta name="description" content="Discover 1000s of job openings on Jobeze. Use our AI job assistant to find and apply for jobs that match your skills and interests easily." />
      </Helmet>
      <div>
        <Homeheader
          skill={skill}
          setSkill={setSkill}
          location={location}
          setLocation={setLocation}
          handleSearch={handleSearch}
        />
        <div className="status-profile-viewjobs1">
          <Box p={2} sx={{ backgroundColor: "white" }}>
            <Box className="container" display={'flex'} alignItems={'center'}>
              <ArrowBackIosIcon style={{ cursor: "pointer" }} onClick={() => nav("/applied-jobs")} fontSize="14px" />
              <div className="fw-bold fs-5 px-2" style={{ cursor: "pointer" }} onClick={() => nav("/applied-jobs")} >Applied Jobs</div>
            </Box>
          </Box>
          <div className="container py-2 pt-3">
            {data.length > 0 ? (
              <div className="d-flex gap-2"  >
                <div
                  className="col-5 pe-3 scrollbar jbscroll"
                  id="div1"
                  style={{
                    overflow: "scroll",
                    overflowX: "hidden",
                  }}
                >
                  {data.length > 0 &&
                    data.length &&
                    data?.map((item, index) => {
                      return (
                        <Card
                          data={item}
                          key={index}
                          selected={{ joborder_id: getJobId.joborder_id }}
                          handleJobCardClick={setonClick}
                          border={border}
                          tempId={tempId}
                        />
                      );
                    })}
                  {data.length > 0 ? (
                    <Pagination
                      defaultPage={1}
                      size="medium"
                      count={1}
                      color="primary"
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        paddingBottom: "1%",
                      }}
                    />
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-7 " id="div2">
                  <div
                    className="vl bg-white"
                    style={{ height: "80vh", overflow: "hidden" }}
                  >
                    <div className="col-12 pt-4 fx" style={{ border: "none" }}>
                      <div className="row row-cols-3 px-4 pb-4 p-0">
                        <div
                          className="col-xl-3 col-lg-2 col-md-2 m-auto"
                          style={{ width: isMediumScreen ? "5em" : "6em" }}
                        >
                          <img
                            src={getJobId.logo}
                            style={{ width: isMediumScreen ? "3em" : "5rem" }}
                            className="img-fluid"
                            alt="Logo"
                          />
                        </div>
                        <div className="col-lg-6 col-md-4 card-body fw-bold m-0 m-auto px-3 p-0 fx">
                          <div
                            className="card-title fw-bold"
                            style={{ color: "#2D65BC", textTransform: "capitalize" }}
                          >
                            {getJobId.title}
                          </div>
                          <div className="card-subtitle fw-bold" style={{ textTransform: "capitalize" }} >
                            {getJobId.name}
                          </div>
                        </div>
                        <div className="">
                          <div className="text-lg-end pe-3 pb-2"
                            style={{ display: "flex", justifyContent: "end" }}>
                            {/* <a
                            className="fw-bold viewfullpagehover"
                            style={{ fontSize: "12px", cursor: "pointer", color: "unset" }}
                            target="_blank"
                            rel="noreferrer"
                            href={`/jobDetail/${getJobId.joborder_id}`}
                          >
                            <NorthEastIcon
                              fontSize=""
                              sx={{ mx: 0.1 }}
                            />
                            View full page
                          </a> */}

                            { }

                            <Box className="box-container">
                              <div className="share-text" style={{ fontSize: isMediumScreen ? "11px" : "14px", width: isMediumScreen ? "50px" : "93px" }}>Share this Job</div>
                              <ShareIcon className="share-icon" sx={{ width: isMediumScreen ? "20px" : "1em" }} onClick={() => { setOpen(true); SharableUrl() }} />
                            </Box>
                            {/* </a> */}

                            <Shareable isOpen={open} setOpen={setOpen} url={shareurl} handleViewFullpage={handleViewFullpage} mobileview={false} />

                            { }
                          </div>
                          <div className="text-lg-end">
                            <span
                              style={{
                                fontSize: isMediumScreen ? "12px" : "15px"
                              }}
                            >
                              <img
                                className="img-fluid"
                                src="/rightick.png"
                                alt="Confirm job applications with Jobeze"
                                title="Confirm Job Applications Easily on Jobeze"
                                style={{
                                  height: "15px",
                                  width: "15px",
                                }}
                              />
                              Applied on{" "}
                              {moment(getJobId.applied_date).format("MM/DD/YYYY")}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="px-4">
                      <div className="m-0 fw-bold" style={{ fontSize: "13.5px" }}>
                        <div
                          className="rounded-3 fx p-3 mb-2"
                          style={{
                            background: "#E6EFF7",
                            boxSizing: "border-box",
                          }}
                        >
                          <div className="h5 fw-bold">Highlights</div>
                          <div
                            className="d-lg-flex justify-content-between align-items-center pt-2"
                            style={{ boxSizing: "border-box" }}
                          >
                            <div className="py-1 d-flex text-truncate w-100 ">
                              <span>
                                {" "}
                                <PlaceOutlinedIcon
                                  fontSize="small"
                                  sx={{ marginBottom: "0.1ch" }}
                                />{" "}
                              </span>
                              <div className="px-1 text-truncate " title={`${getJobId.city}, ${getJobId.state}`}>
                                {getJobId.city}, {getJobId.state}
                              </div>
                            </div>
                            <div className="py-1 text-truncate text-lg-center w-100" style={{ letterSpacing: "0.5px" }}>
                              <span>
                                {" "}
                                <WorkOutlineOutlinedIcon
                                  className="mx-1"
                                  sx={{ marginBottom: "0.5ch" }}
                                  fontSize="small"
                                />{" "}
                              </span>
                              {/* {getJobId?.experience?.split(" ").length > 1
                              ? getJobId?.experience
                              : "-"} */}
                              {getJobId?.min_experience < 1 && !getJobId?.max_experience ? "Entry-Level" : getJobId?.min_experience?.toString() ? `${getJobId.min_experience}${!getJobId.max_experience ? '+ ' : ''}` : ""}
                              {getJobId?.max_experience ? " - " : ""}
                              {getJobId?.max_experience ? getJobId?.max_experience : ""}
                              {getJobId?.min_experience == 1 && getJobId?.max_experience == "" ? " years" : getJobId?.min_experience || getJobId?.max_experience ? " years" : ""}

                            </div>
                            <div className="py-1 d-flex text-truncate w-100">
                              <span>
                                {" "}
                                <ScheduleOutlinedIcon
                                  fontSize="small"
                                  sx={{ marginBottom: "0.5ch" }}
                                />{" "}
                              </span>
                              <div className="px-1">
                                {postedMessages}
                              </div>
                            </div>
                            <div className="py-1 d-flex text-truncate w-100">
                              <span>
                                {" "}
                                <EventAvailableIcon
                                  fontSize="small"
                                  sx={{ marginBottom: "0.5ch" }}
                                />{" "}
                              </span>
                              <div className="px-1">
                                {
                                  getJobId?.total_recruited > 1 ? `${getJobId?.total_recruited} Applied` : `${getJobId?.total_recruited} Applied`
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                        <h4 className="fx fw-bold py-2 px-0">About the Job</h4>
                      </div>
                    </div>

                    <div
                      className="col-12 jbscroll ps-4 pe-3 pb-5"
                      style={{ height: "45vh" }}
                    >
                      <div
                        id="htmlServer"
                        style={{ fontSize: "15px" }}
                        className="fw-normal"
                        dangerouslySetInnerHTML={{ __html: Html(getJobId.description) }}
                      ></div>
                      <div className="fx">
                        <h4 className="fw-bolder">Skills</h4>
                        <div className="">
                          <Stack direction="row" flexWrap="wrap" spacing={2}>
                            {getJobId?.skills?.length > 0 ? (
                              getJobId?.skills.map((data, i) => {
                                return (
                                  <>
                                    <Chip
                                      label={data}
                                      key={i}
                                      className="fw-bolder mb-2 text-capitalize"
                                      sx={{
                                        padding: "11px 5px",
                                      }}
                                    />
                                  </>
                                );
                              })
                            ) : (
                              <Chip
                                label="Not Available"
                                className="fw-bolder text-capitalize"
                                sx={{ padding: "11px 5px", marginBottom: "10px" }}
                              />
                            )}
                          </Stack>
                        </div>
                      </div>
                      <div className="row m-0 py-3 pb-4 fx">
                        <div className="col pb-3 ">
                          <div className="fw-bold">Job Type</div>
                          <div className="text-muted fw-bold">
                            {getJobId.text ? getJobId.text : "N/A"}
                          </div>
                        </div>
                        <div className="col">
                          <div className="fw-bold">Role</div>
                          <div
                            className="text-muted fw-bold"
                            style={{ letterSpacing: "0.5px" }}
                          >
                            {getJobId.role ? getJobId.role : "N/A"}
                          </div>
                        </div>
                        <div className="col-lg">
                          <div className="fw-bold">Work Mode</div>
                          <div className="text-muted fw-bold text-capitalize ">{getJobId.workmode ? getJobId.workmode : "N/A"}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              errorData && <Errorpage />
            )}
          </div>
        </div>
        <FooterZ />
      </div>
    </>
  );
};

export default Viewjobs;
