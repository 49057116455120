import { createSlice } from "@reduxjs/toolkit"
import { PURGE } from "redux-persist"

const initialState = {
    isVerified: 0,
    token: null,
    profile: null,
    credits: 0,
    isResumeVerified: 0,
    renewal_date: null
}

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        getUser: (state, action) => {
            return {
                ...state,
                isVerified: action.payload,
            }
        },
        setToken: (state, action) => {
            return {
                ...state,
                token: action.payload,
            };
        },
        getCredits: (state, action) => {
            return {
                ...state,
                credits: action.payload
            }
        },
        getProfile: (state, action) => {
            return {
                ...state,
                profile: action.payload
            }
        },
        isResumeVerified: (state, action) => {
            return {
                ...state,
                isResumeVerified: action.payload
            }
        },
        getRenewal_date: (state, action) => {
            return {
                ...state,
                renewal_date: action.payload
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(PURGE, () => initialState)
    },
})
export const { getUser, setToken, getProfile, getCredits, isResumeVerified, getRenewal_date } = userSlice.actions
export const selectUser = (state) => state.user
export const userReducer = userSlice.reducer
