import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Backdrops from './Backdrop'
import Button from '@mui/material/Button';
import { Helmet } from "react-helmet";
import { TextField } from '@mui/material'
const FooterN = ({ handleClick }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [email, setEmail] = useState('')
  const [error, setError] = useState(false)
  // const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  const regex = /^[\w.-]+@[a-zA-Z_-]+?(?:\.[a-zA-Z]{2,})+$/;
  const baseURL = process.env.REACT_APP_BASEURL;

  const locations = useLocation();
  // console.log("locations", locations);

  function errorHandler(status) {

    return setError(status)
  }

  const productEngineers = [
    'Embedded Engineer',
    'Network Engineer',
    'Test Engineer'
  ]

  const digitalEngineering = [
    'Artificial Intelligence',
    'Cyber Security',
    'Data Analyst',
    'Data Engineer',
    'Machine Learning',
    'QA Engineer',
    'Security Engineer',
    'Full Stack Developer',
    'Program Manager'
  ]

  const management = [
    'Business Analyst',
    'Product Manager',
    'Project Manager'
  ]

  const nonTech = [
    'Administrator',
    'HR',
    'Mechanical Engineer',
    'Technician',
  ]
  const nav = useNavigate();
  const handleprivacy = () => {
    nav('/privacy-policy');
    window.scrollTo(0, 0);
  }
  const handleTerms = () => {
    nav('/terms-condition');
    window.scrollTo(0, 0);
  }
  const handleBeware = () => {
    nav('/bewarefruad');
    window.scrollTo(0, 0);
  }
  const handlegrpr = () => {
    nav('/gdpr');
    window.scrollTo(0, 0);
  }
  const handlesecurity = () => {
    nav('/security-cheaker');
    window.scrollTo(0, 0);
  }
  const handleAbout = () => {
    nav('/about-us');
    window.scrollTo(0, 0);
  }

  const handlecontact = () => {
    nav('/contact-us')
    window.scrollTo(0, 0);
  }
  const handleHtmlSitemap = () => {
    nav('/sitemap-html')
    window.scrollTo(0, 0);
  }
  const changeHandler = (e) => {
    setEmail(e.target.value)
    errorHandler(false)
  }

  const onSubscribeHandler = async () => {
    try {
      if (!email) {
        return errorHandler(true);
      }
      if (!regex.test(email)) {
        return errorHandler(true);
      } else {
        setIsOpen(true);
        const { data, status } = await axios.post(`subscribe/user`, {
          email
        });
        // console.log(data);
        if (status !== 200) {
          setEmail('');
          toast.error('Please Try Again', { hideProgressBar: true, closeOnClick: true });
          setIsOpen(false);
        } else {
          if (data.message === "user already subscribed") {
            setEmail('');
            toast.warning('You are already subscribed!', { hideProgressBar: true, closeOnClick: true });
          } else {
            toast.success('Subscribed Successfully!', { hideProgressBar: true, closeOnClick: true });
          }
          setEmail('');
          setIsOpen(false);
        }
      }
    } catch (error) {
      toast.error(error.response.data.error, { hideProgressBar: true, closeOnClick: true });
      setIsOpen(false);
    }
  };



  const handleSearch = (skill) => {
    const param_title = skill
    if (locations.pathname.includes("jobsearch")) handleClick(skill);
    // else nav(`/jobsearch/allsearchdata?jobRole=${param_title}&search=${param_title}`, { state: { skill, param_title } });
  }



  // useEffect(() => {
  //   const script = document.createElement('script');
  //   script.src = "https://platform.linkedin.com/in.js";
  //   script.type = "text/javascript";
  //   script.async = true;
  //   script.innerHTML = 'lang: en_US';
  //   document.getElementsByTagName('head')[0].appendChild(script);
  // }, []);


  return (
    <>
      {isOpen && <Backdrops />}
      <div className='footerMain fx'>
        <div className="container footerMainSection " >
          <div className='row m-0'>
            <div className='col-12 logoinMobile '><img className='img-fluid' alt='Jobeze logo - Job search platform' title='Jobeze - AI-Powered Job Search Platform' src='/Logo.svg' /></div>
            <div className='col-lg-6 d-lg-flex flex-column justify-content-center  '>
              <div className='h5 fw-bold'>Get latest Jobs in your inbox</div>
              <div className='fw-bold text-muted'>Subscribe to our job alerts</div>
            </div>
            <div className='col-lg-6 '>
              <div className='d-lg-flex pb-3 py-lg-0 pt-2 gap-3 text-start justify-content-lg-end'>
                <div className='w-100 Subscribing'>
                  <TextField
                    placeholder='Write Email Address Here'
                    type='email'
                    value={email}
                    onChange={(e) => changeHandler(e)}
                    fullWidth
                    size='small'
                    error={error}
                    className=''
                    sx={{ marginBottom: "15px" }}
                    helperText={error ? "Enter valid email address" : ""}
                  />
                </div>
                <div>
                  <Button className='fw-bold' fullWidth sx={{ height: "40px" }} onClick={() => onSubscribeHandler()} variant="outlined">SUBSCRIBE</Button>
                </div>
              </div>
              <span className='fw-bold Subscribing'>By subscribing, you agree to our <a className='' href="/privacy-policy"> Privacy Policy</a> & <a className='' href="/terms-condition">Terms & Conditions</a></span>
            </div>
          </div>
          <div className="row mt-5">
            <div className='col-lg-2 logoinDesktop '><img className='img-fluid' alt='Jobeze logo - Job search platform' title='Jobeze - AI-Powered Job Search Platform' src='/Logo.svg' /></div>
            <div className='col-lg-10 links-section'>
              <div className=''>
                <ul className='ul-list'  >
                  <li className='common-font pb-4'>Product Engineering</li>
                  {
                    productEngineers.map((e, index) => {
                      return <Link key={index} style={{ textDecoration: 'none' }} to={`/jobsearch/allsearchdata?search=${e}&jobRole=${e}&location=${"World Wide"}`} onClick={() => handleSearch(e)} ><li className='common-font-2 py-1 pb-2' >{e}</li></Link>
                    })
                  }
                </ul>
              </div>
              <div>
                <ul className='ul-list' >
                  <li className='common-font pb-4'>Digital Engineering</li>
                  {
                    digitalEngineering.map((e, index) => {
                      return <Link key={index} style={{ textDecoration: 'none' }} to={`/jobsearch/allsearchdata?search=${e}&jobRole=${e}&location=${"World Wide"}`} onClick={() => handleSearch(e)} ><li className='common-font-2 py-1 pb-2' >{e}</li></Link>
                    })
                  }
                </ul>
              </div>
              <div>
                <ul className='ul-list' >
                  <li className='common-font pb-4'>Management</li>
                  {
                    management.map((e, index) => {
                      return <Link key={index} style={{ textDecoration: 'none' }} to={`/jobsearch/allsearchdata?search=${e}&jobRole=${e}&location=${"World Wide"}`} onClick={() => handleSearch(e)} ><li className='common-font-2 py-1 pb-2' >{e}</li></Link>
                    })
                  }

                </ul>
              </div>
              <div>
                <ul className='ul-list'>
                  <li className='common-font pb-4'>Non Tech</li>
                  {
                    nonTech.map((e, index) => {
                      return <Link key={index} style={{ textDecoration: 'none' }} to={`/jobsearch/allsearchdata?search=${e}&jobRole=${e}&location=${"World Wide"}`} onClick={() => handleSearch(e)} ><li className='common-font-2 py-1 pb-2' >{e}</li></Link>
                    })
                  }
                </ul>
              </div>
              <div>
                <ul className='ul-list'>
                  <li className='common-font'>Jobeze</li>
                  <li className='common-font-2' style={{ paddingTop: '24px' }} onClick={handleAbout}>About Us</li>
                  <li className='common-font-2' style={{ paddingTop: '16px' }} onClick={handlecontact}>Contact us</li>
                  <li className='common-font-2' style={{ paddingTop: '16px' }}><a className='text-decoration-none text-dark' href='/signup'>Upload Resume</a></li>
                  <li className='common-font-2' style={{ paddingTop: '16px' }} onClick={handleHtmlSitemap} >HTML Sitemap</li>
                  <li className='common-font-2' style={{ paddingTop: '16px' }}><a className='text-decoration-none text-dark' href='/sitemap-index.xml'>XML Sitemap</a></li>
                </ul>
              </div>
              <div>
                <ul className='ul-list'>
                  <li className='common-font'>Legal</li>
                  <li className='common-font-2' style={{ paddingTop: '24px' }} onClick={handleprivacy}>Privacy Policy</li>
                  <li className='common-font-2' style={{ paddingTop: '16px' }} onClick={handleTerms}>Terms of Service</li>
                  <li className='common-font-2' style={{ paddingTop: '16px' }} onClick={handleBeware}>Beware of Fraudsters</li>
                  <li className='common-font-2' style={{ paddingTop: '16px' }} onClick={handlegrpr}>GDPR EU Cookies</li>
                  <li className='common-font-2' style={{ paddingTop: '16px' }} onClick={handlesecurity}>Security Center</li>
                </ul>
              </div>
            </div>
          </div>
          {/* <div className="spreation-line"></div> */}
          <hr />
          <div className=" d-lg-flex justify-content-between pt-4 container-sm " >
            <div className="footer-end-col1">
              © 2023 Jobeze. All rights reserved.
            </div>
            <div className="d-flex socialicons" style={{ gap: '15px', marginRight: "45px" }}>
              <a href="https://www.facebook.com/people/Jobeze/100092156896675/" target='_blank' rel='noreferrer' ><img src="/facebook.svg" alt="Follow Jobeze on Facebook for job updates" title='Jobeze Facebook - Stay Updated on Jobs' /></a>
              <a href="https://www.instagram.com/jobeze_job/ " target='_blank' rel='noreferrer' ><img src="/insta.svg" alt="Follow Jobeze on Instagram for job openings" title='Jobeze Instagram - Find Jobs Near You' /></a>
              <a href=" https://twitter.com/jobeze_jobs" target='_blank' rel='noreferrer' ><img src="/twitter.svg" alt="Follow Jobeze on Twitter for job search tips" title='Jobeze Twitter - AI-Powered Job Search Updates' /></a>
              {/* <div className='d-flex gap-2 justify-content-center position-relative overflow-hidden'>
                <a target='_blank' rel='noreferrer'>
                  <img src="/linkdin.svg" alt="" />
                </a>
                <div style={{ position:"absolute" , top:"0", opacity:"0"  }}>
                  <script type="IN/FollowCompany" data-id="93251773"  data-counter="bottom"></script>
                </div>
              </div> */}
              <a href="https://www.linkedin.com/company/jobeze/?isFollowingPage=true" target='_blank' rel='noreferrer'><img src="/linkdin.svg" alt="Connect with Jobeze on LinkedIn for job opportunities" title='Jobeze LinkedIn - Professional Job Listings' /></a>
            </div>
          </div>

        </div>

      </div>
    </>
  )
}

export default FooterN