import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
function SavedShimmer(props) {
    return (
        <>
            <Stack spacing={1}>
                <Skeleton variant="text" height={150} sx={{ fontSize: '1rem' }} />
                <Skeleton variant="text" height={150} />
                <Skeleton variant="text" height={150} />
                <Skeleton variant="text" height={150} />
            </Stack>
        </>
    );
}

export default SavedShimmer;