import React, { useEffect, useState, useCallback } from "react";
import Button from "@mui/material/Button";
import { useMediaQuery } from "@mui/material";

function JobPostedFilter(props) {
  const isMediumScreen = useMediaQuery("(max-width: 1200px)");

  console.log(props);

  return (
    <div className="py-2">
      <div className="dropdown fx">
        <button
          className="btn btn-outline-primary dropdown-toggle px-3 rounded-pill dropdownclassesfilter fw-bold"
          type="button"
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          style={{ letterSpacing: "0.7px" }}
        >
          {props.name}
        </button>
        <div
          className="dropdown-menu filterchipDropdown1 shadow border-0"
          aria-labelledby="dropdownMenuButton1"
        >
          <ul className="jobposttype">
            <li>
              {props.menuOptions.map((option, index) => (
                <label
                  key={index}
                  className="dropdown-item "
                  onClick={(e) => e.stopPropagation()}
                  style={{ fontWeight: "600" }}
                >
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="jobPosted"
                      id={`flexRadioDefault${index}`}
                      style={{ cursor: "pointer" }}
                      checked={option.isSelected}
                      value={option.value}
                      onChange={(e) => props.changeRoles(e, index)}
                    />
                    <div
                      className="text-wrap text-capitalize py-0"
                      style={{ fontSize: "14px" }}
                    >
                      {option.label}
                    </div>
                  </div>
                </label>
              ))}
            </li>
          </ul>
          <div className="d-flex justify-content-evenly">
            <Button
              size="medium"
              onClick={() => props.reset()}
              className="text-dark fw-bold col-5"
              sx={{ letterSpacing: "0.2ch" }}
            >
              Reset
            </Button>
            <Button
              variant="contained"
              size="medium"
              onClick={() => props.show()}
              className=" col-5 fw-bold"
              sx={{ letterSpacing: "0.2ch" }}
            >
              Show
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default JobPostedFilter;
