import React, { useState } from "react";
import axios from "axios";
import { Button, TextField, InputAdornment } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import ResendBackdrop from "../ResendBackdrop";

export function Forgetpage() {
  const nav = useNavigate();
  const loc = useLocation();
  const [newPass, setNewPassword] = useState("");
  const [confirmPass, setConfPass] = useState("");
  const [showNewPass, setShowNewPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const regexPass = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

  const [passval, setPassval] = useState({
    alert: "",
    flag: false,
    match: "",
    matchFlag: false,
  });


  const handleSendData = async () => {
    try {
      if (!newPass) throw new Error("Please Enter New Password");
      if (!regexPass.test(newPass))
        throw new Error(
          "Use 8 or more characters with a mix of uppercase and lowercase letters, numbers & symbols. (ex : 11aAA11@)"
        );
      if (newPass !== confirmPass)
        throw new Error("New password and confirm password do not match");

      setPassval({ alert: "", flag: false });

      
      

      const response = await axios.post(`user/reset-password/verify-user`, {
        token: loc.pathname.split("/")[4],
        password: newPass === confirmPass ? newPass : "",
      });

      if (response.data.status === 201) {
        localStorage.clear();
        const d = new Date();
        let expires = "expires=" + d.toUTCString();
        document.cookie = "_secure_ARJ_" + "=" + "" + ";" + expires + ";path=/";
        
        toast.success(response.data.message, {
          hideProgressBar: true,
          closeOnClick: true,
        });
        
        setTimeout(() => {
        nav("/login")
        }, 1000);
      }
    } catch (error) {
      
      
      toast.error(error.message, {
        hideProgressBar: true,
        closeOnClick: true,
      });
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSendData();
    }
  };

  return (
    <>
    
          <div className="d-flex justify-content-center align-items-center fx" style={{ height: "89vh" }}>
            <div className="bg-white p-5 rounded-3" style={{ boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)" }}>
              <div className="text-center">
                <div className="h3 fw-bold py-2">Create New Password</div>
                <div className="py-3">
                  <TextField
                    type={showNewPass ? "text" : "password"}
                    value={newPass}
                    onChange={(e) => setNewPassword(e.target.value)}
                    placeholder="New Password"
                    onKeyDown={handleKeyDown}
                    fullWidth
                    margin="normal"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {showNewPass ? (
                            <VisibilityOffIcon sx={{cursor: 'pointer'}} onClick={() => setShowNewPass(false)} />
                          ) : (
                            <VisibilityIcon sx={{cursor: 'pointer'}} onClick={() => setShowNewPass(true)} />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                  {passval.flag && (
                    <div className="text-start pb-2" style={{ width: "375px" }}>
                      <span style={{ color: "red", fontSize: "12px" }}>{passval.alert}</span>
                    </div>
                  )}
                  <TextField
                    type={showConfirmPass ? "text" : "password"}
                    value={confirmPass}
                    onChange={(e) => setConfPass(e.target.value)}
                    placeholder="Confirm Password"
                    onKeyDown={handleKeyDown}
                    fullWidth
                    margin="normal"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {showConfirmPass ? (
                            <VisibilityOffIcon sx={{cursor: 'pointer'}} onClick={() => setShowConfirmPass(false)} />
                          ) : (
                            <VisibilityIcon sx={{cursor: 'pointer'}} onClick={() => setShowConfirmPass(true)} />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                  <div className="text-start">
                    <span style={{ color: "red", fontSize: "12px" }}>{passval.match}</span>
                  </div>
                </div>
                <Button className="w-100" onClick={handleSendData} variant="contained" onKeyDown={handleKeyDown}>
                  Save
                </Button>
              </div>
            </div>
          </div>
      
    </>
  );
};

