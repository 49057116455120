import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const Backdrops = () => {
    const [open, setOpen] = React.useState(true);
  return (
    <div>
      <Backdrop sx={{ color: '#1976d2', zIndex: (theme) => theme.zIndex.drawer + 20, bgcolor:"#fff" }} open={open}>
            <CircularProgress color="inherit" />
        </Backdrop>
    </div>
  )
}
export default Backdrops