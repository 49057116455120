import * as React from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import {
  Autocomplete,
  Chip,
  CircularProgress,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  Select,
  Slider,
  TextField,
  Typography,
  debounce,
  useMediaQuery,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import { toast } from "react-toastify";
import axios from "axios";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import moment from "moment";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const PrettoSlider = styled(Slider)({
  color: "#006CB7",
  height: 4,
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 18,
    width: 18,
    backgroundColor: "#006CB7",
    border: "2px solid currentColor",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 26,
    height: 26,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: "#006CB7",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&:before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
});

export default function Professionaldrawer({
  drawerProfessionalProps,
  duplicate,
  date_modified,
}) {
  const [intialYears, setInitialYears] = useState(
    drawerProfessionalProps?.years || ""
  );

  const [intialMonths, setInitialMonths] = useState(
    drawerProfessionalProps?.months || ""
  );
  const [loading, setLoading] = useState(false);
  const [currentState, setCurrentState] = useState("");
  const [disablecurrent_employer, _disablecurrent_employer] = useState(true);
  const [disableCTC, _disableCTC] = useState(true);
  const [disableExp_CTC, _disableExp_CTC] = useState(true);
  const [disablerelocate, _disablerelocate] = useState(true);
  const [disableradius, _disableradius] = useState(true);
  const [disableyears, _disableyears] = useState(true);
  const [disablemonths, _disablemonths] = useState(true);
  const [disablenotice_period, _disableNotice_period] = useState(true);

  const duplicates = {
    current_employer: duplicate?.details?.current_employer,
    CTC: duplicate?.details?.CTC,
    Exp_CTC: duplicate?.details?.Exp_CTC,
    relocate: duplicate?.details?.relocate,
    current_loc: duplicate?.details?.current_loc,
    radius: duplicate?.details?.radius,
    notice_period: duplicate?.details?.notice_period,
    last_working_day: duplicate?.details?.last_working_day,
    expected_joining_date: duplicate?.details?.expected_joining_date,
  };

  const [drawerData, setDrawerData] = React.useState(
    drawerProfessionalProps.ProfessionalDrawerField
  );





  const [state, setState] = React.useState({
    right: true,
  });

  const onChangeDrawerHandler = (e) => {
    const { name, value } = e.target;
    setDrawerData({ ...drawerData, [name]: value });
    if (name === "current_employer") {
      if (duplicates["current_employer"] === value) {
        _disablecurrent_employer(true);
      }
      else if (value === "" && !duplicates["current_employer"]) {
        _disablecurrent_employer(true);
      }
      else {
        _disablecurrent_employer(false);
      }
    }
    if (name === "CTC") {
      if (duplicates["CTC"] === value) {
        _disableCTC(true);
      }
      else if (value === "" && !duplicates["CTC"]) {
        _disableCTC(true);
      }
      else {
        _disableCTC(false);
      }
    }
    if (name === "notice_period") {
      if (duplicates["notice_period"] === parseInt(value)) {
        _disableNotice_period(true);
      }
      else if(value == "" && !duplicates["notice_period"]){
        _disableNotice_period(true);
      } 
      else {
        _disableNotice_period(false);
      }
    }
    if (name === "Exp_CTC") {
      if (duplicates["Exp_CTC"] === value) {
        _disableExp_CTC(true);
      }
      else if (value === "" && !duplicates["Exp_CTC"]) {
        _disableExp_CTC(true);
      }
      else {
        _disableExp_CTC(false);
      }
    }
    if (name === "relocate") {
      if (duplicates?.["relocate"]?.toLowerCase() === value) {
        _disablerelocate(true);
      } else {
        _disablerelocate(false);
      }
    }
    if (name === "radius") {
      if (duplicates["radius"] === value.toString()) {
        _disableradius(true);
      } else {
        _disableradius(false);
      }
    }
  };

  const areArraysEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) {
      return false;
    }

    for (let i = 0; i < arr1.length; i++) {
      if (!arr2.some((obj) => obj.label === arr1[i].label)) {
        return false;
      }
    }

    return true;
  };

  const handleClose = () => {
    drawerProfessionalProps.setonclickdrawer(false);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const currentSalaryInputRef = React.useRef(null); // Step 1: Create a ref

  // ... (other state and functions)

  // Step 2: Create an onWheel event handler
  const handleCurrentSalaryInput = (e) => {
    const enteredValue = e.target.value;
    if (enteredValue.startsWith("-")) {
      // Prevent updating the value if it starts with a negative sign
      e.target.value = enteredValue.substring(1);
    }
  };

  const handleCurrentSalaryWheel = (e) => {
    if (currentSalaryInputRef.current === document.activeElement) {
      e.preventDefault();
    }
  };

  // Assuming drawerData.last_working_day holds the date value
  const [pfrdArray, setPfrdArray] = useState(() => {
    if (
      drawerData &&
      (drawerData.preferred_loc) &&
      drawerData.preferred_loc[0]?.label !== "Any Location()"
    ) {
      //  const setPrefData = [...new Set(drawerData.preferred_loc)]
      return [...new Set(drawerData.preferred_loc)];
    }
    return [];
  });


  const handleSubmit = () => {
    if (drawerData.relocate === "no" && !pfrdArray.length) {
      toast.error("Please select preferred location", {
        hideProgressBar: true,
        closeOnClick: true,
      });
      return;
    }
    // const date_modified = new Date().toISOString().slice(0, 19).replace("T", " ");
    // const removeNullfromPreferdLocation = pfrdArray.filter((item) => item !== null);

    const payload = {
      token: document.cookie,
      profile: "professional",
      year: intialYears ? parseInt(intialYears) : null,
      month: intialMonths ? parseInt(intialMonths) : null,
      original_date_modified: date_modified,
      // experience:
      //   (drawerProfessionalProps.years ? drawerProfessionalProps.years : "") +
      //   (drawerProfessionalProps?.months ? " " : "") +
      //   (drawerProfessionalProps?.months
      //     ? drawerProfessionalProps?.months
      //     : ""),
      current_employer: drawerData.current_employer
        ? drawerData.current_employer.charAt(0).toUpperCase() + drawerData.current_employer.slice(1)
        : null,

      CTC: drawerData.CTC ? parseInt(drawerData.CTC) : null,

      Exp_CTC: drawerData.Exp_CTC ? parseInt(drawerData.Exp_CTC) : null,
      // radius: drawerData.radius ? drawerData.radius : "",
      radius: drawerData.radius ? drawerData.relocate === "yes" ? "0" : drawerData.radius : null,
      // preferred_loc: drawerData.relocate === "yes" ? [0] : drawerData.preferred_loc ? drawerData.preferred_loc.map((item) => item.value) : [],
      preferred_loc: drawerData.relocate === "yes" ? [0] : pfrdArray.map((item) => item.value),
      // current_loc: drawerData.current_loc ? drawerData.current_loc.map((item) => item.value) : null,
      current_loc: currentID ? currentID : drawerData.current_loc[0]?.value ? drawerData.current_loc[0]?.value : null,
      work_authorization: null,
      notice_period: drawerData.notice_period ? parseInt(drawerData.notice_period) : null,
      last_working_day: drawerData.last_working_day ? drawerData.last_working_day : null,
      expected_joining_date: drawerData.expected_joining_date ? drawerData.expected_joining_date : null
    };

    drawerProfessionalProps.setEffectDependency(true);
    axios
      .post(`user/details/update-details`, payload)
      .then((response) => {
        setLoading(true);
        if (response.status === 201) {
          drawerProfessionalProps.setonclickdrawer(false);
          drawerProfessionalProps.setEffectDependency(false);
          toast.success("Details Saved Successfully", {
            hideProgressBar: true,
            closeOnClick: true,
          });
          setLoading(false);
        } else {
          // drawerProfessionalProps.setonclickdrawer(false)
          toast.error("Please try after sometime", {
            hideProgressBar: true,
            closeOnClick: true,
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        drawerProfessionalProps.setonclickdrawer(false);
      });
  };


  const [disableLWD, setDisableLWD] = useState(true);
  const [disableEJD, setDisableEJD] = useState(true);


  // Step 1: Create a state for lwdDate
  const handleLWDDateChange = (e) => {
    let date = "";
    if (e) {
      // Extracting date components from the selected date
      const month = e.$d.getMonth() + 1;
      const formattedMonth = month < 10 ? `0${month}` : month;
      const day = ("0" + e.$D).slice(-2);
      date = `${e.$d.getFullYear()}-${formattedMonth}-${day}`;
      // Updating the state with the selected date
      setDrawerData({ ...drawerData, last_working_day: date });

      // Checking if the selected date matches the condition
      if (date === duplicates['last_working_day']?.split('T')[0]) {
        setDisableLWD(true);
      } else {
        setDisableLWD(false);
      }
    }
  };



  const handleEJDDateChange = (e) => {
    let date = "";
    if (e) {
      // Extracting date components from the selected date
      const month = e.$d.getMonth() + 1;
      const formattedMonth = month < 10 ? `0${month}` : month;
      const day = ("0" + e.$D).slice(-2);
      date = `${e.$d.getFullYear()}-${formattedMonth}-${day}`;
      // Updating the state with the selected date
      setDrawerData({ ...drawerData, expected_joining_date: date });

      // Checking if the selected date matches the condition
      if (date === duplicates['expected_joining_date']?.split('T')[0]) {
        setDisableEJD(true);
      } else {
        setDisableEJD(false);
      }
    }
  }
  const [preferredState, setPreferredState] = useState([]);

  const [CurrentCity, setCurrentCity] = useState(() => {
    return drawerData.current_loc[0]?.label
  });

  const [PrefferedCity, setPreferredCity] = useState([]);

  const CurrentFetch = async () => {
    if (CurrentCity) {
      const response = await axios.post("state-city/list", {
        cityName: CurrentCity ? CurrentCity : "",
      });
      setCurrentState(response.data.message);
    }
  };

  const PreferredFetch = async () => {
    if (PrefferedCity && typeof PrefferedCity === "string") {

      const response = await axios.post("state-city/list", {
        cityName: PrefferedCity,
      });
      setPreferredState(response.data.message);
    }
  };

  const handlePreInputChange = (value) => {
    if (value.length > 2) setPreferredCity(value);

  };

  React.useEffect(() => {
    const GetCurrent = async () => {
      await CurrentFetch();
    };
    GetCurrent();
  }, [CurrentCity]);

  React.useEffect(() => {
    const GetPreferred = async () => {
      await PreferredFetch();
    };
    GetPreferred();
  }, [PrefferedCity]);

  const [currentID, setCurrentID] = useState(null);
  const [disabledCurrentLoc, setDisabledCurrentLoc] = useState(true);

  const handleCurrentID = (event, value, reason) => {
    const selectedID = currentState.find((item) => item.city === value);
    if (value) {
      if (selectedID) {
        setLoading(true);
        setCurrentID(selectedID.city_id);
      }
      
      // setLoading(false);
    }
    else {
      setCurrentID(null);
    }

    // console.log(event.cancelable,"418");
    if (event.cancelable){
       if (reason === "clear") {
        // console.log("Are you sure to clear?"); // or you can open Dialog here
        setDisabledCurrentLoc(false);
        setDrawerData({ ...drawerData, current_loc: [{ label: "", value: "" }] });
        //other codes go here like setting the value of input
      }
    }
  }

  

  const handleCurrentCityChange = debounce(async (newInputValue) => {
    if (newInputValue){
      setLoading(false);
    }
    
    

    if (newInputValue.length >= 3) {
      setCurrentCity(newInputValue);
      setDisabledCurrentLoc(false);
      if (newInputValue === duplicates?.["current_loc"]?.[0]?.label) {
        setDisabledCurrentLoc(true);
      }
      if (drawerData.current_loc[0]?.label === newInputValue) {
        setDisabledCurrentLoc(true);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } else {
      setCurrentCity("");
    }
    setLoading(false);
  }, 1500);

  const isSmallScreen = useMediaQuery("(max-width: 625px)");
  const list = (anchor) => (
    <Box sx={{ width: isSmallScreen ? "100vw" : 425 }} role="presentation">
      <List className="fx ">
        <form>
          <div className="basic-drawer-buttons">
            <div className="drawer-title">Professional Details</div>
            <div className="app" style={{ cursor: "pointer" }}>
              <ClearIcon onClick={handleClose} />
            </div>
          </div>
          <div className="basic-drawer-divider"></div>
          <div className="professional-fields-handle">
            <div
              className={`${drawerData.relocate === "no" ? "basic-scroll jb" : ""
                } `}
            >
              <div
                className="fw-bold "
                style={{ marginTop: "10px", marginLeft: "32px" }}
              >
                Experience
              </div>
              <div className="professional-drawer-inputs">
                <div
                  className=""
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    width: "85%",
                  }}
                >
                  <TextField
                    id="outlined-basic"
                    label="Years"
                    variant="outlined"
                    value={intialYears}
                    autoComplete="off"
                    size="small"
                    onChange={(e) => {
                      const inputValue = e.target.value;

                      // Check if the input is empty or a valid number within the range [0, 40]
                      if (
                        inputValue === "" ||
                        (/^\d+$/.test(inputValue) &&
                          parseInt(inputValue) >= 0 &&
                          parseInt(inputValue) <= 40)
                      ) {
                        setInitialYears(inputValue);
                        if (inputValue === drawerProfessionalProps?.years) {
                          _disableyears(true);
                        } else {
                          _disableyears(false);
                        }
                      }
                      // If not, you can set an error message or take another action
                      else {
                        // You can set an error state or display an error message to the user.
                        // For this example, I'll set an error message in the state:
                        drawerProfessionalProps.setErrorMessage(
                          "Enter a number between 0 and 40"
                        );
                        _disableyears(true);
                      }
                    }}
                    InputProps={{ sx: { fontWeight: "bold" } }}
                    sx={{ width: "48.5%" }}
                  />

                  <FormControl sx={{ width: "48.5%" }}>
                    <InputLabel
                      className="fw-bold"
                      id="demo-simple-select-label"
                      size="small"
                    >
                      Months
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="experience"
                      size="small"
                      value={intialMonths}
                      label="Months"
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        setInitialMonths(inputValue);
                        // Add the condition for disabling months
                        if (
                          drawerProfessionalProps?.months ===
                          inputValue.toString()
                        ) {
                          _disablemonths(true);
                        } else {
                          _disablemonths(false);
                        }
                      }}
                      MenuProps={MenuProps}
                      className="fw-bold"
                    >
                      <MenuItem value={0}>0</MenuItem>
                      <MenuItem value={1}>1</MenuItem>
                      <MenuItem value={2}>2 </MenuItem>
                      <MenuItem value={3}>3 </MenuItem>
                      <MenuItem value={4}>4</MenuItem>
                      <MenuItem value={5}>5</MenuItem>
                      <MenuItem value={6}>6</MenuItem>
                      <MenuItem value={7}>7</MenuItem>
                      <MenuItem value={8}>8</MenuItem>
                      <MenuItem value={9}>9</MenuItem>
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={11}>11</MenuItem>
                    </Select>
                  </FormControl>
                </div>

                <TextField
                  size="small"
                  id="outlined-basic"
                  value={drawerData.current_employer}
                  variant="outlined"
                  label="Current Employer"
                  autoComplete="off"
                  sx={{ width: "85%" }}
                  name="current_employer"
                  InputProps={{ sx: { fontWeight: "bold" } }}
                  onChange={(e) => {
                    if (e.target.value.length <= 60) {
                      onChangeDrawerHandler(e);
                    }
                  }}
                />

                <FormControl sx={{ width: "85%" }}>
                  <InputLabel size="small" htmlFor="outlined-adornment-amount">
                    Current CTC
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-amount"
                    startAdornment={
                      <InputAdornment position="start">$</InputAdornment>
                    }
                    size="small"
                    label="Current CTC"
                    type="tel"
                    name="CTC"
                    autoComplete="off"
                    inputProps={{
                      style: { fontWeight: 800 },
                      onKeyPress: (e) => {
                        const charCode = e.which ? e.which : e.keyCode;
                        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                          e.preventDefault();
                        }
                      },
                    }}
                    inputRef={currentSalaryInputRef}
                    value={drawerData.CTC}
                    onChange={(e) => {
                      const enteredValue = e.target.value;
                      if (enteredValue.length <= 10) {
                        onChangeDrawerHandler(e);
                      }
                    }}
                  />
                </FormControl>

                <FormControl sx={{ width: "85%" }}>
                  <InputLabel size="small" htmlFor="outlined-adornment-amount">
                    Expected CTC
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-amount"
                    startAdornment={
                      <InputAdornment position="start">$</InputAdornment>
                    }
                    size="small"
                    autoComplete="off"
                    label="Expected CTC"
                    type="tel"
                    name="Exp_CTC"
                    inputProps={{
                      style: { fontWeight: 800 },
                      onKeyPress: (e) => {
                        const charCode = e.which ? e.which : e.keyCode;
                        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                          e.preventDefault();
                        }
                      },
                    }}
                    inputRef={currentSalaryInputRef}
                    value={drawerData.Exp_CTC}
                    onChange={(e) => {
                      const enteredValue = e.target.value;
                      if (enteredValue.length <= 10) {
                        onChangeDrawerHandler(e);
                      }
                    }}
                  />
                </FormControl>


                <Box sx={{ width: "85%" }}>
                  <Box>
                    <Autocomplete
                      fullWidth
                      size="small"
                      value={CurrentCity}
                      autoHighlight
                      loading={loading}
                      autoComplete="off"
                      onInputChange={(event, newInputValue) => {
                        handleCurrentCityChange(newInputValue);
                      }}
                      filterSelectedOptions
                      onChange={handleCurrentID}
                      id="controllable-states-demo"
                      options={
                        (Array.isArray(currentState)
                          ? currentState.map((e) => e.city)
                          : []) || []
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          autoComplete="off"
                          label="Current Location"
                          size="small"

                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {loading ? (
                                  <CircularProgress size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  </Box>
                </Box>
                <Box sx={{ width: "85%" }}>
                  <OutlinedInput
                    fullWidth
                    id="outlined-basic"
                    size="small"
                    type="tel"
                    name="notice_period"
                    placeholder="Notice Period"
                    className="fw-bold"
                    value={drawerData.notice_period}
                    endAdornment={<InputAdornment position="end">Days</InputAdornment>}
                    onChange={(e) => onChangeDrawerHandler(e)}
                    autoComplete="off"
                    inputProps={{
                      maxLength: 2,
                      'aria-label': 'weight',
                      onKeyPress: (e) => {
                        const charCode = e.which ? e.which : e.keyCode;
                        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                          e.preventDefault();
                        }
                      },
                      pattern: "[0-9]*"
                    }}
                    onPaste={(e) => {
                      e.preventDefault();
                      const pastedText = e.clipboardData.getData('text/plain');
                      const filteredText = pastedText.replace(/[^\d]/g, ''); // Keep only digits
                      navigator.clipboard.writeText(filteredText)
                        .then(() => {
                          // Update the field value after pasting filtered text
                          e.target.value = filteredText;
                          onChangeDrawerHandler(e);
                        })
                        .catch(err => {
                          console.error('Error writing to clipboard: ', err);
                        });
                    }}
                  />
                </Box>
                <Box sx={{ width: "85%" }}>
                  <LocalizationProvider size="small" dateAdapter={AdapterDayjs}>
                    <DatePicker
                      size="small"
                      sx={{ width: "100%" }}
                      label="Last Working Day"
                      value={drawerData.last_working_day ? dayjs(drawerData.last_working_day) : null}
                      format="MM/DD/YYYY"
                      onChange={handleLWDDateChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                        />
                      )}
                      slotProps={{
                        textField: {
                          readOnly: true,
                          size: "small",
                        },
                      }}

                    // disableFuture={true}
                    // maxDate={dayjs(moment().subtract('years', 18))}
                    // minDate={dayjs(moment().subtract('years', 70))}
                    />
                  </LocalizationProvider>
                </Box>
                <Box sx={{ width: "85%" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      // className="basic-dob"
                      size="small"
                      sx={{ width: "100%" }}
                      label="Expected Joining Date"
                      value={drawerData.expected_joining_date ? dayjs(drawerData.expected_joining_date) : null}
                      format="MM/DD/YYYY"
                      onChange={handleEJDDateChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                        // Set the input field as read-only
                        />
                      )}
                      slotProps={{
                        textField: {
                          readOnly: true,
                          size: "small",
                        },
                      }}

                      disablePast={true}
                    // maxDate={dayjs(moment().subtract('years', 18))}
                    // minDate={dayjs(moment().subtract('years', 70))}
                    />
                  </LocalizationProvider>
                </Box>
                <Box>
                  <Typography sx={{ fontWeight: "600" }}>
                    Ready to Relocate ?
                  </Typography>
                  <Box sx={{ display: "flex", gap: "58px" }}>
                    <Box>
                      <Radio
                        checked={drawerData.relocate === "no"}
                        onChange={(e) => onChangeDrawerHandler(e)}
                        value="no"
                        size="small"
                        name="relocate"
                        inputProps={{ "aria-label": "No" }}
                      />
                      <label className="fw-bold">Preferred Location</label>
                    </Box>
                    <Box>
                      <Radio
                        checked={drawerData.relocate === "yes"}
                        onChange={(e) => onChangeDrawerHandler(e)}
                        value="yes"
                        size="small"
                        name="relocate"
                        inputProps={{ "aria-label": "Yes" }}
                      />
                      <label className="fw-bold">Any Location</label>
                    </Box>
                  </Box>
                </Box>

                {drawerData.relocate === "no" && (
                  <Box sx={{ width: "85%" }}>
                    <Autocomplete
                      multiple
                      value={pfrdArray}
                      size="small"
                      autoComplete="off"
                      onChange={(e, val) => {
                        // Only allow update if there are 5 or fewer selected items
                        if (val.length <= 5) {
                          const originalPrefLoc = drawerData.preferred_loc;
                          // const filteredOriginalPrefLoc = originalPrefLoc.filter(loc => loc.label !== "Any Location()");
                          const isPrefLocEqual = areArraysEqual(
                            originalPrefLoc,
                            val
                          );
                          if (!isPrefLocEqual) {
                          setPfrdArray(val);
                          _disableradius(false);
                          } else {
                          setPfrdArray(val);
                          return _disableradius(true);
                          }
                        } else {
                          toast.error("You can select up to 5 locations only.");
                        }
                      }}
                      onInputChange={(event, newInputValue) => {
                        handlePreInputChange(newInputValue);
                      }}
                      options={preferredState?.map((ele) => ({ label: ele.city, value: ele.city_id }))}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <>
                            {/* {
                              option.label === "Any Location()" ? 
                              <></>
                              :
                                <Chip
                                  variant="filled"
                                  {...getTagProps({ index })}
                                  label={option.label}
                                />
                            } */}
                            <Chip
                              variant="filled"
                              {...getTagProps({ index })}
                              label={option.label}
                            />
                          </>
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          size="small"
                          autoComplete="off"
                          placeholder={
                            PrefferedCity.length > 0
                              ? "Add another Locations"
                              : "Enter preferred locations"
                          }
                        />
                      )}
                    />
                    <Box className='fw-bold py-3 px-2'>
                      <Typography className="fw-bold"
                        gutterBottom
                      >
                        Radius in miles
                      </Typography>
                      <PrettoSlider
                        valueLabelDisplay="auto"
                        name="radius"
                        onChange={(e) => onChangeDrawerHandler(e)}
                        aria-label="pretto slider"
                        value={drawerData.radius ? drawerData.radius : 1}
                      />
                    </Box>
                  </Box>

                )}
              </div>


            </div>

            <div>
              <div className="basic-drawer-divider-1"></div>
              <div className="drawer-buttons-p">
                <Button sx={{ fontWeight: "600" }} onClick={handleClose}>
                  CANCEL
                </Button>
                <Button
                  variant="contained"
                  onClick={handleSubmit}
                  disabled={
                    disablecurrent_employer &&
                    disableCTC &&
                    disableExp_CTC &&
                    disablerelocate &&
                    disableradius &&
                    disableyears &&
                    disablemonths &&
                    disablenotice_period &&
                    disableLWD &&
                    disableEJD &&
                    disabledCurrentLoc
                  }
                >
                  SAVE
                </Button>
              </div>
            </div>
          </div>
        </form>
      </List>
    </Box>
  );
  return (
    <div>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            disableSwipeToClose={true}
            onClose={toggleDrawer(anchor, true)}
            onOpen={toggleDrawer(anchor, true)}
            allowSwipeInChildren={true}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}
