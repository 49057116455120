import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import "./Cookies.css"
import { Link } from 'react-router-dom';

const CookiesComponent = () => {
  const [accepted, setAccepted] = useState(Cookies.get('cookiesAccepted') === 'true');

  useEffect(() => {
    const cookiesAccepted = Cookies.get('cookiesAccepted');
    if (cookiesAccepted === 'true') {
      setAccepted(true);
    }
  }, []);

  const acceptCookies = () => {
    Cookies.set('cookiesAccepted', 'true', { expires: 365 });
    setAccepted(true);
  };

  if (accepted) {
    return null;
  }

  return (
    <div className="cookies-banner w-100 text-center p-1 d-flex justify-content-center align-items-center ">
        <div className='text-white fw-bold'>We use cookies to improve your experience. By continuing to browse the site, you agree to our
          <a href="/privacy-policy" target="_blank" className='px-2'> Privacy Policy </a>
          &
          <a href="/gdpr" target="_blank" className='px-2'>Cookie Policy</a>
          <button type='button' className="btn btn-primary rounded-pill fw-bold mx-2 py-0 " onClick={acceptCookies}>Got it</button>
        </div>
    </div>
  );
};

export default CookiesComponent;
