import { Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const Recommendedempty = ({ isrecommended, issaved, isShortlisted }) => {
  const nav = useNavigate();
  return (
    <div>
      {isrecommended && (
        <div
          style={{ height: "60vh" }}
          className="fx d-flex justify-content-center align-items-center"
        >
          <div className="p-0 pt-5">
            <div className=" text-center pt-5">
              <div>
                <img src="/bag.svg" alt="Job listings for professional opportunities" title="Find Professional Job Listings on Jobeze" />
              </div>
              <div className="fw-bold pt-4" style={{ fontSize: "23px" }}>
                No recommended jobs yet
              </div>
              <div className="text-muted fw-bold pt-2">
                Add skills to your profile to get them
              </div>
              {/* <div className="pt-4">
            <Button variant="contained" className="fw-bold" onClick={()=>nav('/profile-section')}>
              Add Skills
            </Button>
          </div> */}
            </div>
          </div>
        </div>
      )}
      {issaved && (
        <div className="container p-0 pt-5">
          <div className=" text-center pt-5">
            <div>
              <img src="/files.svg" alt="Manage job applications and files on Jobeze" title="Organize Your Job Applications on Jobeze" />
            </div>
            <div className="fw-bold pt-4" style={{ fontSize: "23px" }}>
              You have no saved jobs
            </div>
            <div className="text-muted fw-bold pt-2">
              Browse and save jobs relevant to you
            </div>
            <div className="pt-4">
              <Button
                variant="contained"
                className="fw-bold"
                onClick={() => nav("/jobsearch/allsearchdata")}
              >
                Browse jobs
              </Button>
            </div>
          </div>
        </div>
      )}
      {isShortlisted && (
        <div className="container p-0 pt-5">
          <div className=" text-center pt-5">
            <div>
              <img src="/files.svg" alt="Manage job applications and files on Jobeze" title="Organize Your Job Applications on Jobeze" />
            </div>
            <div className="fw-bold pt-4" style={{ fontSize: "23px" }}>
              No shortlisted jobs yet
            </div>
            <div className="text-muted fw-bold pt-2">
              If an employer shortlists you for a job, you'll find it here
            </div>

          </div>
        </div>
      )}
    </div>
  );
};

export default Recommendedempty;
