import React from 'react'
import "./Companycard.css";

const Companycard = () => {
  return (
    <div>
       <div className="viewdetail-section1">
          <p className="detail-section-title">Senior UX Designer</p>
          <div style={{display:'flex' ,gap:'10px'}}>
            <div className="detail-block" ></div>
            <p className="viewdetail-title">Spanidea System Pvt Ltd</p>
          </div>
        </div>
    </div>
  )
}

export default Companycard